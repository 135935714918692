import React from 'react';
import { Link } from 'react-router-dom';

const SuccessStoryModal = () => {
  return (
    <div className="modal fade" id="successStoryModal" tabIndex="-1" aria-labelledby="successStoryModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div className="modal-content">
          <div className="modal-header bg-primary text-white">
            <h5 className="modal-title" id="successStoryModalLabel">From Warehouse Worker to Cloud Consultant: A Journey of Transformation</h5>
            <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className="container-fluid">
              {/* Meet Jordan Section */}
              <div className="mb-4 position-relative">
                <div className="row">
                  <div className="col-md-9">
                    <h4 className="text-primary">Meet Jordan: The Aspiring Cloud Leader</h4>
                    <p>Jordan had spent the last six years working as a <strong>warehouse logistics coordinator</strong>, managing shipments, tracking inventory, and ensuring supply chains ran smoothly. It was a stable job, but deep down, he knew he wanted <strong>something more</strong>—a career where he could <strong>grow, innovate, and challenge himself</strong> in ways the warehouse floor never could.</p>
                    <p>One day, while scrolling through LinkedIn, he came across a post about <strong>Google Cloud Digital Leader certification</strong>. He saw stories of professionals from <strong>non-technical backgrounds</strong> transitioning into tech, and something clicked.</p>
                    <blockquote className="blockquote">
                      <p className="mb-0 fst-italic">"Maybe I could do this too."</p>
                    </blockquote>
                  </div>
                  <div className="col-md-3">
                    <div className="position-relative">
                      <img src="/assets/jordan.png" alt="Jordan - Cloud Consultant" className="rounded-circle img-fluid border border-4 border-primary shadow-sm" style={{ width: '180px', height: '180px', objectFit: 'cover' }} />
                      <div className="position-absolute bottom-0 end-0">
                        <span className="badge bg-success rounded-pill">Success Story</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* First Step Section */}
              <div className="mb-4">
                <h4 className="text-primary">The First Step: Cloud Digital Leader Training</h4>
                <p>Determined to break into the tech world, Jordan signed up for <strong>Charlene Banks' Cloud Digital Leader session</strong>. He had never taken a formal cloud computing course before, but something about <strong>Charlene's high-energy, engaging teaching style</strong> made the concepts <strong>click instantly</strong>.</p>
                <ul className="list-unstyled">
                  <li><i className="bi bi-check-circle-fill text-success me-2"></i>He learned <strong>how businesses leverage cloud technology</strong> to drive innovation.</li>
                  <li><i className="bi bi-check-circle-fill text-success me-2"></i>He discovered the power of <strong>AI, machine learning, and data analytics</strong>.</li>
                  <li className="mb-2"><i className="bi bi-check-circle-fill text-success me-2"></i>He saw <strong>how his logistics experience</strong> could translate into <strong>cloud-based supply chain solutions</strong>.</li>
                </ul>
                <p>For the first time, <strong>cloud computing wasn't just for engineers</strong>—it was for <strong>leaders, strategists, and business professionals like him</strong>.</p>
                <p>After weeks of studying, Jordan took the <strong>Cloud Digital Leader exam</strong>—and passed! 🎉</p>
              </div>

              {/* Making the Leap Section */}
              <div className="mb-4">
                <h4 className="text-primary">What's Next? Making the Leap to Tech</h4>
                <p>Earning his certification was exhilarating, but <strong>now came the real challenge</strong>—how could he leverage this into <strong>a full-time tech career</strong>?</p>
                <p>Jordan didn't have a traditional IT background. He wasn't a coder. But he knew that businesses <strong>desperately needed cloud-literate professionals</strong> who understood both <strong>business strategy and technology</strong>.</p>
                <p>That's when he discovered <strong>Charlene's Tier 3 Mentoring Program</strong>.</p>
                <blockquote className="blockquote">
                  <p className="mb-0 fst-italic">"I don't just need training—I need guidance on how to make the leap into tech."</p>
                </blockquote>
              </div>

              {/* Tier 3 Benefits Section */}
              <div className="mb-4">
                <h4 className="text-primary">Tier 3: Personalized Mentorship for Career Growth</h4>
                <p>Jordan signed up for <strong>Tier 3 - Premium Access</strong>, knowing he needed more than just self-paced learning. He needed <strong>one-on-one mentorship</strong> from someone who had been <strong>in the trenches of cloud consulting</strong>.</p>
                
                <div className="card mb-3">
                  <div className="card-body">
                    <h5 className="card-title"><i className="bi bi-briefcase-fill text-primary me-2"></i>Career Strategy Sessions</h5>
                    <p>Charlene helped him <strong>identify his transferable skills</strong> from logistics:</p>
                    <ul className="list-unstyled">
                      <li><i className="bi bi-arrow-right-circle-fill text-primary me-2"></i><strong>Supply Chain Optimization</strong> → Cloud-Based Inventory Management</li>
                      <li><i className="bi bi-arrow-right-circle-fill text-primary me-2"></i><strong>Operational Efficiency</strong> → Google Cloud AI & Automation</li>
                      <li><i className="bi bi-arrow-right-circle-fill text-primary me-2"></i><strong>Vendor Coordination</strong> → Cloud Migration Planning</li>
                    </ul>
                  </div>
                </div>

                <div className="card mb-3">
                  <div className="card-body">
                    <h5 className="card-title"><i className="bi bi-chat-quote-fill text-primary me-2"></i>Mock Interviews & Technical Discussions</h5>
                    <ul className="list-unstyled">
                      <li><i className="bi bi-check2-circle text-success me-2"></i>Practice answering cloud strategy questions</li>
                      <li><i className="bi bi-check2-circle text-success me-2"></i>Position yourself as a cloud business consultant</li>
                      <li className="mb-2"><i className="bi bi-check2-circle text-success me-2"></i>Build confidence in explaining cloud concepts</li>
                    </ul>
                  </div>
                </div>

                <div className="card mb-3">
                  <div className="card-body">
                    <h5 className="card-title"><i className="bi bi-people-fill text-primary me-2"></i>Networking & Career Opportunities</h5>
                    <ul className="list-unstyled">
                      <li className="mb-2"><i className="bi bi-check2-circle text-success me-2"></i>Coffee chat with a Google Cloud partner company</li>
                      <li className="mb-2"><i className="bi bi-check2-circle text-success me-2"></i>Introduction to a mentor in cloud strategy</li>
                      <li className="mb-2"><i className="bi bi-check2-circle text-success me-2"></i>First freelance gig optimizing cloud workflow</li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* Transformation Section */}
              <div className="mb-4">
                <h4 className="text-primary">The Transformation: A New Career Begins</h4>
                <p>Six months after earning his Cloud Digital Leader certification, Jordan landed <strong>his first tech job</strong>—a <strong>Cloud Adoption Consultant</strong> at a mid-sized firm.</p>
                <div className="alert alert-success">
                  <h5><i className="bi bi-lightbulb-fill me-2"></i>The best part?</h5>
                  <ul className="list-unstyled mb-0">
                    <li><i className="bi bi-check-lg me-2"></i>Built on his <strong>existing logistics experience</strong></li>
                    <li className="mb-2"><i className="bi bi-check-lg me-2"></i><strong>Navigated the transition with clarity and confidence</strong></li>
                    <li className="mb-2"><i className="bi bi-check-lg me-2"></i>Continued access to <strong>Tier 3 mentoring</strong> for ongoing support</li>
                  </ul>
                </div>
              </div>

              {/* Call to Action Section */}
              <div className="bg-light p-4 rounded">
                <h4 className="text-primary">Inspired? You Can Do It Too.</h4>
                <p>If you've taken <strong>Cloud Digital Leader training</strong> and want to <strong>break into cloud consulting, cloud sales, or leadership roles</strong>, Tier 3 <strong>is your next step</strong>.</p>
                <div className="row">
                  <div className="col-md-6">
                    <ul className="list-unstyled">
                      <li><i className="bi bi-check-circle-fill text-success me-2"></i>Personalized 1:1 career coaching</li>
                      <li className="mb-2"><i className="bi bi-check-circle-fill text-success me-2"></i>Exclusive networking & job opportunities</li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="list-unstyled">
                      <li className="mb-2"><i className="bi bi-check-circle-fill text-success me-2"></i>Mock interviews & resume optimization</li>
                      <li className="mb-2"><i className="bi bi-check-circle-fill text-success me-2"></i>Discounted mentoring blocks</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessStoryModal; 