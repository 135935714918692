import React from 'react';
import { Link } from 'react-router-dom';

const LuisSuccessStoryModal = () => {
  return (
    <div className="modal fade" id="luisSuccessStoryModal" tabIndex="-1" aria-labelledby="luisSuccessStoryModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div className="modal-content">
          <div className="modal-header bg-primary text-white">
            <h5 className="modal-title" id="luisSuccessStoryModalLabel">Building Confidence with Cloud: How Luis Used Tier 1 to Gain Technical Skills</h5>
            <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className="container-fluid">
              {/* Meet Luis Section */}
              <div className="mb-4 position-relative">
                <div className="row">
                  <div className="col-md-9">
                    <h4 className="text-primary">Meet Luis: The Self-Paced Learner with Big Goals</h4>
                    <p>Luis always had a passion for technology, but he <strong>never worked in IT</strong>. Instead, he spent the last <strong>eight years in retail management</strong>, handling store operations, inventory tracking, and customer service.</p>
                    <p>Every day, he saw how technology was changing the industry—automated checkouts, cloud-based inventory systems, AI-driven sales forecasting. He wanted to be part of that transformation.</p>
                    <blockquote className="blockquote">
                      <p className="mb-0 fst-italic">"I know technology is the future, but where do I even start?"</p>
                    </blockquote>
                    <p>Luis didn't have the time or money to <strong>quit his job for full-time training</strong>. He needed a <strong>flexible, self-paced way to learn cloud computing</strong>.</p>
                    <p>That's when he found <strong>Charlene Banks' Cloud Digital Leader course</strong>.</p>
                  </div>
                  <div className="col-md-3">
                    <div className="position-relative">
                      <img src="/assets/luis.png" alt="Luis - Cloud Learner" className="rounded-circle img-fluid border border-4 border-primary shadow-sm" style={{ width: '180px', height: '180px', objectFit: 'cover' }} />
                      <div className="position-absolute bottom-0 end-0">
                        <span className="badge bg-success rounded-pill">Success Story</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* First Step Section */}
              <div className="mb-4">
                <h4 className="text-primary">The First Step: Learning at His Own Pace</h4>
                <p>Luis took the <strong>Cloud Digital Leader session</strong> and was <strong>hooked</strong>.</p>
                <div className="card mb-3">
                  <div className="card-body">
                    <ul className="list-unstyled mb-0">
                      <li className="mb-2"><i className="bi bi-check-circle-fill text-success me-2"></i>He finally <strong>understood cloud computing</strong> in a way that made sense.</li>
                      <li className="mb-2"><i className="bi bi-check-circle-fill text-success me-2"></i>He saw <strong>how businesses use Google Cloud</strong> to optimize operations.</li>
                      <li className="mb-2"><i className="bi bi-check-circle-fill text-success me-2"></i>He realized that <strong>his retail management skills could translate into cloud-based business solutions.</strong></li>
                    </ul>
                  </div>
                </div>
                <p>He passed the <strong>Cloud Digital Leader certification</strong>, but he <strong>wasn't ready to jump into a tech career yet</strong>. He wanted to <strong>build more technical skills first</strong>—at his own pace.</p>
                <p>That's when he discovered <strong>Tier 1: Self-Paced Learning</strong>.</p>
              </div>

              {/* Tier 1 Benefits Section */}
              <div className="mb-4">
                <h4 className="text-primary">Tier 1: Learning Cloud on His Own Schedule</h4>
                <p>Luis needed <strong>structured, ongoing learning</strong>—but he didn't want to feel overwhelmed.</p>

                <div className="card mb-3">
                  <div className="card-body">
                    <h5 className="card-title"><i className="bi bi-collection-play-fill text-primary me-2"></i>Mini-Sessions for Practical Learning</h5>
                    <p>Instead of aimlessly watching YouTube tutorials, Luis had access to <strong>focused, short lessons</strong> on key cloud concepts.</p>
                    <ul className="list-unstyled mb-0">
                      <li className="mb-2"><i className="bi bi-play-circle-fill text-primary me-2"></i><strong>"How to Set Up a Virtual Machine in Google Cloud"</strong> – a 15-minute walkthrough that made VMs easy to understand.</li>
                      <li className="mb-2"><i className="bi bi-play-circle-fill text-primary me-2"></i><strong>"Cloud IAM for Beginners"</strong> – a quick breakdown of permissions & access controls.</li>
                      <li className="mb-2"><i className="bi bi-play-circle-fill text-primary me-2"></i><strong>"Intro to Cloud SQL"</strong> – a bite-sized session on managed databases.</li>
                    </ul>
                    <p className="mb-0 mt-2">Each lesson was <strong>clear, actionable, and built on his foundational knowledge</strong>.</p>
                  </div>
                </div>

                <div className="card mb-3">
                  <div className="card-body">
                    <h5 className="card-title"><i className="bi bi-newspaper text-primary me-2"></i>Curated Industry Updates & Best Practices</h5>
                    <p>Luis didn't just want to pass exams—he wanted to <strong>understand how cloud was evolving</strong>.</p>
                    <ul className="list-unstyled mb-0">
                      <li className="mb-2"><i className="bi bi-arrow-right-circle-fill text-primary me-2"></i>Every month, he received <strong>insights on cloud trends, business use cases, and best practices</strong>.</li>
                      <li className="mb-2"><i className="bi bi-arrow-right-circle-fill text-primary me-2"></i>Instead of sifting through <strong>endless blog posts</strong>, he had <strong>handpicked resources</strong> delivered to him.</li>
                    </ul>
                  </div>
                </div>

                <div className="card mb-3">
                  <div className="card-body">
                    <h5 className="card-title"><i className="bi bi-file-earmark-text-fill text-primary me-2"></i>Cheat Sheets & Quick Reference Guides</h5>
                    <p>Luis wasn't a fan of <strong>memorizing</strong> things—he liked having <strong>practical, easy-to-reference materials</strong>.</p>
                    <ul className="list-unstyled mb-0">
                      <li className="mb-2"><i className="bi bi-file-check-fill text-primary me-2"></i>When he needed to <strong>review key IAM roles</strong>, he used the <strong>Google Cloud IAM Cheat Sheet</strong> from Tier 1.</li>
                      <li className="mb-2"><i className="bi bi-file-check-fill text-primary me-2"></i>Before testing out <strong>Cloud Storage</strong>, he used the <strong>quick-start guide</strong> in the private resource library.</li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* Transformation Section */}
              <div className="mb-4">
                <h4 className="text-primary">The Transformation: From Cloud Beginner to Confident Learner</h4>
                <div className="alert alert-success">
                  <h5><i className="bi bi-trophy-fill me-2"></i>After <strong>six months in Tier 1</strong>, Luis:</h5>
                  <ul className="list-unstyled mb-0">
                    <li className="mb-2"><i className="bi bi-check-lg me-2"></i><strong>Understood key cloud technologies</strong> like virtual machines, IAM, and databases</li>
                    <li className="mb-2"><i className="bi bi-check-lg me-2"></i><strong>Built small cloud projects on his own</strong>, giving him confidence</li>
                    <li className="mb-2"><i className="bi bi-check-lg me-2"></i><strong>Started applying for IT support and cloud-related jobs</strong>—something he never thought he'd do</li>
                  </ul>
                </div>
                <p>He <strong>wasn't an expert yet</strong>, but he had <strong>momentum, structure, and clarity</strong>. Instead of feeling <strong>lost in endless resources</strong>, Tier 1 <strong>gave him a guided path</strong>.</p>
                <p>Eventually, Luis <strong>joined Tier 2</strong> to get <strong>interactive learning support</strong>—but Tier 1 gave him the <strong>solid foundation he needed to get started</strong>.</p>
              </div>

              {/* Call to Action Section */}
              <div className="bg-light p-4 rounded">
                <h4 className="text-primary">Who is Tier 1 For?</h4>
                <p>If you're <strong>new to cloud</strong> and want to <strong>learn at your own pace</strong>, Tier 1 is the perfect fit.</p>
                <div className="row">
                  <div className="col-md-6">
                    <ul className="list-unstyled">
                      <li className="mb-2"><i className="bi bi-check-circle-fill text-success me-2"></i><strong>Mini-sessions that simplify complex topics</strong></li>
                      <li className="mb-2"><i className="bi bi-check-circle-fill text-success me-2"></i><strong>Curated industry insights & best practices</strong></li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="list-unstyled">
                      <li className="mb-2"><i className="bi bi-check-circle-fill text-success me-2"></i><strong>Cheat sheets, quick guides, and cloud resources</strong></li>
                      <li className="mb-2"><i className="bi bi-check-circle-fill text-success me-2"></i><strong>A structured learning path without time pressure</strong></li>
                    </ul>
                  </div>
                </div>
                <p className="mb-0 mt-3"><strong>🚀 Want to build your cloud knowledge on your own schedule? Join Tier 1 today!</strong></p>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LuisSuccessStoryModal; 