import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import CourseDetails from '../components/CourseDetails';
import SuccessStoryModal from '../components/modals/SuccessStoryModal';
import MayaSuccessStoryModal from '../components/modals/MayaSuccessStoryModal';
import LuisSuccessStoryModal from '../components/modals/LuisSuccessStoryModal';
import LaunchpadModal from '../components/modals/LaunchpadModal';
import AIMentoringModal from '../components/modals/AIMentoringModal';
import ModalInitializer from '../components/ModalInitializer';
import charleneImage from '../assets/charlene.JPG';
import blakeImage from '../assets/blake.jpg';

const TechnicalMentoring = () => {
  // Initialize popovers when component mounts
  useEffect(() => {
    // Initialize all popovers on the page
    const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
    popoverTriggerList.map(function (popoverTriggerEl) {
      return new window.bootstrap.Popover(popoverTriggerEl, {
        trigger: 'hover focus',
        autoHide: true,
        dismissible: true
      });
    });

    // Cleanup function to destroy popovers when component unmounts
    return () => {
      popoverTriggerList.map(function (popoverTriggerEl) {
        const popover = window.bootstrap.Popover.getInstance(popoverTriggerEl);
        if (popover) {
          popover.dispose();
        }
        return null;
      });
    };
  }, []);

  return (
    <>
      {/* Video Banner */}
      <div className="position-relative">
        <video className="w-100" autoPlay muted loop playsInline>
          <source src="/assets/technical-coaching-smaller.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="position-absolute top-50 start-0 translate-middle-y text-white p-5">
          <h1 className="fw-bolder mb-3">Accelerate Your Technical Growth</h1>
          <p className="lead">Expert guidance to enhance your technical skills and advance your career</p>
        </div>
      </div>

      {/* What is Tech Mentoring Section */}
      <section className="py-5 bg-light">
        <div className="container px-5">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="text-center mb-5">
                <h2 className="fw-bolder mb-3">What is Tech Mentoring?</h2>
                <p className="lead fw-normal mb-4">
                  Our Tech Mentoring Program is designed for professionals who want expert guidance as they navigate cloud technologies, AI, and career growth. Instead of struggling alone, bring your real-world challenges and get on-demand support from an experienced mentor.
                </p>
              </div>
              <div className="card border-0 shadow">
                <div className="card-body p-5">
                  <p className="mb-4">
                    We run this program on <a href="https://discord.com/safety/360044149331-What-is-Discord" target="_blank" rel="noopener noreferrer" className="text-decoration-underline">Discord</a> because it provides a flexible, real-time community where you can:
                  </p>
                  <div className="row">
                    <div className="col-md-4 mb-4 mb-md-0">
                      <div className="d-flex align-items-start mb-3">
                        <div className="text-success me-3">
                          <i className="bi bi-check-circle-fill fs-4"></i>
                        </div>
                        <div>
                          <p className="mb-0">Ask questions as they arise – no need to wait for scheduled sessions.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 mb-4 mb-md-0">
                      <div className="d-flex align-items-start mb-3">
                        <div className="text-success me-3">
                          <i className="bi bi-check-circle-fill fs-4"></i>
                        </div>
                        <div>
                          <p className="mb-0">Join live Q&As and office hours for interactive learning.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="d-flex align-items-start mb-3">
                        <div className="text-success me-3">
                          <i className="bi bi-check-circle-fill fs-4"></i>
                        </div>
                        <div>
                          <p className="mb-0">Connect with peers who are also growing in their careers.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-center mt-4">
                    <p className="lead mb-0">
                      <i className="bi bi-lightbulb text-warning me-2"></i>
                      Upgrade or cancel anytime. Whether you need short-term guidance or long-term mentorship, you're in control of your learning journey.
                      <i className="bi bi-rocket-takeoff ms-2 text-primary"></i>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Why Investing in Mentorship Section */}
      <section className="py-5">
        <div className="container px-5">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="text-center mb-5">
                <h2 className="fw-bolder mb-3">Why Investing in Mentorship is the Smartest Move for Your Career</h2>
                <p className="lead fw-normal mb-4">
                  In the fast-moving world of cloud and AI, knowledge isn't just power—it's profitability, security, and career acceleration. Companies thrive because of skilled, knowledgeable professionals who can solve problems, innovate, and adapt to new technologies. But figuring it all out alone is costly—both in time wasted and missed opportunities.
                </p>
                <p className="lead fw-normal mb-4">
                  That's why mentorship matters. And that's why mentoring with Charsoft is different.
                </p>
              </div>
              
              <div className="row mb-5">
                <div className="col-lg-5 mb-4 mb-lg-0">
                  <div className="text-center mb-4">
                    <img src={charleneImage} alt="Charlene Banks" className="rounded-circle img-fluid mb-3" style={{ width: '180px', height: '180px', objectFit: 'cover' }} />
                    <h5 className="fw-bold">Charlene Banks</h5>
                    <p className="text-muted">Google Cloud's 2024 Trainer of the Year</p>
                  </div>
                  <div className="text-center">
                    <img src={blakeImage} alt="Blake Cook" className="rounded-circle img-fluid mb-3" style={{ width: '180px', height: '180px', objectFit: 'cover' }} />
                    <h5 className="fw-bold">Blake Cook</h5>
                    <p className="text-muted">Technical Mentoring Specialist</p>
                  </div>
                </div>
                <div className="col-lg-7">
                  <p className="mb-4">
                    At Charsoft, we believe in the power of team mentoring. As certified instructors for two of the biggest enterprise training providers (<a href="https://www.learningtree.com" target="_blank" rel="noopener noreferrer">Learning Tree International</a> & <a href="https://www.roitraining.com" target="_blank" rel="noopener noreferrer">ROI Training</a>), we understand that while formal training provides essential theoretical foundations, our mentoring services help you apply those concepts to your specific real-world challenges—bridging the gap between classroom solutions and the complex scenarios you face in your actual work environment.
                  </p>
                  <p className="mb-4">
                    Together with Blake Cook, our technical mentoring specialist, the Charsoft team has trained thousands of professionals, led enterprise-level cloud transformations, and now, we're offering direct mentorship so you can gain real-world skills faster than ever.
                  </p>
                  <div className="card bg-light border-0">
                    <div className="card-body p-4">
                      <h5 className="fw-bold mb-3">For less than the cost of one IT certification course, you get ongoing access to a mentor who can:</h5>
                      <ul className="list-unstyled mb-0">
                        <li className="d-flex align-items-center mb-3">
                          <div className="text-success me-3">
                            <i className="bi bi-check-circle-fill fs-4"></i>
                          </div>
                          <div>
                            Answer your career & technical questions in real time (instead of spending hours Googling)
                          </div>
                        </li>
                        <li className="d-flex align-items-center mb-3">
                          <div className="text-success me-3">
                            <i className="bi bi-check-circle-fill fs-4"></i>
                          </div>
                          <div>
                            Help you decide which certifications & training are right for you (so you don't waste money)
                          </div>
                        </li>
                        <li className="d-flex align-items-center mb-3">
                          <div className="text-success me-3">
                            <i className="bi bi-check-circle-fill fs-4"></i>
                          </div>
                          <div>
                            Guide you through complex AI & cloud workloads (so you can get ahead in your company)
                          </div>
                        </li>
                        <li className="d-flex align-items-center">
                          <div className="text-success me-3">
                            <i className="bi bi-check-circle-fill fs-4"></i>
                          </div>
                          <div>
                            Provide insights only an industry expert can offer (because Charlene has been in your shoes)
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="text-center">
                <p className="lead mb-0">
                  <i className="bi bi-lightbulb text-warning me-2"></i>
                  Your career is too valuable to leave to trial and error. Investing in mentorship now will put you years ahead of the competition. Join today, and if your needs change, you can upgrade or cancel anytime.
                  <i className="bi bi-rocket-takeoff ms-2 text-primary"></i>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Header */}
      <header className="py-5">
        <div className="container px-5">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-xxl-6">
              <div className="text-center my-5">
                <h1 className="fw-bolder mb-3">Choose Your Technical Mentoring Path</h1>
                <p className="lead fw-normal text-muted mb-4">Select the mentoring tier that best fits your goals and learning style, or opt for dedicated one-on-one coaching.</p>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* Membership Tiers section */}
      <section className="py-5 bg-light">
        <div className="container px-5 my-5">
          <div className="row gx-5 justify-content-center">
            {/* Tier 1 */}
            <div className="col-lg-4 mb-5">
              <div className="card h-100 shadow border-0">
                <div className="card-body p-4">
                  <div className="badge bg-primary bg-gradient rounded-pill mb-2">Tier 1</div>
                  <h5 className="card-title mb-3">Self-Paced Learning</h5>
                  <div className="d-flex align-items-baseline mb-4">
                    <span className="display-4 fw-bold">$49</span>
                    <span className="text-muted">/month</span>
                  </div>
                  <p className="card-text mb-4">Perfect for independent learners who want access to quality resources and industry updates.</p>
                  <ul className="list-unstyled mb-4">
                    <li className="mb-2"><i className="bi bi-check-circle-fill text-primary me-2"></i>Pre-recorded video tutorials</li>
                    <li className="mb-2"><i className="bi bi-check-circle-fill text-primary me-2"></i>Comprehensive PDF guides</li>
                    <li className="mb-2"><i className="bi bi-check-circle-fill text-primary me-2"></i>Technical cheatsheets</li>
                    <li className="mb-2"><i className="bi bi-check-circle-fill text-primary me-2"></i>Industry newsletters</li>
                    <li className="mb-2"><i className="bi bi-check-circle-fill text-primary me-2"></i>Curated article collection</li>
                  </ul>
                  <div className="text-center">
                    <button 
                      className="btn btn-secondary px-4" 
                      data-bs-toggle="popover" 
                      data-bs-placement="top" 
                      data-bs-trigger="hover focus"
                      data-bs-auto-close="true"
                      title="Stay Tuned!" 
                      data-bs-content="We're building an amazing library of resources for our self-paced learners. Premium users will get early access to this content when it becomes available!"
                    >
                      Coming Soon
                    </button>
                    <button className="btn btn-outline-primary d-block w-100 mt-2" data-bs-toggle="modal" data-bs-target="#luisSuccessStoryModal">
                      <i className="bi bi-star-fill text-warning me-2"></i>Read Success Story
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* Tier 2 */}
            <div className="col-lg-4 mb-5">
              <div className="card h-100 shadow border-0">
                <div className="card-body p-4">
                  <div className="badge bg-primary bg-gradient rounded-pill mb-2">Tier 2</div>
                  <h5 className="card-title mb-3">Interactive Learning</h5>
                  <div className="d-flex align-items-baseline mb-4">
                    <span className="display-4 fw-bold">$149</span>
                    <span className="text-muted">/month</span>
                  </div>
                  <p className="card-text mb-4">Ideal for those who want regular interaction and live support from our technical experts.</p>
                  <ul className="list-unstyled mb-4">
                    <li className="mb-2"><i className="bi bi-check-circle-fill text-primary me-2"></i>Everything in Tier 1</li>
                    <li className="mb-2"><i className="bi bi-check-circle-fill text-primary me-2"></i>Live Q&A sessions on <a href="https://discord.com/safety/360044149331-What-is-Discord" target="_blank" rel="noopener noreferrer" className="text-decoration-underline">Discord</a></li>
                    <li className="mb-2"><i className="bi bi-check-circle-fill text-primary me-2"></i><a href="https://discord.com/safety/360044149331-What-is-Discord" target="_blank" rel="noopener noreferrer" className="text-decoration-underline">Discord</a> community support</li>
                    <li className="mb-2"><i className="bi bi-check-circle-fill text-primary me-2"></i>Weekly office hours</li>
                    <li className="mb-2"><i className="bi bi-check-circle-fill text-primary me-2"></i>Technical discussion groups</li>
                  </ul>
                  <div className="text-center">
                    <button 
                      className="btn btn-secondary px-4" 
                      data-bs-toggle="popover" 
                      data-bs-placement="top" 
                      data-bs-trigger="hover focus"
                      data-bs-auto-close="true"
                      title="Coming Very Soon!" 
                      data-bs-content="Our interactive community is almost ready! Premium members will be the first to experience our enhanced learning platform with exclusive content and features."
                    >
                      Coming Soon
                    </button>
                    <button className="btn btn-outline-primary d-block w-100 mt-2" data-bs-toggle="modal" data-bs-target="#mayaSuccessStoryModal">
                      <i className="bi bi-star-fill text-warning me-2"></i>Read Success Story
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* Tier 3 */}
            <div className="col-lg-4 mb-5">
              <div className="card h-100 shadow border-0">
                <div className="card-body p-4">
                  <div className="badge bg-primary bg-gradient rounded-pill mb-2">Tier 3</div>
                  <div className="badge bg-danger bg-gradient rounded-pill mb-2 ms-2">SAVE $150</div>
                  <h5 className="card-title mb-3">Premium Mentoring</h5>
                  <div className="d-flex align-items-baseline mb-4">
                    <span className="text-decoration-line-through text-muted me-2">$499</span>
                    <span className="display-4 fw-bold">$349</span>
                    <span className="text-muted">/month</span>
                  </div>
                  <p className="card-text mb-4">The ultimate package with personalized attention and direct access to expert guidance.</p>
                  <ul className="list-unstyled mb-4">
                    <li className="mb-2"><i className="bi bi-check-circle-fill text-primary me-2"></i>Everything in Tier 2</li>
                    <li className="mb-2"><i className="bi bi-check-circle-fill text-primary me-2"></i>1 hour of 1:1 sessions</li>
                    <li className="mb-2"><i className="bi bi-check-circle-fill text-primary me-2"></i>Customized career planning</li>
                    <li className="mb-2"><i className="bi bi-check-circle-fill text-primary me-2"></i>Resume review service</li>
                    <li className="mb-2"><i className="bi bi-check-circle-fill text-primary me-2"></i>Priority support access</li>
                  </ul>
                  <div className="text-center">
                    <Link className="btn btn-primary px-4 mb-2" to="/signup">Get Started</Link>
                    <button className="btn btn-outline-primary d-block w-100" data-bs-toggle="modal" data-bs-target="#successStoryModal">
                      <i className="bi bi-star-fill text-warning me-2"></i>Read Success Story
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Block Mentoring section */}
      <section className="py-5">
        <div className="container px-5 my-5">
          <div className="row gx-5 justify-content-center">
            <div className="col-lg-8">
              <div className="card shadow border-0">
                <div className="card-body p-5">
                  <div className="text-center">
                    <div className="badge bg-gradient bg-warning text-dark rounded-pill mb-2">Special Offer</div>
                    <h2 className="fw-bolder mb-3">Dedicated Block Mentoring</h2>
                    <p className="lead fw-normal text-muted mb-4">Get intensive, personalized technical mentoring with a dedicated block of hours.</p>
                  </div>
                  <ul className="list-unstyled mb-4">
                    <li className="mb-3"><i className="bi bi-check-circle-fill text-primary me-2"></i>40 hours of one-on-one dedicated coaching</li>
                    <li className="mb-3"><i className="bi bi-check-circle-fill text-primary me-2"></i>Fully customized learning path</li>
                    <li className="mb-3"><i className="bi bi-check-circle-fill text-primary me-2"></i>Valid for one year</li>
                    <li className="mb-3"><i className="bi bi-check-circle-fill text-primary me-2"></i>Option to extend with additional hours</li>
                    <li className="mb-3"><i className="bi bi-check-circle-fill text-primary me-2"></i>Free access to Tier 2 while active hours remain</li>
                  </ul>
                  <div className="d-flex justify-content-between align-items-center mb-4">
                    <div>
                      <span className="display-4 fw-bold">$8,000</span>
                      <span className="text-muted">/40 hours</span>
                    </div>
                    <Link className="btn btn-primary btn-lg px-4" to="/block-mentoring-signup">Get Started</Link>
                  </div>
                  <div className="text-center">
                    <div className="row g-2">
                      <div className="col-md-6">
                        <button className="btn btn-outline-primary d-block w-100" data-bs-toggle="modal" data-bs-target="#launchpadModal">
                          <i className="bi bi-rocket fs-5 text-warning me-2"></i>🚀 Just Finished Launchpad? Learn More
                        </button>
                      </div>
                      <div className="col-md-6">
                        <button className="btn btn-outline-primary d-block w-100" data-bs-toggle="modal" data-bs-target="#aiMentoringModal">
                          <i className="bi bi-robot fs-5 text-warning me-2"></i>🤖 Want to Upskill in AI? Learn More
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Why Choose Us section */}
      <section className="py-5 bg-light">
        <div className="container px-5 my-5">
          <div className="text-center mb-5">
            <h2 className="fw-bolder">Why Choose Our Technical Mentoring?</h2>
            <p className="lead fw-normal text-muted">Expert guidance tailored to your needs and learning style</p>
          </div>
          <div className="row gx-5">
            <div className="col-lg-3 mb-5">
              <div className="text-center">
                <i className="bi bi-person-check fs-1 text-primary mb-3"></i>
                <h5 className="mb-3">Expert Guidance</h5>
                <p className="text-muted">Learn from experienced professionals</p>
              </div>
            </div>
            <div className="col-lg-3 mb-5">
              <div className="text-center">
                <i className="bi bi-gear fs-1 text-primary mb-3"></i>
                <h5 className="mb-3">Practical Skills</h5>
                <p className="text-muted">Real-world technical experience</p>
              </div>
            </div>
            <div className="col-lg-3 mb-5">
              <div className="text-center">
                <i className="bi bi-graph-up fs-1 text-primary mb-3"></i>
                <h5 className="mb-3">Career Growth</h5>
                <p className="text-muted">Accelerate your development</p>
              </div>
            </div>
            <div className="col-lg-3 mb-5">
              <div className="text-center">
                <i className="bi bi-clock fs-1 text-primary mb-3"></i>
                <h5 className="mb-3">Flexible Learning</h5>
                <p className="text-muted">Learn at your own pace</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Call to action section */}
      <section className="py-5">
        <div className="container px-5 my-5">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-xxl-6">
              <div className="text-center">
                <h2 className="fw-bolder mb-3">Ready to Start Your Technical Journey?</h2>
                <p className="lead fw-normal text-muted mb-4">Choose the mentoring option that best fits your goals and let's begin your path to technical excellence.</p>
                <div className="d-grid gap-3 d-sm-flex justify-content-sm-center">
                  <Link className="btn btn-primary btn-lg px-4 me-sm-3" to="/signup">Premium Mentoring</Link>
                  <Link className="btn btn-outline-primary btn-lg px-4" to="/block-mentoring-signup">Block Mentoring</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Still Unsure Section */}
      <section className="py-5 bg-light">
        <div className="container px-5 my-5">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="card border-0 shadow-lg">
                <div className="card-body p-5">
                  <div className="text-center mb-4">
                    <h2 className="fw-bolder">Still Unsure? Let's Talk.</h2>
                    <p className="lead fw-normal text-muted">
                      If you're wondering how mentoring can help your career, schedule a quick call to explore your goals.
                    </p>
                    <p className="mb-4">
                      You've seen the possibilities. Now it's time to act.
                    </p>
                  </div>
                  <div className="row g-3 justify-content-center">
                    <div className="col-md-6">
                      <div className="d-flex align-items-center mb-3">
                        <div className="fs-1 text-primary me-3">
                          <i className="bi bi-envelope-fill"></i>
                        </div>
                        <div>
                          <h5 className="mb-1">Email Us</h5>
                          <a href="mailto:support@charsoft.com" className="text-decoration-none">support@charsoft.com</a>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="d-flex align-items-center mb-3">
                        <div className="fs-1 text-primary me-3">
                          <i className="bi bi-telephone-fill"></i>
                        </div>
                        <div>
                          <h5 className="mb-1">Call Us</h5>
                          <a href="tel:+17032619371" className="text-decoration-none">(703) 261-9371</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-center mt-4">
                    <a href="https://calendly.com/charlene-charsoft" target="_blank" rel="noopener noreferrer" className="btn btn-primary btn-lg px-5">
                      <i className="bi bi-calendar-check me-2"></i>Schedule a Call
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Include the modal components */}
      <SuccessStoryModal />
      <MayaSuccessStoryModal />
      <LuisSuccessStoryModal />
      <LaunchpadModal />
      <AIMentoringModal />
    </>
  );
};

export default TechnicalMentoring; 