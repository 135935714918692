import { useEffect } from 'react';

// This component initializes all Bootstrap JavaScript components
const BootstrapInitializer = () => {
  useEffect(() => {
    // Check if Bootstrap is available
    if (typeof window !== 'undefined' && typeof window.bootstrap !== 'undefined') {
      // Initialize all dropdowns
      const dropdownElementList = document.querySelectorAll('.dropdown-toggle');
      dropdownElementList.forEach(dropdownToggle => {
        new window.bootstrap.Dropdown(dropdownToggle);
      });

      // Initialize all tooltips
      const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
      tooltipTriggerList.forEach(tooltipTriggerEl => {
        new window.bootstrap.Tooltip(tooltipTriggerEl);
      });

      // Initialize all popovers
      const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]');
      popoverTriggerList.forEach(popoverTriggerEl => {
        new window.bootstrap.Popover(popoverTriggerEl);
      });

      // Initialize all modals
      const modalTriggerList = document.querySelectorAll('.modal');
      modalTriggerList.forEach(modalTriggerEl => {
        new window.bootstrap.Modal(modalTriggerEl);
      });

      // Initialize all collapses
      const collapseTriggerList = document.querySelectorAll('.collapse');
      collapseTriggerList.forEach(collapseTriggerEl => {
        new window.bootstrap.Collapse(collapseTriggerEl, { toggle: false });
      });
    } else {
      console.warn('Bootstrap is not loaded. JavaScript components may not function correctly.');
    }
  }, []);

  // This component doesn't render anything
  return null;
};

export default BootstrapInitializer; 