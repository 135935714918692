import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CourseDetails from '../components/CourseDetails';

const LeadershipProgram = () => {
  // Add state for tracking which cards are flipped
  const [flippedCards, setFlippedCards] = useState({
    card1: false,
    card2: false,
    card3: false,
    card4: false
  });

  // Function to handle card flipping
  const handleCardFlip = (cardId) => {
    setFlippedCards(prev => ({
      ...prev,
      [cardId]: !prev[cardId]
    }));
  };

  // Ensure Bootstrap components are initialized when this page loads
  useEffect(() => {
    // Scroll to top when component mounts
    window.scrollTo(0, 0);
    
    // Re-initialize Bootstrap components if needed
    if (typeof window !== 'undefined' && typeof window.bootstrap !== 'undefined') {
      // This ensures any Bootstrap components on this page are properly initialized
      const dropdownElementList = document.querySelectorAll('.dropdown-toggle');
      dropdownElementList.forEach(dropdownToggle => {
        new window.bootstrap.Dropdown(dropdownToggle);
      });
      
      // Initialize accordions
      const accordionElementList = document.querySelectorAll('.accordion-button');
      accordionElementList.forEach(accordionButton => {
        accordionButton.addEventListener('click', function() {
          this.classList.toggle('collapsed');
          const target = this.getAttribute('data-bs-target');
          const content = document.querySelector(target);
          if (content) {
            content.classList.toggle('show');
          }
        });
      });
    }
  }, []);

  return (
    <>
      {/* Video Banner */}
      <section className="position-relative">
        <video className="w-100" autoPlay muted loop playsInline>
          <source src="/assets/leadership-program.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="position-absolute top-0 start-0 w-100 h-100" style={{ background: 'rgba(0, 0, 0, 0.15)' }}></div>
      </section>

      {/* Header */}
      <header className="py-5 bg-white">
        <div className="container px-5">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-xxl-6">
              <div className="text-center my-5">
                <h1 className="fw-bolder mb-3">Empower Your Leadership. Inspire Your Team. Elevate Your Success.</h1>
                <p className="lead fw-normal text-muted mb-4">
                  Are you ready to step into your full leadership potential? Whether you're a rising leader, a seasoned manager, or an entrepreneur, 
                  Charsoft Consulting's one-on-one leadership development program is designed to help you master essential leadership skills, 
                  develop strategic thinking, and confidently lead high-performing teams.
                </p>
                <div className="d-grid gap-3 d-sm-flex justify-content-sm-center mb-3">
                  <Link to="/leadership-quiz" className="btn btn-primary btn-lg px-4 py-3 me-sm-3">
                    <i className="bi bi-clipboard-check me-2"></i>
                    Take the Leadership Style Quiz
                  </Link>
                </div>
                <p className="text-muted">Discover your natural leadership style in just 2 minutes</p>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* Why This Program section */}
      <section className="py-5 bg-light">
        <div className="container px-5 my-5">
          <div className="row gx-5 align-items-center">
            <div className="col-lg-6">
              <h2 className="fw-bolder mb-4">Why This Program?</h2>
              <ul className="list-unstyled">
                <li className="mb-3"><i className="bi bi-check-circle-fill text-primary me-2"></i>Personalized one-on-one coaching tailored to your leadership style and goals</li>
                <li className="mb-3"><i className="bi bi-check-circle-fill text-primary me-2"></i>Learn to think strategically, communicate effectively, and inspire others</li>
                <li className="mb-3"><i className="bi bi-check-circle-fill text-primary me-2"></i>Gain practical tools to navigate challenges, delegate with confidence, and make high-impact decisions</li>
                <li className="mb-3"><i className="bi bi-check-circle-fill text-primary me-2"></i>Access a structured Leadership Workbook to track your growth and action steps</li>
                <li className="mb-3"><i className="bi bi-check-circle-fill text-primary me-2"></i>Walk away with a clear leadership development plan for long-term success</li>
              </ul>
            </div>
            <div className="col-lg-6">
              <img className="img-fluid rounded" src="/assets/leadership-lady.png" alt="Leadership Development" />
            </div>
          </div>
        </div>
      </section>

      {/* What You'll Learn section */}
      <section className="py-5">
        <div className="container px-5 my-5">
          <div className="text-center mb-5">
            <h2 className="fw-bolder">What You'll Learn</h2>
            <p className="lead fw-normal text-muted">This program is structured into five transformational phases:</p>
          </div>
          <div className="row gx-5 align-items-center">
            <div className="col-lg-4 mb-5 mb-lg-0">
              <div className="text-center">
                <img className="img-fluid rounded shadow" src="/assets/first-step-in-journey.png" alt="First Step in Leadership Journey" />
              </div>
            </div>
            <div className="col-lg-8">
              <div className="accordion" id="learningAccordion">
                {/* Phase 1 */}
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#phase1" aria-expanded="true" aria-controls="phase1">
                      <i className="bi bi-building-fill text-primary me-2"></i> Phase 1: Leadership Foundations
                    </button>
                  </h2>
                  <div id="phase1" className="accordion-collapse collapse show" aria-labelledby="phase1" data-bs-parent="#learningAccordion">
                    <div className="accordion-body">
                      <ul className="list-unstyled mb-0">
                        <li className="mb-2"><i className="bi bi-dot me-2"></i>Understanding Leadership Styles & Strengths</li>
                        <li className="mb-2"><i className="bi bi-dot me-2"></i>Developing Emotional Intelligence & Self-Awareness</li>
                        <li className="mb-2"><i className="bi bi-dot me-2"></i>Overcoming Limiting Beliefs & Imposter Syndrome</li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* Phase 2 */}
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#phase2" aria-expanded="false" aria-controls="phase2">
                      <i className="bi bi-lightbulb-fill text-primary me-2"></i> Phase 2: Strategic Thinking & Decision-Making
                    </button>
                  </h2>
                  <div id="phase2" className="accordion-collapse collapse" aria-labelledby="phase2" data-bs-parent="#learningAccordion">
                    <div className="accordion-body">
                      <ul className="list-unstyled mb-0">
                        <li className="mb-2"><i className="bi bi-dot me-2"></i>Creating a Clear Leadership Vision & Setting SMART Goals</li>
                        <li className="mb-2"><i className="bi bi-dot me-2"></i>Problem-Solving & Decision-Making Frameworks</li>
                        <li className="mb-2"><i className="bi bi-dot me-2"></i>Leading with Confidence in Uncertainty</li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* Phase 3 */}
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#phase3" aria-expanded="false" aria-controls="phase3">
                      <i className="bi bi-chat-quote-fill text-primary me-2"></i> Phase 3: Communication & Influence
                    </button>
                  </h2>
                  <div id="phase3" className="accordion-collapse collapse" aria-labelledby="phase3" data-bs-parent="#learningAccordion">
                    <div className="accordion-body">
                      <ul className="list-unstyled mb-0">
                        <li className="mb-2"><i className="bi bi-dot me-2"></i>Mastering Effective Leadership Communication</li>
                        <li className="mb-2"><i className="bi bi-dot me-2"></i>Navigating Difficult Conversations with Ease</li>
                        <li className="mb-2"><i className="bi bi-dot me-2"></i>Developing Your Executive Presence & Persuasive Influence</li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* Phase 4 */}
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#phase4" aria-expanded="false" aria-controls="phase4">
                      <i className="bi bi-people-fill text-primary me-2"></i> Phase 4: Team Management & High-Performance Culture
                    </button>
                  </h2>
                  <div id="phase4" className="accordion-collapse collapse" aria-labelledby="phase4" data-bs-parent="#learningAccordion">
                    <div className="accordion-body">
                      <ul className="list-unstyled mb-0">
                        <li className="mb-2"><i className="bi bi-dot me-2"></i>Motivating & Empowering Teams</li>
                        <li className="mb-2"><i className="bi bi-dot me-2"></i>Delegation Strategies & Strengths-Based Leadership</li>
                        <li className="mb-2"><i className="bi bi-dot me-2"></i>Fostering a Culture of Trust, Innovation, & Inclusion</li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* Phase 5 */}
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#phase5" aria-expanded="false" aria-controls="phase5">
                      <i className="bi bi-rocket-takeoff-fill text-primary me-2"></i> Phase 5: Execution & Sustainable Leadership Growth
                    </button>
                  </h2>
                  <div id="phase5" className="accordion-collapse collapse" aria-labelledby="phase5" data-bs-parent="#learningAccordion">
                    <div className="accordion-body">
                      <ul className="list-unstyled mb-0">
                        <li className="mb-2"><i className="bi bi-dot me-2"></i>Mastering Time & Energy Management as a Leader</li>
                        <li className="mb-2"><i className="bi bi-dot me-2"></i>Creating Your Personalized One-Year Leadership Growth Plan</li>
                        <li className="mb-2"><i className="bi bi-dot me-2"></i>Building a Network of Mentors & Accountability Partners</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* What's Included section */}
      <section className="py-5 bg-light">
        <div className="container px-5 my-5">
          <div className="text-center mb-5">
            <h2 className="fw-bolder">What's Included?</h2>
          </div>
          <div className="row gx-5">
            <div className="col-lg-3 mb-5">
              <div className="text-center">
                <i className="bi bi-bullseye fs-1 text-primary mb-3"></i>
                <h5 className="mb-3">One-on-One Coaching Sessions</h5>
                <p className="text-muted">Expert guidance tailored to your unique challenges</p>
              </div>
            </div>
            <div className="col-lg-3 mb-5">
              <div className="text-center">
                <i className="bi bi-book fs-1 text-primary mb-3"></i>
                <h5 className="mb-3">Leadership Development Workbook</h5>
                <p className="text-muted">Exclusive workbook with exercises and strategies</p>
              </div>
            </div>
            <div className="col-lg-3 mb-5">
              <div className="text-center">
                <i className="bi bi-graph-up fs-1 text-primary mb-3"></i>
                <h5 className="mb-3">Growth Roadmap</h5>
                <p className="text-muted">Self-assessments and personalized growth tracking</p>
              </div>
            </div>
            <div className="col-lg-3 mb-5">
              <div className="text-center">
                <i className="bi bi-trophy fs-1 text-primary mb-3"></i>
                <h5 className="mb-3">Certificate of Completion</h5>
                <p className="text-muted">Official recognition of your leadership growth</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Who is This For section */}
      <section className="py-5">
        <div className="container px-5 my-5">
          <div className="text-center mb-5">
            <h2 className="fw-bolder">Who is This For?</h2>
            <p className="lead fw-normal text-muted">Our leadership coaching program is perfect for professionals at every stage</p>
          </div>
          <div className="row gx-5">
            <div className="col-lg-3 mb-5">
              <div className={`flip-card ${flippedCards.card1 ? 'flipped' : ''}`}>
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <div className="card h-100 shadow border-0 transition-card">
                      <div className="card-body p-5 position-relative">
                        <div className="icon-circle bg-primary bg-opacity-10 mb-4">
                          <i className="bi bi-rocket-takeoff text-primary"></i>
                        </div>
                        <h5 className="card-title mb-3 text-primary">Emerging & Aspiring Leaders</h5>
                        <p className="card-text">Ready to step into leadership roles with confidence and make an immediate impact</p>
                        <div className="card-hover-overlay">
                          <button 
                            className="btn btn-sm btn-outline-primary mt-3" 
                            onClick={() => handleCardFlip('card1')}
                          >
                            That's Me
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flip-card-back">
                    <div className="card h-100 shadow border-0 bg-primary text-white">
                      <div className="card-body p-5 d-flex flex-column justify-content-between">
                        <div>
                          <h5 className="card-title mb-3 border-bottom pb-2">How You'll Benefit</h5>
                          <ul className="list-unstyled">
                            <li className="mb-2"><i className="bi bi-check2-circle me-2"></i>Develop confidence to lead before you have the title</li>
                            <li className="mb-2"><i className="bi bi-check2-circle me-2"></i>Learn to communicate ideas that get noticed by executives</li>
                            <li className="mb-2"><i className="bi bi-check2-circle me-2"></i>Build a personal brand that positions you for promotion</li>
                            <li className="mb-2"><i className="bi bi-check2-circle me-2"></i>Overcome imposter syndrome with proven techniques</li>
                          </ul>
                        </div>
                        <button 
                          className="btn btn-light btn-sm mt-3"
                          onClick={() => handleCardFlip('card1')}
                        >
                          <i className="bi bi-arrow-left me-1"></i> Go Back
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 mb-5">
              <div className={`flip-card ${flippedCards.card2 ? 'flipped' : ''}`}>
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <div className="card h-100 shadow border-0 transition-card">
                      <div className="card-body p-5 position-relative">
                        <div className="icon-circle bg-success bg-opacity-10 mb-4">
                          <i className="bi bi-briefcase text-success"></i>
                        </div>
                        <h5 className="card-title mb-3 text-success">Managers & Executives</h5>
                        <p className="card-text">Seeking to enhance leadership effectiveness and drive organizational success</p>
                        <div className="card-hover-overlay">
                          <button 
                            className="btn btn-sm btn-outline-success mt-3"
                            onClick={() => handleCardFlip('card2')}
                          >
                            That's Me
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flip-card-back">
                    <div className="card h-100 shadow border-0 bg-success text-white">
                      <div className="card-body p-5 d-flex flex-column justify-content-between">
                        <div>
                          <h5 className="card-title mb-3 border-bottom pb-2">How You'll Benefit</h5>
                          <ul className="list-unstyled">
                            <li className="mb-2"><i className="bi bi-check2-circle me-2"></i>Transform team performance with advanced leadership techniques</li>
                            <li className="mb-2"><i className="bi bi-check2-circle me-2"></i>Develop strategic thinking that drives organizational change</li>
                            <li className="mb-2"><i className="bi bi-check2-circle me-2"></i>Master difficult conversations and conflict resolution</li>
                            <li className="mb-2"><i className="bi bi-check2-circle me-2"></i>Balance operational excellence with innovation</li>
                          </ul>
                        </div>
                        <button 
                          className="btn btn-light btn-sm mt-3"
                          onClick={() => handleCardFlip('card2')}
                        >
                          <i className="bi bi-arrow-left me-1"></i> Go Back
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 mb-5">
              <div className={`flip-card ${flippedCards.card3 ? 'flipped' : ''}`}>
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <div className="card h-100 shadow border-0 transition-card">
                      <div className="card-body p-5 position-relative">
                        <div className="icon-circle bg-info bg-opacity-10 mb-4">
                          <i className="bi bi-building text-info"></i>
                        </div>
                        <h5 className="card-title mb-3 text-info">Entrepreneurs & Business Owners</h5>
                        <p className="card-text">Looking to build and inspire high-performing teams that drive business growth</p>
                        <div className="card-hover-overlay">
                          <button 
                            className="btn btn-sm btn-outline-info mt-3"
                            onClick={() => handleCardFlip('card3')}
                          >
                            That's Me
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flip-card-back">
                    <div className="card h-100 shadow border-0 bg-info text-white">
                      <div className="card-body p-5 d-flex flex-column justify-content-between">
                        <div>
                          <h5 className="card-title mb-3 border-bottom pb-2">How You'll Benefit</h5>
                          <ul className="list-unstyled">
                            <li className="mb-2"><i className="bi bi-check2-circle me-2"></i>Scale your leadership as you scale your business</li>
                            <li className="mb-2"><i className="bi bi-check2-circle me-2"></i>Build systems that allow your team to thrive without micromanagement</li>
                            <li className="mb-2"><i className="bi bi-check2-circle me-2"></i>Create a company culture that attracts and retains top talent</li>
                            <li className="mb-2"><i className="bi bi-check2-circle me-2"></i>Transition from founder to visionary leader</li>
                          </ul>
                        </div>
                        <button 
                          className="btn btn-light btn-sm mt-3"
                          onClick={() => handleCardFlip('card3')}
                        >
                          <i className="bi bi-arrow-left me-1"></i> Go Back
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 mb-5">
              <div className={`flip-card ${flippedCards.card4 ? 'flipped' : ''}`}>
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <div className="card h-100 shadow border-0 transition-card">
                      <div className="card-body p-5 position-relative">
                        <div className="icon-circle bg-warning bg-opacity-10 mb-4">
                          <i className="bi bi-graph-up-arrow text-warning"></i>
                        </div>
                        <h5 className="card-title mb-3 text-warning">Future Leaders</h5>
                        <p className="card-text">Committed to becoming stronger, more strategic, and influential in your organization</p>
                        <div className="card-hover-overlay">
                          <button 
                            className="btn btn-sm btn-outline-warning mt-3"
                            onClick={() => handleCardFlip('card4')}
                          >
                            That's Me
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flip-card-back">
                    <div className="card h-100 shadow border-0 bg-warning">
                      <div className="card-body p-5 d-flex flex-column justify-content-between">
                        <div>
                          <h5 className="card-title mb-3 border-bottom pb-2">How You'll Benefit</h5>
                          <ul className="list-unstyled">
                            <li className="mb-2"><i className="bi bi-check2-circle me-2"></i>Develop a personalized leadership roadmap for your career</li>
                            <li className="mb-2"><i className="bi bi-check2-circle me-2"></i>Build influence and executive presence that gets you noticed</li>
                            <li className="mb-2"><i className="bi bi-check2-circle me-2"></i>Learn to lead cross-functional teams and initiatives</li>
                            <li className="mb-2"><i className="bi bi-check2-circle me-2"></i>Become the go-to problem solver in your organization</li>
                          </ul>
                        </div>
                        <button 
                          className="btn btn-light btn-sm mt-3"
                          onClick={() => handleCardFlip('card4')}
                        >
                          <i className="bi bi-arrow-left me-1"></i> Go Back
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center mt-5">
            <div className="card mx-auto border-0 bg-light shadow-sm" style={{ maxWidth: '700px' }}>
              <div className="card-body p-5">
                <h4 className="mb-3">Not sure which leadership style fits you best?</h4>
                <p className="mb-4">Take our quick 2-minute assessment to discover your natural leadership tendencies and receive personalized insights.</p>
                <Link to="/leadership-quiz" className="btn btn-primary px-4 py-2">
                  <i className="bi bi-clipboard-check me-2"></i>
                  Discover Your Leadership Style
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* Add this style block at the end of the section */}
        <style jsx="true">{`
          .icon-circle {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 60px;
            height: 60px;
            border-radius: 50%;
          }
          
          .icon-circle i {
            font-size: 1.75rem;
          }
          
          .transition-card {
            transition: all 0.3s ease;
            overflow: hidden;
            height: 100%;
          }
          
          .transition-card:hover {
            transform: translateY(-5px);
            box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important;
          }
          
          .card-hover-overlay {
            opacity: 0;
            transition: opacity 0.3s ease;
          }
          
          .transition-card:hover .card-hover-overlay {
            opacity: 1;
          }
          
          /* Flip card styles */
          .flip-card {
            perspective: 1000px;
            height: 100%;
            min-height: 480px;
          }
          
          .flip-card-inner {
            position: relative;
            width: 100%;
            height: 100%;
            text-align: center;
            transition: transform 0.8s;
            transform-style: preserve-3d;
          }
          
          .flip-card.flipped .flip-card-inner {
            transform: rotateY(180deg);
          }
          
          .flip-card-front, .flip-card-back {
            position: absolute;
            width: 100%;
            height: 100%;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            border-radius: 0.5rem;
            overflow: hidden;
          }
          
          .flip-card-back {
            transform: rotateY(180deg);
          }
          
          .flip-card-back .card {
            height: 100%;
          }
          
          .flip-card-back ul li {
            margin-bottom: 0.75rem;
            font-size: 0.9rem;
            text-align: left;
          }
          
          /* Add more space between list items and button */
          .flip-card-back .card-body {
            padding-bottom: 2rem;
          }
        `}</style>
      </section>

      {/* How to Enroll section */}
      <section className="py-5 bg-light">
        <div className="container px-5 my-5">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-xxl-6">
              <div className="text-center my-5">
                <h2 className="fw-bolder mb-3">How to Enroll</h2>
                <p className="lead fw-normal text-muted mb-4">Spots are limited! Apply today for a free discovery call.</p>
                <div className="d-grid gap-3 d-sm-flex justify-content-sm-center">
                  <Link className="btn btn-primary btn-lg px-4 me-sm-3" to="/signup">Sign Up Now</Link>
                  <Link className="btn btn-outline-primary btn-lg px-4" to="/coaching">Learn More</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LeadershipProgram; 