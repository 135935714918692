import React from 'react';
import { Link } from 'react-router-dom';

const ServiceCard = ({ image, badge, title, description, link }) => {
  return (
    <div className="col-lg-4 mb-5">
      <div className="card h-100 shadow border-0">
        <img className="card-img-top" src={image} alt={title} />
        <div className="card-body p-4">
          <div className="badge bg-primary bg-gradient rounded-pill mb-2">{badge}</div>
          <Link className="text-decoration-none link-dark stretched-link" to={link}>
            <h5 className="card-title mb-3">{title}</h5>
          </Link>
          <p className="card-text mb-0">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default ServiceCard; 