/**
 * API utility functions for making requests to external services
 */

// Google Sheets Web App URL
const GOOGLE_SHEETS_API_URL = 'https://script.google.com/macros/s/AKfycbwuuKTN5aQsXBWrXdIs4au-Gw90IKGWwSHpJIDPqVyL_uiWf1SgsHpyjBtax_iTDMM-/exec';

/**
 * Get the Google Script URL
 * @returns {string} - The Google Script URL
 */
const getGoogleScriptUrl = () => {
  return GOOGLE_SHEETS_API_URL;
};

/**
 * Generate a unique identifier for tracking user journey
 * @returns {string} - A unique identifier
 */
const generateUniqueId = () => {
  return 'uid-' + Date.now() + '-' + Math.random().toString(36).substring(2, 10);
};

/**
 * Get or create a session ID for the current user
 * @returns {string} - The session ID
 */
const getSessionId = () => {
  let sessionId = sessionStorage.getItem('charsoft_session_id');
  if (!sessionId) {
    sessionId = generateUniqueId();
    sessionStorage.setItem('charsoft_session_id', sessionId);
  }
  return sessionId;
};

/**
 * Generate a unique session ID and store it in sessionStorage
 * @returns {string} - The generated session ID
 */
const generateSessionId = () => {
  const sessionId = 'session_' + Date.now() + '_' + Math.random().toString(36).substring(2, 9);
  sessionStorage.setItem('sessionId', sessionId);
  return sessionId;
};

/**
 * Generate a unique submission ID
 * @returns {string} - The generated submission ID
 */
const generateSubmissionId = () => {
  return 'sub_' + Date.now() + '_' + Math.random().toString(36).substring(2, 9);
};

/**
 * Submit data to Google Sheets using the legacy no-cors approach
 * @param {Object} data - The data to submit
 * @returns {Promise} - The fetch promise
 */
export const submitToGoogleSheetsLegacy = async (data) => {
  try {
    // Add timestamp if not provided
    if (!data.timestamp) {
      data.timestamp = new Date().toISOString();
    }
    
    // Add session ID if not provided
    if (!data.sessionId) {
      data.sessionId = sessionStorage.getItem('sessionId') || generateSessionId();
    }
    
    // Add submission ID
    data.submissionId = generateSubmissionId();
    
    console.log('Submitting data to Google Sheets (legacy):', data);
    
    // Create URL with parameters for GET request
    const params = new URLSearchParams();
    
    // Add each property from data object as a URL parameter
    Object.entries(data).forEach(([key, value]) => {
      params.append(key, String(value));
    });
    
    // Create the URL with query parameters
    const url = `${GOOGLE_SHEETS_API_URL}?${params.toString()}`;
    
    // Make the request with no-cors mode
    const response = await fetch(url, {
      method: 'GET',
      mode: 'no-cors',
      cache: 'no-cache'
    });
    
    // With no-cors mode, we can't read the response
    // So we just return a success object
    return {
      success: true,
      message: 'Data submitted successfully (no response available in no-cors mode)'
    };
  } catch (error) {
    console.error('Error submitting data to Google Sheets:', error);
    throw error;
  }
};

/**
 * Submit data to Google Sheets via the Google Script proxy
 * This is the primary implementation that supports CORS and returns the full response
 * 
 * @param {string} formType - Type of form being submitted
 * @param {Object} data - Form data to submit
 * @returns {Promise<Object>} - Response from the Google Script
 */
export const submitToGoogleSheets = async (formType, data) => {
  try {
    const scriptUrl = getGoogleScriptUrl();
    
    // Add timestamp and tracking info to the data
    const enhancedData = {
      ...data,
      timestamp: new Date().toISOString(),
      formType,
      sessionId: getSessionId(),
      submissionId: generateUniqueId()
    };
    
    console.log(`Submitting ${formType} data to Google Sheets:`, enhancedData);
    
    const response = await fetch(scriptUrl, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(enhancedData),
    });
    
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    
    const result = await response.json();
    console.log(`${formType} submission result:`, result);
    
    // Return the full result object which may include emailSent status
    return result;
  } catch (error) {
    console.error(`Error submitting ${formType} to Google Sheets:`, error);
    throw error;
  }
};

/**
 * Submit quiz results to Google Sheets
 * @param {string} email - User's email address
 * @param {string} leadershipStyle - The identified leadership style
 * @returns {Promise} - The fetch promise
 */
export const submitQuizResults = async (email, leadershipStyle) => {
  return submitToGoogleSheetsLegacy({
    formType: 'leadership-quiz',
    email,
    leadershipStyle,
    source: 'leadership_quiz'
  });
};

/**
 * Submit tier subscription data to Google Sheets
 * @param {Object} subscriptionData - The subscription data
 * @param {string} subscriptionData.email - User's email address
 * @param {string} subscriptionData.name - User's name
 * @param {string} subscriptionData.tier - Subscription tier (e.g., "Basic", "Premium", "Enterprise")
 * @param {string} subscriptionData.price - Price of the subscription
 * @param {string} subscriptionData.paymentStatus - Status of the payment
 * @returns {Promise} - The fetch promise
 */
export const submitTierSubscription = async (subscriptionData) => {
  // Ensure payment status is included
  const enhancedData = {
    ...subscriptionData,
    paymentStatus: subscriptionData.paymentStatus || 'Pending',
    source: subscriptionData.source || 'signup_page'
  };
  
  return submitToGoogleSheetsLegacy({
    formType: 'tier-subscription',
    ...enhancedData
  });
};

/**
 * Submit mentor block purchase data to Google Sheets
 * @param {Object} mentorData - The mentor block data
 * @param {string} mentorData.email - User's email address
 * @param {string} mentorData.name - User's name
 * @param {string} mentorData.blockType - Type of mentoring block (e.g., "5-Hour Block", "10-Hour Block")
 * @param {string} mentorData.hours - Number of hours in the block
 * @param {string} mentorData.price - Price of the mentoring block
 * @param {string} mentorData.paymentStatus - Status of the payment
 * @returns {Promise} - The fetch promise
 */
export const submitMentorBlockPurchase = async (mentorData) => {
  return submitToGoogleSheetsLegacy({
    formType: 'mentor-block',
    ...mentorData,
    source: mentorData.source || 'mentor_page'
  });
};

/**
 * Open the leadership report in a new tab
 * @param {string} leadershipStyle - The leadership style to open the report for
 */
export const openLeadershipReport = (leadershipStyle) => {
  const reportUrls = {
    analytical: '/assets/leadership-reports/analytical-leader-report.html',
    collaborative: '/assets/leadership-reports/collaborative-leader-report.html',
    decisive: '/assets/leadership-reports/decisive-leader-report.html',
    visionary: '/assets/leadership-reports/visionary-leader-report.html'
  };
  
  const reportUrl = reportUrls[leadershipStyle];
  if (!reportUrl) {
    console.error(`No report URL found for leadership style: ${leadershipStyle}`);
    return;
  }
  
  const link = document.createElement('a');
  link.href = reportUrl;
  link.target = '_blank';
  link.click();
};

/**
 * Submit an invoice request to Google Sheets
 * @param {Object} invoiceData - Data for the invoice request
 * @param {string} invoiceData.email - User's email
 * @param {string} invoiceData.name - User's name
 * @param {string} invoiceData.company - User's company (optional)
 * @param {string} invoiceData.managerEmail - Manager's email (optional)
 * @param {string} invoiceData.notes - Additional notes (optional)
 * @param {string} invoiceData.productType - Type of product (e.g., "Subscription", "Mentoring Block")
 * @param {string} invoiceData.productName - Name of the product
 * @param {string} invoiceData.price - Price of the product
 * @returns {Promise<Object>} - Response from the Google Script
 */
export const submitInvoiceRequest = async (invoiceData) => {
  try {
    console.log('Submitting invoice request:', invoiceData);
    
    // Use the legacy method for no-cors testing
    const response = await submitToGoogleSheetsLegacy({
      formType: 'invoice-request',
      ...invoiceData
    });
    
    console.log('Invoice request response:', response);
    
    // Return the response
    return response;
  } catch (error) {
    console.error('Error submitting invoice request:', error);
    throw error;
  }
}; 