import React from 'react';
import { Link } from 'react-router-dom';
import ServiceCard from '../components/ServiceCard';

const Training = () => {
  // Training programs data
  const trainingPrograms = [
    {
      image: '/assets/google-cloud-platform.png',
      badge: 'Cloud Computing',
      title: 'Google Cloud Training',
      description: 'Comprehensive training in Google Cloud Platform (GCP) services, including compute, storage, networking, and security. Learn to build, deploy, and manage applications on GCP with hands-on experience.',
      link: '/google-cloud-training'
    },
    {
      image: '/assets/microsoft-apps.png',
      badge: 'Microsoft Stack',
      title: 'Microsoft Technologies',
      description: 'Expert training in Microsoft\'s suite of technologies, including Azure, SQL Server, SharePoint, and Power Platform. Master the tools and services that power modern enterprise solutions.',
      link: '/microsoft-training'
    },
    {
      image: '/assets/ai.png',
      badge: 'AI & Machine Learning',
      title: 'Artificial Intelligence',
      description: 'Cutting-edge training in AI and machine learning technologies. Learn about neural networks, deep learning, and practical applications of AI in business solutions.',
      link: '/ai-training'
    }
  ];

  return (
    <>
      {/* Video Banner */}
      <div className="video-banner position-relative">
        <video className="w-100" autoPlay muted loop playsInline>
          <source src="/assets/online-training-smaller.mp4?v=1" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="position-absolute top-50 start-0 translate-middle-y text-white ps-5">
          <h1 className="display-4 fw-bolder">Professional Training Programs</h1>
          <p className="lead">Empowering teams with cutting-edge skills and knowledge</p>
        </div>
      </div>

      {/* Header */}
      <header className="py-5">
        <div className="container px-5">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-xxl-6">
              <div className="text-center my-5">
                <h1 className="fw-bolder mb-3">Professional Training Programs</h1>
                <p className="lead fw-normal text-muted mb-4">
                  Empower your team with cutting-edge skills and knowledge through our comprehensive training programs. 
                  From cloud computing to artificial intelligence, we offer specialized courses designed to meet your organization's needs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* Training Cards section */}
      <section className="py-5">
        <div className="container px-5 my-5">
          <div className="row gx-5">
            {trainingPrograms.map((program, index) => (
              <ServiceCard 
                key={index}
                image={program.image}
                badge={program.badge}
                title={program.title}
                description={program.description}
                link={program.link}
              />
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Training; 