import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';

// Components
import Header from './components/Header';
import Footer from './components/Footer';
import BootstrapInitializer from './components/BootstrapInitializer';

// Pages
import Home from './pages/Home';
import About from './pages/About';
import Clients from './pages/Clients';
import Training from './pages/Training';
import GoogleCloudTraining from './pages/GoogleCloudTraining';
import MicrosoftTraining from './pages/MicrosoftTraining';
import AITraining from './pages/AITraining';
import Mentoring from './pages/Mentoring';
import LeadershipProgram from './pages/LeadershipProgram';
import LeadershipQuiz from './pages/LeadershipQuiz';
import TechnicalMentoring from './pages/TechnicalMentoring';
import CareerDevelopment from './pages/CareerDevelopment';
import TeamCoaching from './pages/TeamCoaching';
import Signup from './pages/Signup';
import BlockMentoringSignup from './pages/BlockMentoringSignup';
import TestPaymentPage from './pages/TestPaymentPage';
import InvoicePayment from './pages/InvoicePayment';
import ApproveInvoice from './pages/ApproveInvoice';
import AdminFirebase from './pages/AdminFirebase';

function App() {
  return (
    <Router>
      <div className="d-flex flex-column min-vh-100">
        <BootstrapInitializer />
        <Header />
        <main className="flex-shrink-0">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/clients" element={<Clients />} />
            <Route path="/training" element={<Training />} />
            <Route path="/google-cloud-training" element={<GoogleCloudTraining />} />
            <Route path="/microsoft-training" element={<MicrosoftTraining />} />
            <Route path="/ai-training" element={<AITraining />} />
            <Route path="/mentoring" element={<Mentoring />} />
            <Route path="/leadership-program" element={<LeadershipProgram />} />
            <Route path="/leadership-quiz" element={<LeadershipQuiz />} />
            <Route path="/technical-mentoring" element={<TechnicalMentoring />} />
            {/* Hidden routes
            <Route path="/career-development" element={<CareerDevelopment />} />
            <Route path="/team-coaching" element={<TeamCoaching />} />
            */}
            <Route path="/signup" element={<Signup />} />
            <Route path="/block-mentoring-signup" element={<BlockMentoringSignup />} />
            <Route path="/test-payment" element={<TestPaymentPage />} />
            <Route path="/payment" element={<InvoicePayment />} />
            <Route path="/approve-invoice" element={<ApproveInvoice />} />
            <Route path="/admin/firebase" element={<AdminFirebase />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App; 