import React from 'react';

const Clients = () => {
  // Client data
  const governmentClients = [
    'CPM Projects',
    'Tokio Millennium RE',
    'Metropolitan Regional Information Systems (MRIS)',
    'American Postal Workers Union Health Plan',
    'Realty Investment Corporation',
    'Vanguard',
    'Fujitsu Computer Products of America',
    'Genentech',
    'US Navy',
    'US Marine Corps'
  ];

  const privateSectorClients = [
    'Cisco Systems',
    'The Raven Group',
    'Jack and Jill of America, Inc.',
    'Queen Anne\'s County Emergency Services',
    'The Learning Tree, International',
    'Nea Community Learning Center',
    'ROI Training'
  ];

  return (
    <>
      {/* Header */}
      <header className="py-5">
        <div className="container px-5">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-xxl-6">
              <div className="text-center my-5">
                <h1 className="fw-bolder mb-3">Our Clients</h1>
                <p className="lead fw-normal text-muted mb-4">
                  Charsoft Consulting, Inc. has a long history of working with a diverse range of clients, from small businesses to Fortune 500 companies. 
                  We are proud to have helped our clients achieve their goals and objectives.
                </p>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* Clients section */}
      <section className="py-5">
        <div className="container px-5 my-5">
          <div className="row gx-5">
            <div className="col-lg-6">
              <h2 className="fw-bolder">Government</h2>
              <ul className="list-unstyled mb-4">
                {governmentClients.map((client, index) => (
                  <li key={index}>{client}</li>
                ))}
              </ul>
            </div>
            <div className="col-lg-6">
              <h2 className="fw-bolder">Private Sector</h2>
              <ul className="list-unstyled mb-4">
                {privateSectorClients.map((client, index) => (
                  <li key={index}>{client}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Clients; 