import { db } from './firebase';
import { 
  collection, 
  addDoc, 
  doc, 
  getDoc, 
  getDocs, 
  updateDoc, 
  query, 
  where,
  orderBy,
  serverTimestamp
} from 'firebase/firestore';

// Helper function to generate unique IDs
const generateId = (prefix) => {
  return `${prefix}-${Date.now().toString().substring(0, 10)}-${Math.random().toString(36).substring(2, 7)}`;
};

// Helper function to get session ID
const getSessionId = () => {
  let sessionId = sessionStorage.getItem('sessionId');
  if (!sessionId) {
    sessionId = `session-${Date.now()}-${Math.random().toString(36).substring(2, 10)}`;
    sessionStorage.setItem('sessionId', sessionId);
  }
  return sessionId;
};

// ===== LEADERSHIP QUIZ FUNCTIONS =====

/**
 * Save leadership quiz results to Firestore
 * @param {Object} data - Quiz data including email and leadership style
 * @returns {Promise<Object>} - Result object with ID and success status
 */
export const saveLeadershipQuizResults = async (data) => {
  try {
    // Prepare the data
    const quizData = {
      email: data.email || '',
      leadershipStyle: data.leadershipStyle || '',
      timestamp: serverTimestamp(),
      source: data.source || window.location.href,
      sessionId: data.sessionId || getSessionId(),
      submissionId: data.submissionId || generateId('quiz'),
      emailSent: false,
      notes: ''
    };
    
    // Add to Firestore
    const docRef = await addDoc(collection(db, 'leadershipQuizResults'), quizData);
    
    // Send email if needed
    let emailSent = false;
    if (data.email) {
      emailSent = await sendLeadershipQuizEmail(data);
      
      // Update the document with email status
      await updateDoc(doc(db, 'leadershipQuizResults', docRef.id), {
        emailSent: emailSent
      });
    }
    
    return {
      id: docRef.id,
      status: 'success',
      message: 'Leadership quiz results saved successfully',
      emailSent: emailSent
    };
  } catch (error) {
    console.error('Error saving leadership quiz results:', error);
    throw new Error(`Failed to save quiz results: ${error.message}`);
  }
};

/**
 * Get leadership quiz results by email
 * @param {string} email - User's email
 * @returns {Promise<Array>} - Array of quiz results
 */
export const getLeadershipQuizResultsByEmail = async (email) => {
  try {
    const q = query(
      collection(db, 'leadershipQuizResults'), 
      where('email', '==', email),
      orderBy('timestamp', 'desc')
    );
    
    const querySnapshot = await getDocs(q);
    const results = [];
    
    querySnapshot.forEach((doc) => {
      results.push({
        id: doc.id,
        ...doc.data()
      });
    });
    
    return results;
  } catch (error) {
    console.error('Error getting leadership quiz results:', error);
    throw new Error(`Failed to get quiz results: ${error.message}`);
  }
};

// ===== TIER SUBSCRIPTION FUNCTIONS =====

/**
 * Save tier subscription data to Firestore
 * @param {Object} data - Subscription data
 * @returns {Promise<Object>} - Result object with ID and success status
 */
export const saveTierSubscription = async (data) => {
  try {
    // Prepare the data
    const subscriptionData = {
      email: data.email || '',
      name: data.name || '',
      tier: data.tier || '',
      price: data.price || '',
      paymentStatus: data.paymentStatus || 'Pending',
      timestamp: serverTimestamp(),
      source: data.source || window.location.href,
      sessionId: data.sessionId || getSessionId(),
      submissionId: data.submissionId || generateId('sub'),
      emailSent: false,
      notes: ''
    };
    
    // Add to Firestore
    const docRef = await addDoc(collection(db, 'tierSubscriptions'), subscriptionData);
    
    // Send email if payment is completed
    let emailSent = false;
    if (data.paymentStatus === 'Completed' && data.email) {
      emailSent = await sendTierSubscriptionEmail(data);
      
      // Update the document with email status
      await updateDoc(doc(db, 'tierSubscriptions', docRef.id), {
        emailSent: emailSent
      });
    }
    
    return {
      id: docRef.id,
      status: 'success',
      message: 'Tier subscription saved successfully',
      emailSent: emailSent
    };
  } catch (error) {
    console.error('Error saving tier subscription:', error);
    throw new Error(`Failed to save subscription: ${error.message}`);
  }
};

/**
 * Update tier subscription payment status
 * @param {string} id - Document ID
 * @param {string} paymentStatus - New payment status
 * @param {string} paymentMethod - Payment method used
 * @param {string} paymentId - Payment transaction ID
 * @returns {Promise<Object>} - Result object with success status
 */
export const updateTierSubscriptionPayment = async (id, paymentStatus, paymentMethod, paymentId) => {
  try {
    const docRef = doc(db, 'tierSubscriptions', id);
    const docSnap = await getDoc(docRef);
    
    if (!docSnap.exists()) {
      throw new Error('Subscription not found');
    }
    
    // Update the document
    await updateDoc(docRef, {
      paymentStatus: paymentStatus,
      paymentMethod: paymentMethod || 'Credit Card',
      paymentId: paymentId || '',
      paymentDate: serverTimestamp()
    });
    
    // Send email if payment is completed
    let emailSent = false;
    if (paymentStatus === 'Completed') {
      const subscriptionData = docSnap.data();
      emailSent = await sendTierSubscriptionEmail({
        ...subscriptionData,
        paymentStatus: paymentStatus,
        paymentMethod: paymentMethod,
        paymentId: paymentId
      });
      
      // Update the document with email status
      await updateDoc(docRef, {
        emailSent: emailSent
      });
    }
    
    return {
      status: 'success',
      message: 'Payment status updated successfully',
      emailSent: emailSent
    };
  } catch (error) {
    console.error('Error updating payment status:', error);
    throw new Error(`Failed to update payment: ${error.message}`);
  }
};

// ===== MENTOR BLOCK FUNCTIONS =====

/**
 * Save mentor block data to Firestore
 * @param {Object} data - Mentor block data
 * @returns {Promise<Object>} - Result object with ID and success status
 */
export const saveMentorBlock = async (data) => {
  try {
    // Prepare the data
    const mentorBlockData = {
      email: data.email || '',
      name: data.name || '',
      blockType: data.blockType || '',
      hours: data.hours || '',
      price: data.price || '',
      paymentStatus: data.paymentStatus || 'Pending',
      timestamp: serverTimestamp(),
      source: data.source || window.location.href,
      sessionId: data.sessionId || getSessionId(),
      submissionId: data.submissionId || generateId('mentor'),
      emailSent: false,
      notes: ''
    };
    
    // Add to Firestore
    const docRef = await addDoc(collection(db, 'mentorBlocks'), mentorBlockData);
    
    // Send email if payment is completed
    let emailSent = false;
    if (data.paymentStatus === 'Completed' && data.email) {
      emailSent = await sendMentorBlockEmail(data);
      
      // Update the document with email status
      await updateDoc(doc(db, 'mentorBlocks', docRef.id), {
        emailSent: emailSent
      });
    }
    
    return {
      id: docRef.id,
      status: 'success',
      message: 'Mentor block saved successfully',
      emailSent: emailSent
    };
  } catch (error) {
    console.error('Error saving mentor block:', error);
    throw new Error(`Failed to save mentor block: ${error.message}`);
  }
};

/**
 * Update mentor block payment status
 * @param {string} id - Document ID
 * @param {string} paymentStatus - New payment status
 * @param {string} paymentMethod - Payment method used
 * @param {string} paymentId - Payment transaction ID
 * @returns {Promise<Object>} - Result object with success status
 */
export const updateMentorBlockPayment = async (id, paymentStatus, paymentMethod, paymentId) => {
  try {
    const docRef = doc(db, 'mentorBlocks', id);
    const docSnap = await getDoc(docRef);
    
    if (!docSnap.exists()) {
      throw new Error('Mentor block not found');
    }
    
    // Update the document
    await updateDoc(docRef, {
      paymentStatus: paymentStatus,
      paymentMethod: paymentMethod || 'Credit Card',
      paymentId: paymentId || '',
      paymentDate: serverTimestamp()
    });
    
    // Send email if payment is completed
    let emailSent = false;
    if (paymentStatus === 'Completed') {
      const mentorBlockData = docSnap.data();
      emailSent = await sendMentorBlockEmail({
        ...mentorBlockData,
        paymentStatus: paymentStatus,
        paymentMethod: paymentMethod,
        paymentId: paymentId
      });
      
      // Update the document with email status
      await updateDoc(docRef, {
        emailSent: emailSent
      });
    }
    
    return {
      status: 'success',
      message: 'Payment status updated successfully',
      emailSent: emailSent
    };
  } catch (error) {
    console.error('Error updating payment status:', error);
    throw new Error(`Failed to update payment: ${error.message}`);
  }
};

// ===== INVOICE REQUEST FUNCTIONS =====

/**
 * Save invoice request data to Firestore
 * @param {Object} data - Invoice request data
 * @returns {Promise<Object>} - Result object with ID and success status
 */
export const saveInvoiceRequest = async (data) => {
  try {
    // Generate a unique invoice number
    const invoiceNumber = data.invoiceNumber || generateId('INV');
    
    // Prepare the data
    const invoiceData = {
      email: data.email || '',
      name: data.name || '',
      company: data.company || '',
      managerEmail: data.managerEmail || '',
      productType: data.productType || '',
      productName: data.productName || '',
      price: data.price || '',
      notes: data.notes || '',
      timestamp: serverTimestamp(),
      source: data.source || window.location.href,
      sessionId: data.sessionId || getSessionId(),
      submissionId: data.submissionId || generateId('invoice'),
      emailSent: false,
      status: 'Pending',
      invoiceNumber: invoiceNumber,
      paymentStatus: 'Unpaid'
    };
    
    // Add to Firestore
    const docRef = await addDoc(collection(db, 'invoiceRequests'), invoiceData);
    
    // Send invoice email
    let emailSent = false;
    if (data.email) {
      emailSent = await sendInvoiceEmail({
        ...invoiceData,
        invoiceNumber: invoiceNumber
      });
      
      // Update the document with email status
      await updateDoc(doc(db, 'invoiceRequests', docRef.id), {
        emailSent: emailSent
      });
    }
    
    return {
      id: docRef.id,
      invoiceNumber: invoiceNumber,
      status: 'success',
      message: 'Invoice request saved successfully',
      emailSent: emailSent
    };
  } catch (error) {
    console.error('Error saving invoice request:', error);
    throw new Error(`Failed to save invoice request: ${error.message}`);
  }
};

/**
 * Get invoice data by invoice number
 * @param {string} invoiceNumber - Invoice number
 * @returns {Promise<Object>} - Invoice data
 */
export const getInvoiceData = async (invoiceNumber) => {
  try {
    console.log('Fetching invoice data for invoice number:', invoiceNumber);
    
    const q = query(
      collection(db, 'invoiceRequests'), 
      where('invoiceNumber', '==', invoiceNumber)
    );
    
    const querySnapshot = await getDocs(q);
    
    if (querySnapshot.empty) {
      console.error('Invoice not found with number:', invoiceNumber);
      throw new Error('Invoice not found');
    }
    
    // Get the first matching document
    const document = querySnapshot.docs[0];
    const invoiceData = {
      id: document.id,
      ...document.data()
    };
    
    console.log('Retrieved invoice data:', invoiceData);
    return invoiceData;
  } catch (error) {
    console.error('Error getting invoice data:', error);
    throw new Error(`Failed to get invoice: ${error.message}`);
  }
};

/**
 * Approve an invoice
 * @param {string} invoiceNumber - Invoice number
 * @param {string} approverEmail - Email of the approver
 * @returns {Promise<Object>} - Result object with success status
 */
export const approveInvoice = async (invoiceNumber, approverEmail) => {
  try {
    console.log('Approving invoice:', invoiceNumber);
    
    // Get the invoice
    const q = query(
      collection(db, 'invoiceRequests'), 
      where('invoiceNumber', '==', invoiceNumber)
    );
    
    const querySnapshot = await getDocs(q);
    
    if (querySnapshot.empty) {
      console.error('Invoice not found with number:', invoiceNumber);
      throw new Error('Invoice not found');
    }
    
    // Get the first matching document
    const document = querySnapshot.docs[0];
    const invoiceData = document.data();
    const docRef = doc(db, 'invoiceRequests', document.id);
    
    // Update the status to Approved
    await updateDoc(docRef, {
      status: 'Approved',
      approverEmail: approverEmail || '',
      approvalDate: serverTimestamp()
    });
    
    console.log('Invoice approved successfully:', invoiceNumber);
    
    // In a real application, you would send notification emails here
    // For now, we'll just log it
    console.log('Would send approval notification to:', invoiceData.email);
    if (approverEmail) {
      console.log('Would send approval confirmation to approver:', approverEmail);
    }
    
    return {
      status: 'success',
      message: 'Invoice approved successfully',
      emailSent: true
    };
  } catch (error) {
    console.error('Error approving invoice:', error);
    throw new Error(`Failed to approve invoice: ${error.message}`);
  }
};

/**
 * Update invoice payment status
 * @param {string} invoiceNumber - Invoice number
 * @param {string} paymentStatus - New payment status
 * @param {string} paymentMethod - Payment method used
 * @param {string} paymentId - Payment transaction ID
 * @returns {Promise<Object>} - Result object with success status
 */
export const updateInvoicePaymentStatus = async (invoiceNumber, paymentStatus, paymentMethod, paymentId) => {
  try {
    console.log('Updating payment status for invoice:', invoiceNumber);
    
    // Get the invoice
    const q = query(
      collection(db, 'invoiceRequests'), 
      where('invoiceNumber', '==', invoiceNumber)
    );
    
    const querySnapshot = await getDocs(q);
    
    if (querySnapshot.empty) {
      console.error('Invoice not found with number:', invoiceNumber);
      throw new Error('Invoice not found');
    }
    
    // Get the first matching document
    const document = querySnapshot.docs[0];
    const invoiceData = document.data();
    const docRef = doc(db, 'invoiceRequests', document.id);
    
    // Update the payment status
    await updateDoc(docRef, {
      paymentStatus: paymentStatus || 'Paid',
      paymentMethod: paymentMethod || 'Credit Card',
      paymentId: paymentId || '',
      paymentDate: serverTimestamp(),
      status: paymentStatus === 'Paid' ? 'Completed' : invoiceData.status
    });
    
    console.log('Payment status updated successfully for invoice:', invoiceNumber);
    
    // In a real application, you would send a payment confirmation email here
    // For now, we'll just log it
    console.log('Would send payment confirmation to:', invoiceData.email);
    
    return {
      status: 'success',
      message: 'Payment status updated successfully',
      emailSent: true
    };
  } catch (error) {
    console.error('Error updating payment status:', error);
    throw new Error(`Failed to update payment: ${error.message}`);
  }
};

// ===== EMAIL FUNCTIONS =====
// These functions would integrate with your email service
// For now, they return true to simulate successful email sending

/**
 * Send a confirmation email for leadership quiz results
 * @param {Object} data - Quiz data
 * @returns {Promise<boolean>} - Success status
 */
export const sendLeadershipQuizEmail = async (data) => {
  // Implement email sending logic here
  // This could use Firebase Cloud Functions or a third-party email service
  console.log('Sending leadership quiz email to:', data.email);
  return true;
};

/**
 * Send a confirmation email for tier subscription
 * @param {Object} data - Subscription data
 * @returns {Promise<boolean>} - Success status
 */
export const sendTierSubscriptionEmail = async (data) => {
  console.log('Sending tier subscription email to:', data.email);
  return true;
};

/**
 * Send a confirmation email for mentor block purchase
 * @param {Object} data - Mentor block data
 * @returns {Promise<boolean>} - Success status
 */
export const sendMentorBlockEmail = async (data) => {
  console.log('Sending mentor block email to:', data.email);
  return true;
};

/**
 * Send an invoice email for manager approval
 * @param {Object} data - Invoice data
 * @returns {Promise<boolean>} - Success status
 */
export const sendInvoiceEmail = async (data) => {
  console.log('Sending invoice email to:', data.email);
  
  // If manager email is provided, send approval request
  if (data.managerEmail) {
    console.log('Sending approval request to manager:', data.managerEmail);
  }
  
  return true;
};

/**
 * Send notification emails when an invoice is approved
 * @param {Object} invoice - Invoice data
 * @returns {Promise<boolean>} - Success status
 */
export const sendApprovalNotificationEmail = async (invoice) => {
  console.log('Sending approval notification to:', invoice.email);
  
  // If approver email is provided, send confirmation
  if (invoice.approverEmail) {
    console.log('Sending approval confirmation to approver:', invoice.approverEmail);
  }
  
  return true;
};

/**
 * Send a payment confirmation email
 * @param {Object} invoice - Invoice data
 * @returns {Promise<boolean>} - Success status
 */
export const sendPaymentConfirmationEmail = async (invoice) => {
  console.log('Sending payment confirmation to:', invoice.email);
  return true;
}; 