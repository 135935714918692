import React from 'react';
import CourseDetails from '../components/CourseDetails';

const MicrosoftTraining = () => {
  return (
    <>
      {/* Header */}
      <header className="py-5">
        <div className="container px-5">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-xxl-6">
              <div className="text-center my-5">
                <h1 className="fw-bolder mb-3">Microsoft Technologies Training</h1>
                <p className="lead fw-normal text-muted mb-4">
                  Expert training in Microsoft's suite of technologies, designed to help your team master Azure, SQL Server, SharePoint, and Power Platform solutions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* Training Details section */}
      <section className="py-5">
        <div className="container px-5 my-5">
          <div className="row gx-5">
            <div className="col-lg-8">
              <div className="card h-100 shadow border-0">
                <img className="card-img-top" src="/assets/microsoft-apps.png" alt="Microsoft Technologies Training" />
                <div className="card-body p-4">
                  <h2 className="fw-bolder mb-4">Course Overview</h2>
                  <p className="mb-4">
                    Our Microsoft Technologies training program provides comprehensive coverage of Microsoft's enterprise solutions. 
                    Through hands-on exercises and real-world projects, participants will learn to build, deploy, and manage applications using Microsoft's suite of technologies.
                  </p>
                  
                  <h3 className="fw-bolder mb-3">Key Topics Covered</h3>
                  <ul className="list-unstyled mb-4">
                    <li className="mb-2"><i className="bi bi-check-circle-fill text-primary me-2"></i>Azure Cloud Services and Infrastructure</li>
                    <li className="mb-2"><i className="bi bi-check-circle-fill text-primary me-2"></i>SQL Server and Azure SQL Database</li>
                    <li className="mb-2"><i className="bi bi-check-circle-fill text-primary me-2"></i>SharePoint and Microsoft 365</li>
                    <li className="mb-2"><i className="bi bi-check-circle-fill text-primary me-2"></i>Power Platform (Power Apps, Power BI, Power Automate)</li>
                    <li className="mb-2"><i className="bi bi-check-circle-fill text-primary me-2"></i>Azure DevOps and CI/CD</li>
                  </ul>

                  <h3 className="fw-bolder mb-3">Course Benefits</h3>
                  <ul className="list-unstyled mb-4">
                    <li className="mb-2"><i className="bi bi-check-circle-fill text-primary me-2"></i>Hands-on experience with Microsoft technologies</li>
                    <li className="mb-2"><i className="bi bi-check-circle-fill text-primary me-2"></i>Enterprise-grade solution development</li>
                    <li className="mb-2"><i className="bi bi-check-circle-fill text-primary me-2"></i>Integration and automation best practices</li>
                    <li className="mb-2"><i className="bi bi-check-circle-fill text-primary me-2"></i>Security and compliance guidelines</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <CourseDetails 
                duration="40 hours"
                maxParticipants={12}
                format="Hands-on Labs"
                certificationPrep="Microsoft Certification Prep Included"
                enrollLink="#!"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MicrosoftTraining; 