import { db } from './firebase';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';

/**
 * Create sample data in Firestore for testing
 * This function should only be used in development
 */
export const createSampleData = async () => {
  try {
    console.log('Starting to create sample data in Firestore...');
    
    // Create collections if they don't exist
    const collections = [
      'leadershipQuizResults',
      'tierSubscriptions',
      'mentorBlocks',
      'invoiceRequests'
    ];
    
    const results = {
      success: [],
      errors: []
    };
    
    // Create sample leadership quiz results
    try {
      console.log('Creating sample leadership quiz result...');
      const quizRef = await addDoc(collection(db, 'leadershipQuizResults'), {
        email: 'sample@example.com',
        leadershipStyle: 'Visionary',
        timestamp: serverTimestamp(),
        source: 'Sample Data',
        sessionId: 'sample-session-1',
        submissionId: 'sample-quiz-1',
        emailSent: true,
        notes: 'Sample leadership quiz result'
      });
      console.log('Created leadership quiz result with ID:', quizRef.id);
      results.success.push('Leadership Quiz Result');
    } catch (error) {
      console.error('Error creating leadership quiz result:', error);
      results.errors.push(`Leadership Quiz Result: ${error.message}`);
    }
    
    // Create sample tier subscription
    try {
      console.log('Creating sample tier subscription...');
      const subRef = await addDoc(collection(db, 'tierSubscriptions'), {
        email: 'sample@example.com',
        name: 'Sample User',
        tier: 'Professional',
        price: '199900',
        paymentStatus: 'Completed',
        timestamp: serverTimestamp(),
        source: 'Sample Data',
        sessionId: 'sample-session-1',
        submissionId: 'sample-sub-1',
        emailSent: true,
        notes: 'Sample tier subscription'
      });
      console.log('Created tier subscription with ID:', subRef.id);
      results.success.push('Tier Subscription');
    } catch (error) {
      console.error('Error creating tier subscription:', error);
      results.errors.push(`Tier Subscription: ${error.message}`);
    }
    
    // Create sample mentor block
    try {
      console.log('Creating sample mentor block...');
      const mentorRef = await addDoc(collection(db, 'mentorBlocks'), {
        email: 'sample@example.com',
        name: 'Sample User',
        blockType: 'Technical Mentoring',
        hours: '10',
        price: '299900',
        paymentStatus: 'Completed',
        timestamp: serverTimestamp(),
        source: 'Sample Data',
        sessionId: 'sample-session-1',
        submissionId: 'sample-mentor-1',
        emailSent: true,
        notes: 'Sample mentor block'
      });
      console.log('Created mentor block with ID:', mentorRef.id);
      results.success.push('Mentor Block');
    } catch (error) {
      console.error('Error creating mentor block:', error);
      results.errors.push(`Mentor Block: ${error.message}`);
    }
    
    // Create sample invoice requests
    try {
      console.log('Creating sample pending invoice...');
      const pendingInvoiceRef = await addDoc(collection(db, 'invoiceRequests'), {
        email: 'sample@example.com',
        name: 'Sample User',
        company: 'Sample Company',
        managerEmail: 'manager@example.com',
        productType: 'Subscription',
        productName: 'Enterprise Tier',
        price: '499900',
        notes: 'Sample invoice request',
        timestamp: serverTimestamp(),
        source: 'Sample Data',
        sessionId: 'sample-session-1',
        submissionId: 'sample-invoice-1',
        emailSent: true,
        status: 'Pending',
        invoiceNumber: 'INV-SAMPLE-001',
        paymentStatus: 'Unpaid'
      });
      console.log('Created pending invoice with ID:', pendingInvoiceRef.id);
      results.success.push('Pending Invoice');
    } catch (error) {
      console.error('Error creating pending invoice:', error);
      results.errors.push(`Pending Invoice: ${error.message}`);
    }
    
    try {
      console.log('Creating sample approved invoice...');
      const approvedInvoiceRef = await addDoc(collection(db, 'invoiceRequests'), {
        email: 'sample@example.com',
        name: 'Sample User',
        company: 'Sample Company',
        managerEmail: 'manager@example.com',
        productType: 'Mentoring',
        productName: 'Leadership Mentoring',
        price: '399900',
        notes: 'Sample approved invoice',
        timestamp: serverTimestamp(),
        source: 'Sample Data',
        sessionId: 'sample-session-1',
        submissionId: 'sample-invoice-2',
        emailSent: true,
        status: 'Approved',
        invoiceNumber: 'INV-SAMPLE-002',
        paymentStatus: 'Unpaid',
        approverEmail: 'manager@example.com',
        approvalDate: serverTimestamp()
      });
      console.log('Created approved invoice with ID:', approvedInvoiceRef.id);
      results.success.push('Approved Invoice');
    } catch (error) {
      console.error('Error creating approved invoice:', error);
      results.errors.push(`Approved Invoice: ${error.message}`);
    }
    
    try {
      console.log('Creating sample paid invoice...');
      const paidInvoiceRef = await addDoc(collection(db, 'invoiceRequests'), {
        email: 'sample@example.com',
        name: 'Sample User',
        company: 'Sample Company',
        managerEmail: 'manager@example.com',
        productType: 'Training',
        productName: 'Google Cloud Workshop',
        price: '299900',
        notes: 'Sample paid invoice',
        timestamp: serverTimestamp(),
        source: 'Sample Data',
        sessionId: 'sample-session-1',
        submissionId: 'sample-invoice-3',
        emailSent: true,
        status: 'Completed',
        invoiceNumber: 'INV-SAMPLE-003',
        paymentStatus: 'Paid',
        approverEmail: 'manager@example.com',
        approvalDate: serverTimestamp(),
        paymentMethod: 'Credit Card',
        paymentId: 'sample-payment-1',
        paymentDate: serverTimestamp()
      });
      console.log('Created paid invoice with ID:', paidInvoiceRef.id);
      results.success.push('Paid Invoice');
    } catch (error) {
      console.error('Error creating paid invoice:', error);
      results.errors.push(`Paid Invoice: ${error.message}`);
    }
    
    // Generate result message
    let message = '';
    if (results.success.length > 0) {
      message += `Successfully created: ${results.success.join(', ')}. `;
    }
    if (results.errors.length > 0) {
      message += `Errors: ${results.errors.join('; ')}`;
    }
    
    return {
      status: results.errors.length === 0 ? 'success' : 'partial',
      message: message || 'Sample data created successfully',
      details: results
    };
  } catch (error) {
    console.error('Error creating sample data:', error);
    throw new Error(`Failed to create sample data: ${error.message}`);
  }
}; 