import React from 'react';

const FeatureCard = ({ icon, title, description }) => {
  return (
    <div className="col mb-5 h-100">
      <div className="feature bg-primary bg-gradient text-white rounded-3 mb-3">
        <i className={`bi ${icon}`}></i>
      </div>
      <h2 className="h5">{title}</h2>
      <p className="mb-0">{description}</p>
    </div>
  );
};

export default FeatureCard; 