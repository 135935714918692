import React from 'react';
import { Link } from 'react-router-dom';
import ServiceCard from '../components/ServiceCard';

const Mentoring = () => {
  // Mentoring programs data
  const mentoringPrograms = [
    {
      image: '/assets/mentoring.png',
      badge: 'Leadership',
      title: 'Leadership Development',
      description: 'One-on-one coaching focused on developing essential leadership skills, strategic thinking, and effective team management. Learn to inspire and guide your team to achieve exceptional results.',
      link: '/leadership-program'
    },
    {
      image: '/assets/mentoring.png',
      badge: 'Technical',
      title: 'Technical Mentoring',
      description: 'Expert guidance in cloud computing, artificial intelligence, and Microsoft technologies. Accelerate your technical growth with personalized mentoring from industry professionals.',
      link: '/technical-mentoring'
    }
    /* Hidden programs
    ,{
      image: '/assets/mentoring.png',
      badge: 'Career Growth',
      title: 'Career Development',
      description: 'Strategic career planning and professional development guidance. Set clear goals, identify growth opportunities, and create a roadmap for your career advancement.',
      link: '/career-development'
    },
    {
      image: '/assets/mentoring.png',
      badge: 'Team Development',
      title: 'Team Coaching',
      description: 'Group coaching sessions designed to enhance team dynamics, communication, and collaboration. Build high-performing teams that drive innovation and achieve exceptional results.',
      link: '/team-coaching'
    }
    */
  ];

  return (
    <>
      {/* Header */}
      <header className="py-5">
        <div className="container px-5">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-xxl-6">
              <div className="text-center my-5">
                <h1 className="fw-bolder mb-3">Mentoring & Coaching Programs</h1>
                <p className="lead fw-normal text-muted mb-4">
                  Empower your career growth with personalized mentoring and coaching programs designed to develop leadership skills, technical expertise, and professional excellence.
                </p>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* Mentoring Programs section */}
      <section className="py-5">
        <div className="container px-5 my-5">
          <div className="row gx-5">
            {mentoringPrograms.map((program, index) => (
              <div className="col-lg-6 mb-5" key={index}>
                <div className="card h-100 shadow border-0">
                  <img className="card-img-top" src={program.image} alt={program.title} />
                  <div className="card-body p-4">
                    <div className="badge bg-primary bg-gradient rounded-pill mb-2">{program.badge}</div>
                    <Link className="text-decoration-none link-dark stretched-link" to={program.link}>
                      <h5 className="card-title mb-3">{program.title}</h5>
                    </Link>
                    <p className="card-text mb-0">{program.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Mentoring; 