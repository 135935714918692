import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { submitQuizResults, openLeadershipReport } from '../utils/api';

const LeadershipQuiz = () => {
  // Quiz state
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState({});
  const [showResults, setShowResults] = useState(false);
  const [leadershipStyle, setLeadershipStyle] = useState('');
  const [email, setEmail] = useState('');
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  // Quiz questions
  const questions = [
    {
      id: 1,
      question: "When facing a challenging decision, you typically:",
      options: [
        { id: 'a', text: "Gather all available data and analyze it thoroughly before deciding" },
        { id: 'b', text: "Consult with your team to get their input and build consensus" },
        { id: 'c', text: "Trust your instincts and make quick decisions based on experience" },
        { id: 'd', text: "Consider how the decision aligns with the organization's vision and values" }
      ]
    },
    {
      id: 2,
      question: "When communicating with your team, you prefer to:",
      options: [
        { id: 'a', text: "Be direct and straightforward with clear expectations" },
        { id: 'b', text: "Listen first and create an open dialogue" },
        { id: 'c', text: "Inspire with stories and paint a picture of possibilities" },
        { id: 'd', text: "Adapt your communication style to each individual" }
      ]
    },
    {
      id: 3,
      question: "When a team member makes a mistake, your first response is to:",
      options: [
        { id: 'a', text: "Analyze what went wrong and create a process to prevent it in the future" },
        { id: 'b', text: "Have a supportive conversation to understand their perspective" },
        { id: 'c', text: "Focus on quick solutions and moving forward" },
        { id: 'd', text: "Use it as a teaching moment to help them grow" }
      ]
    },
    {
      id: 4,
      question: "Your approach to innovation and change is to:",
      options: [
        { id: 'a', text: "Implement changes methodically with careful planning" },
        { id: 'b', text: "Ensure everyone feels heard and included in the change process" },
        { id: 'c', text: "Embrace change enthusiastically and encourage risk-taking" },
        { id: 'd', text: "Connect changes to the bigger picture and organizational purpose" }
      ]
    },
    {
      id: 5,
      question: "When building a team, you prioritize:",
      options: [
        { id: 'a', text: "Technical expertise and proven competence" },
        { id: 'b', text: "Interpersonal skills and team chemistry" },
        { id: 'c', text: "Drive, ambition, and results-orientation" },
        { id: 'd', text: "Diversity of thought and complementary strengths" }
      ]
    },
    {
      id: 6,
      question: "Your greatest strength as a leader is:",
      options: [
        { id: 'a', text: "Creating structure and ensuring consistent execution" },
        { id: 'b', text: "Building relationships and fostering a positive culture" },
        { id: 'c', text: "Driving results and overcoming obstacles" },
        { id: 'd', text: "Developing others and creating future leaders" }
      ]
    }
  ];

  // Leadership styles
  const leadershipStyles = {
    analytical: {
      title: "Analytical Leader",
      description: "You're a thoughtful, methodical leader who excels at solving complex problems. You value data, structure, and logical processes. Your careful analysis and attention to detail help your team avoid pitfalls and make sound decisions.",
      strengths: ["Strategic thinking", "Problem-solving", "Process improvement", "Risk management"],
      growthAreas: ["May need to make decisions more quickly at times", "Could benefit from showing more emotion and vulnerability", "Might need to simplify communication for some team members"]
    },
    collaborative: {
      title: "Collaborative Leader",
      description: "You're a people-focused leader who builds strong relationships and creates inclusive environments. You excel at listening, mediating conflicts, and creating psychological safety. Your empathetic approach helps team members feel valued and understood.",
      strengths: ["Building trust", "Conflict resolution", "Active listening", "Creating inclusive environments"],
      growthAreas: ["May need to make unpopular decisions more readily", "Could benefit from more direct feedback at times", "Might need to focus more on results alongside relationships"]
    },
    decisive: {
      title: "Decisive Leader",
      description: "You're an action-oriented leader who drives results and embraces challenges. You make decisions quickly and pivot when needed. Your confidence and determination help teams overcome obstacles and achieve ambitious goals.",
      strengths: ["Quick decision-making", "Resilience", "Results orientation", "Adaptability"],
      growthAreas: ["May need to slow down for more complex decisions", "Could benefit from more consultation with team members", "Might need to show more patience with different working styles"]
    },
    visionary: {
      title: "Visionary Leader",
      description: "You're an inspiring leader who connects work to a larger purpose. You excel at developing others and seeing potential. Your ability to communicate a compelling vision helps teams stay motivated through challenges and change.",
      strengths: ["Inspiring others", "Strategic thinking", "Developing talent", "Change management"],
      growthAreas: ["May need to focus more on execution details", "Could benefit from more structured approaches to implementation", "Might need to provide more specific guidance at times"]
    }
  };

  // Handle answer selection
  const handleAnswerSelect = (questionId, answerId) => {
    setAnswers({
      ...answers,
      [questionId]: answerId
    });
    
    // Move to next question or show results if last question
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      calculateResults();
    }
  };

  // Calculate quiz results
  const calculateResults = () => {
    // Count the frequency of each answer type
    const counts = {
      'a': 0, // Analytical
      'b': 0, // Collaborative
      'c': 0, // Decisive
      'd': 0  // Visionary
    };
    
    Object.values(answers).forEach(answer => {
      counts[answer]++;
    });
    
    // Determine dominant style
    let dominantStyle = '';
    let maxCount = 0;
    
    if (counts['a'] > maxCount) {
      maxCount = counts['a'];
      dominantStyle = 'analytical';
    }
    if (counts['b'] > maxCount) {
      maxCount = counts['b'];
      dominantStyle = 'collaborative';
    }
    if (counts['c'] > maxCount) {
      maxCount = counts['c'];
      dominantStyle = 'decisive';
    }
    if (counts['d'] > maxCount) {
      maxCount = counts['d'];
      dominantStyle = 'visionary';
    }
    
    // Handle ties by choosing a random style from the tied ones
    const tiedStyles = [];
    if (counts['a'] === maxCount) tiedStyles.push('analytical');
    if (counts['b'] === maxCount) tiedStyles.push('collaborative');
    if (counts['c'] === maxCount) tiedStyles.push('decisive');
    if (counts['d'] === maxCount) tiedStyles.push('visionary');
    
    if (tiedStyles.length > 1) {
      dominantStyle = tiedStyles[Math.floor(Math.random() * tiedStyles.length)];
    }
    
    setLeadershipStyle(dominantStyle);
    setShowResults(true);
  };

  // Handle email submission
  const handleEmailSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    
    // Submit the quiz results to Google Sheets using no-cors mode
    submitQuizResults(email, leadershipStyle)
      .then(() => {
        // Since we're using no-cors, we can't actually verify if the request succeeded
        // We'll assume it worked if no exception was thrown
        setEmailSubmitted(true);
        setLoading(false);
        
        // Open the leadership report in a new tab
        openLeadershipReport(leadershipStyle);
      })
      .catch(error => {
        console.error('Error submitting email:', error);
        setLoading(false);
        
        // Continue with report download even if the email submission fails
        // This ensures users get their report even if there are CORS issues
        openLeadershipReport(leadershipStyle);
        setEmailSubmitted(true);
      });
  };

  // Restart quiz
  const restartQuiz = () => {
    setCurrentQuestion(0);
    setAnswers({});
    setShowResults(false);
    setLeadershipStyle('');
    setEmail('');
    setEmailSubmitted(false);
  };

  return (
    <>
      {/* Header */}
      <header className="py-5 bg-primary bg-gradient text-white">
        <div className="container px-5">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-xxl-6">
              <div className="text-center my-5">
                <h1 className="fw-bolder mb-3">Understanding Your Leadership Style</h1>
                <p className="lead fw-normal text-white-50 mb-4">
                  Discover your natural leadership tendencies with our quick 6-question assessment.
                  Your results will help you leverage your strengths and identify growth opportunities.
                </p>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* Quiz Section */}
      <section className="py-5">
        <div className="container px-5">
          <div className="row gx-5 justify-content-center">
            <div className="col-lg-8">
              <div className="card border-0 rounded-3 shadow-lg">
                <div className="card-body p-4">
                  {!showResults ? (
                    <>
                      {/* Progress Bar */}
                      <div className="progress mb-4" style={{ height: '8px' }}>
                        <div 
                          className="progress-bar bg-primary" 
                          role="progressbar" 
                          style={{ width: `${((currentQuestion + 1) / questions.length) * 100}%` }}
                          aria-valuenow={((currentQuestion + 1) / questions.length) * 100}
                          aria-valuemin="0" 
                          aria-valuemax="100"
                        ></div>
                      </div>
                      
                      {/* Question */}
                      <div className="text-center mb-4">
                        <h4 className="fw-bolder">Question {currentQuestion + 1} of {questions.length}</h4>
                        <p className="lead fw-normal text-muted mb-0">
                          {questions[currentQuestion].question}
                        </p>
                      </div>
                      
                      {/* Answer Options */}
                      <div className="d-grid gap-3">
                        {questions[currentQuestion].options.map((option) => (
                          <button
                            key={option.id}
                            className="btn btn-outline-primary p-3 text-start"
                            onClick={() => handleAnswerSelect(questions[currentQuestion].id, option.id)}
                          >
                            <span className="fw-bold">{option.id.toUpperCase()}.</span> {option.text}
                          </button>
                        ))}
                      </div>
                    </>
                  ) : (
                    <>
                      {/* Results */}
                      <div className="text-center mb-4">
                        <div className="mb-4">
                          <span className="badge bg-primary fs-6 px-3 py-2 rounded-pill mb-3">Your Results</span>
                          <h3 className="fw-bolder">You are a {leadershipStyles[leadershipStyle].title}</h3>
                          <p className="lead fw-normal text-muted">
                            {leadershipStyles[leadershipStyle].description}
                          </p>
                        </div>
                        
                        <div className="row mb-4">
                          <div className="col-md-6 mb-4 mb-md-0">
                            <div className="card bg-light h-100">
                              <div className="card-body">
                                <h5 className="card-title text-primary">Your Strengths</h5>
                                <ul className="list-unstyled mb-0">
                                  {leadershipStyles[leadershipStyle].strengths.map((strength, index) => (
                                    <li key={index} className="mb-2">
                                      <i className="bi bi-check-circle-fill text-primary me-2"></i>
                                      {strength}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="card bg-light h-100">
                              <div className="card-body">
                                <h5 className="card-title text-primary">Growth Opportunities</h5>
                                <ul className="list-unstyled mb-0">
                                  {leadershipStyles[leadershipStyle].growthAreas.map((area, index) => (
                                    <li key={index} className="mb-2">
                                      <i className="bi bi-arrow-up-circle-fill text-primary me-2"></i>
                                      {area}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        
                        {!emailSubmitted ? (
                          <div className="card border bg-light p-4 mb-4">
                            <h5 className="mb-3">Want a detailed report on your leadership style?</h5>
                            <p className="mb-3">
                              Enter your email below to receive an in-depth analysis of your leadership style, 
                              personalized development tips, and strategies to maximize your effectiveness.
                            </p>
                            <form onSubmit={handleEmailSubmit}>
                              <div className="input-group mb-3">
                                <input
                                  type="email"
                                  className="form-control"
                                  placeholder="Your email address"
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                  required
                                />
                                <button 
                                  className="btn btn-primary" 
                                  type="submit"
                                  disabled={loading}
                                >
                                  {loading ? (
                                    <>
                                      <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                      Sending...
                                    </>
                                  ) : (
                                    'Get My Report'
                                  )}
                                </button>
                              </div>
                              <div className="form-text">
                                We respect your privacy and will never share your email with third parties.
                              </div>
                            </form>
                          </div>
                        ) : (
                          <div className="alert alert-success" role="alert">
                            <i className="bi bi-check-circle-fill me-2"></i>
                            <strong>Thank you!</strong> Your detailed leadership report has been sent to {email} and opened in a new tab. If the report didn't open automatically, <a href={`/assets/leadership-reports/${leadershipStyle}-leader-report.html`} target="_blank" rel="noopener noreferrer">click here</a> to view it.
                          </div>
                        )}
                        
                        <div className="d-grid gap-2 d-md-flex justify-content-md-center mt-4">
                          <button 
                            className="btn btn-outline-primary px-4"
                            onClick={restartQuiz}
                          >
                            <i className="bi bi-arrow-repeat me-2"></i>
                            Take the Quiz Again
                          </button>
                          <Link 
                            className="btn btn-primary px-4"
                            to="/leadership-program"
                          >
                            <i className="bi bi-arrow-right me-2"></i>
                            Explore Our Leadership Program
                          </Link>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LeadershipQuiz; 