import React from 'react';
import { Link } from 'react-router-dom';

const LaunchpadModal = () => {
  return (
    <div className="modal fade" id="launchpadModal" tabIndex="-1" aria-labelledby="launchpadModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div className="modal-content">
          <div className="modal-header bg-primary text-white">
            <h5 className="modal-title" id="launchpadModalLabel">You Attended the Launchpad Event—Now What?</h5>
            <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className="container-fluid">
              {/* The Problem Section */}
              <div className="mb-5">
                <h4 className="text-primary mb-4">The Problem: You See the Possibilities, But What's Next?</h4>
                <p className="lead">You just attended <strong>the Launchpad event</strong>, and now you <strong>finally see the big picture</strong>—how cloud technologies fit together, how they drive innovation, and how they can transform your career.</p>
                
                <div className="row mb-4">
                  <div className="col-md-4">
                    <div className="d-flex align-items-center mb-2">
                      <i className="bi bi-rocket-takeoff-fill text-primary fs-4 me-2"></i>
                      <p className="mb-0">You're excited about the possibilities.</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="d-flex align-items-center mb-2">
                      <i className="bi bi-rocket-takeoff-fill text-primary fs-4 me-2"></i>
                      <p className="mb-0">You understand how Google Cloud works at a high level.</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="d-flex align-items-center mb-2">
                      <i className="bi bi-rocket-takeoff-fill text-primary fs-4 me-2"></i>
                      <p className="mb-0">You see how companies are using cloud solutions.</p>
                    </div>
                  </div>
                </div>

                <div className="bg-light p-4 rounded mb-4">
                  <h5 className="mb-3">But now, the <strong>real questions</strong> hit you:</h5>
                  <div className="d-flex align-items-center mb-2">
                    <i className="bi bi-question-circle-fill text-primary fs-4 me-3"></i>
                    <p className="mb-0">How do YOU turn this knowledge into real career growth?</p>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <i className="bi bi-question-circle-fill text-primary fs-4 me-3"></i>
                    <p className="mb-0">What are YOUR next steps?</p>
                  </div>
                  <div className="d-flex align-items-center">
                    <i className="bi bi-question-circle-fill text-primary fs-4 me-3"></i>
                    <p className="mb-0">Which cloud skills should YOU focus on to move up in your company?</p>
                  </div>
                </div>

                <p>Your manager is <strong>supportive</strong>, but there's <strong>no detailed plan</strong>—just the expectation that you'll <strong>figure it out on your own</strong>.</p>
                <p className="lead">That's where the <strong>Technical Mentoring Program</strong> comes in.</p>
              </div>

              {/* Why Mentoring Blocks Section */}
              <div className="mb-5">
                <h4 className="text-primary mb-4">Why Mentoring Blocks Are Your Next Step</h4>
                <p>The <strong>free Launchpad event</strong> gave you an <strong>overview</strong> of cloud technologies.<br />
                Now, the <strong>mentoring program</strong> gives you <strong>structured guidance</strong> to:</p>

                <div className="row g-4">
                  <div className="col-md-6">
                    <div className="card h-100 border-0 bg-light">
                      <div className="card-body">
                        <h5 className="card-title">
                          <i className="bi bi-compass text-primary me-2"></i>
                          Clarify Your Career Path
                        </h5>
                        <p className="card-text">Identify the <strong>exact skills</strong> needed to move into <strong>cloud architecture, security, or DevOps</strong>.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card h-100 border-0 bg-light">
                      <div className="card-body">
                        <h5 className="card-title">
                          <i className="bi bi-map text-primary me-2"></i>
                          Develop a Personalized Learning Plan
                        </h5>
                        <p className="card-text">No more guessing—get a roadmap based on your <strong>current skillset and career goals</strong>.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card h-100 border-0 bg-light">
                      <div className="card-body">
                        <h5 className="card-title">
                          <i className="bi bi-tools text-primary me-2"></i>
                          Gain Hands-On Technical Guidance
                        </h5>
                        <p className="card-text">Work on <strong>real-world projects</strong> tailored to <strong>your company's needs</strong>.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card h-100 border-0 bg-light">
                      <div className="card-body">
                        <h5 className="card-title">
                          <i className="bi bi-lightning-charge text-primary me-2"></i>
                          Bridge Theory and Practice
                        </h5>
                        <p className="card-text">Learn <strong>how to actually use cloud tools on the job</strong>.</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="alert alert-info mt-4">
                  <i className="bi bi-info-circle-fill me-2"></i>
                  This is <strong>not a course</strong>—it's a <strong>hands-on, on-demand mentoring experience</strong>, where you bring your <strong>real-world cloud challenges</strong> and get <strong>personalized guidance</strong> from an expert.
                </div>
              </div>

              {/* How It Works Section */}
              <div className="mb-5">
                <h4 className="text-primary mb-4">How the Mentoring Program Works</h4>
                <div className="row g-4">
                  <div className="col-md-6">
                    <div className="d-flex align-items-start mb-3">
                      <div className="bg-primary text-white rounded-circle p-2 me-3" style={{ width: '35px', height: '35px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>1</div>
                      <p className="mb-0">Purchase a <strong>40-hour mentoring block</strong> (hours expire in one year, use them as needed).</p>
                    </div>
                    <div className="d-flex align-items-start mb-3">
                      <div className="bg-primary text-white rounded-circle p-2 me-3" style={{ width: '35px', height: '35px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>2</div>
                      <p className="mb-0">Bring your <strong>cloud challenges, career questions, or project roadblocks</strong>.</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex align-items-start mb-3">
                      <div className="bg-primary text-white rounded-circle p-2 me-3" style={{ width: '35px', height: '35px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>3</div>
                      <p className="mb-0">Receive <strong>expert 1:1 guidance</strong> on technical topics, career strategy, or cloud best practices.</p>
                    </div>
                    <div className="d-flex align-items-start">
                      <div className="bg-primary text-white rounded-circle p-2 me-3" style={{ width: '35px', height: '35px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>4</div>
                      <p className="mb-0">Track your progress and adjust your plan as you grow.</p>
                    </div>
                  </div>
                </div>

                <div className="alert alert-warning mt-4">
                  <div className="d-flex align-items-center mb-2">
                    <i className="bi bi-lightbulb-fill fs-4 me-2"></i>
                    <strong>If your company is a Google Cloud customer, they may cover the cost of mentoring!</strong>
                  </div>
                  <p className="mb-0">We provide an invoice option for manager approval and company-sponsored learning.</p>
                </div>
              </div>

              {/* Who Is This For Section */}
              <div className="mb-5">
                <h4 className="text-primary mb-4">Who Is This For?</h4>
                <div className="row">
                  <div className="col-md-6">
                    <ul className="list-unstyled">
                      <li className="mb-3">
                        <i className="bi bi-check-circle-fill text-success me-2"></i>
                        You attended Launchpad and now want to advance in your cloud career.
                      </li>
                      <li className="mb-3">
                        <i className="bi bi-check-circle-fill text-success me-2"></i>
                        You're expected to 'figure it out' at work, but you need structure and guidance.
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="list-unstyled">
                      <li className="mb-3">
                        <i className="bi bi-check-circle-fill text-success me-2"></i>
                        You want to move into a higher-paying cloud role.
                      </li>
                      <li className="mb-3">
                        <i className="bi bi-check-circle-fill text-success me-2"></i>
                        You need technical mentorship to apply cloud concepts in your real job.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* Pricing Section */}
              <div className="text-center mb-5">
                <h4 className="text-primary mb-4">Next Step: Secure Your Mentoring Block Today</h4>
                <div className="row justify-content-center">
                  <div className="col-md-6">
                    <div className="card border-0 bg-light mb-3">
                      <div className="card-body">
                        <h5 className="card-title">Standard Rate</h5>
                        <div className="display-6 fw-bold mb-0">$8,000</div>
                        <p className="text-muted">for 40 hours</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card border-0 bg-light mb-3">
                      <div className="card-body">
                        <h5 className="card-title">Tier 3 Members</h5>
                        <div className="display-6 fw-bold mb-0">$6,000</div>
                        <p className="text-muted">for 40 hours (25% off)</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Final CTA */}
              <div className="text-center">
                <p className="mb-3">
                  <i className="bi bi-lightbulb text-primary me-2"></i>
                  <strong>Still unsure?</strong> Let's talk. If you're wondering <strong>how mentoring can help your career</strong>, schedule a quick call to explore your goals.
                </p>
                <p className="lead fw-bold mb-0">
                  <i className="bi bi-rocket-takeoff text-primary me-2"></i>
                  You've seen the possibilities. Now it's time to act.
                </p>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LaunchpadModal; 