import React from 'react';
import { Link } from 'react-router-dom';

const AIMentoringModal = () => {
  return (
    <div className="modal fade" id="aiMentoringModal" tabIndex="-1" aria-labelledby="aiMentoringModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div className="modal-content">
          <div className="modal-header bg-primary text-white">
            <h5 className="modal-title" id="aiMentoringModalLabel">AI is Moving Fast—Are You Keeping Up?</h5>
            <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className="container-fluid">
              {/* Introduction Section */}
              <div className="mb-4">
                <p className="lead">The world of <strong>AI and cloud technology is evolving faster than ever.</strong> Every day, new tools, new models, and new platforms emerge—each promising to revolutionize the way businesses operate.</p>
                
                <div className="bg-light p-4 rounded mb-4">
                  <h5 className="mb-3">But here's the problem:</h5>
                  <div className="d-flex align-items-center mb-3">
                    <i className="bi bi-question-circle-fill text-primary fs-4 me-3"></i>
                    <p className="mb-0"><strong>Which AI and cloud technologies should YOU focus on?</strong></p>
                  </div>
                  <div className="d-flex align-items-center mb-3">
                    <i className="bi bi-question-circle-fill text-primary fs-4 me-3"></i>
                    <p className="mb-0"><strong>What training will actually help YOUR specific workload?</strong></p>
                  </div>
                  <div className="d-flex align-items-center">
                    <i className="bi bi-question-circle-fill text-primary fs-4 me-3"></i>
                    <p className="mb-0"><strong>How do you choose between Microsoft and Google certifications?</strong></p>
                  </div>
                </div>

                <p>With so many options, it's <strong>easy to feel overwhelmed</strong>—and wasting time on the wrong courses or certifications can <strong>stall your career growth.</strong></p>
                <p className="lead">That's where the <strong>Technical Mentoring Program</strong> comes in.</p>
              </div>

              {/* Expert Guidance Section */}
              <div className="mb-5">
                <h4 className="text-primary mb-4">Get Expert Guidance Tailored to Your Goals</h4>
                <p>At <strong>Charsoft Consulting</strong>, we're not just trainers—we're <strong>resellers and expert partners for both Google Cloud and Microsoft 365/Azure</strong>.</p>
                <p>We don't just <strong>teach technology</strong>—we help professionals <strong>navigate the complex landscape of AI and cloud computing</strong> to make <strong>strategic career decisions</strong>.</p>

                <div className="card mb-4 border-0 bg-light">
                  <div className="card-body">
                    <ul className="list-unstyled mb-0">
                      <li className="mb-3">
                        <i className="bi bi-check-circle-fill text-success me-2"></i>
                        <strong>We are official resellers and expert partners for both Microsoft 365/Azure and Google Cloud.</strong>
                      </li>
                      <li className="mb-3">
                        <i className="bi bi-check-circle-fill text-success me-2"></i>
                        <strong>We teach instructor-led training through Learning Tree International & ROI Training, so we can recommend the best courses for your needs.</strong>
                      </li>
                      <li className="mb-3">
                        <i className="bi bi-check-circle-fill text-success me-2"></i>
                        <strong>We'll map out a learning plan that actually fits YOUR job, workload, and career aspirations.</strong>
                      </li>
                    </ul>
                  </div>
                </div>

                <p>Instead of struggling to <strong>figure out the right path</strong>, we'll help you:</p>
                <div className="row g-4">
                  <div className="col-md-6">
                    <div className="card h-100 border-0 bg-light">
                      <div className="card-body">
                        <i className="bi bi-award text-primary me-2"></i>
                        <strong>Identify which AI/cloud certifications will benefit YOUR specific role.</strong>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card h-100 border-0 bg-light">
                      <div className="card-body">
                        <i className="bi bi-book text-primary me-2"></i>
                        <strong>Recommend the right training programs from our partners.</strong>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card h-100 border-0 bg-light">
                      <div className="card-body">
                        <i className="bi bi-laptop text-primary me-2"></i>
                        <strong>Guide you through hands-on labs and real-world use cases.</strong>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card h-100 border-0 bg-light">
                      <div className="card-body">
                        <i className="bi bi-gear text-primary me-2"></i>
                        <strong>Help you apply AI & cloud technologies in your day-to-day job.</strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* How It Works Section */}
              <div className="mb-5">
                <h4 className="text-primary mb-4">How It Works: The Mentoring Block Model</h4>
                <div className="row g-4">
                  <div className="col-md-6">
                    <div className="d-flex align-items-start mb-3">
                      <div className="bg-primary text-white rounded-circle p-2 me-3" style={{ width: '35px', height: '35px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>1</div>
                      <p className="mb-0">Purchase a <strong>40-hour mentoring block</strong> (valid for 1 year, use hours as needed).</p>
                    </div>
                    <div className="d-flex align-items-start mb-3">
                      <div className="bg-primary text-white rounded-circle p-2 me-3" style={{ width: '35px', height: '35px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>2</div>
                      <p className="mb-0">Get <strong>1:1 expert guidance</strong> on AI, cloud, and certification strategies.</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex align-items-start mb-3">
                      <div className="bg-primary text-white rounded-circle p-2 me-3" style={{ width: '35px', height: '35px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>3</div>
                      <p className="mb-0">Work with an <strong>industry-certified mentor</strong> to solve real challenges.</p>
                    </div>
                    <div className="d-flex align-items-start">
                      <div className="bg-primary text-white rounded-circle p-2 me-3" style={{ width: '35px', height: '35px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>4</div>
                      <p className="mb-0">Learn how to <strong>position yourself for career growth</strong> in the AI-driven world.</p>
                    </div>
                  </div>
                </div>

                <div className="alert alert-warning mt-4">
                  <div className="d-flex align-items-center">
                    <i className="bi bi-lightbulb-fill fs-4 me-2"></i>
                    <strong>Your company may cover the cost of mentoring—invoice available for corporate sponsorship!</strong>
                  </div>
                </div>
              </div>

              {/* Who Should Sign Up Section */}
              <div className="mb-5">
                <h4 className="text-primary mb-4">Who Should Sign Up?</h4>
                <div className="row">
                  <div className="col-md-6">
                    <ul className="list-unstyled">
                      <li className="mb-3">
                        <i className="bi bi-check-circle-fill text-success me-2"></i>
                        You need help choosing the right AI/cloud certification path.
                      </li>
                      <li className="mb-3">
                        <i className="bi bi-check-circle-fill text-success me-2"></i>
                        You want to strategically upskill in Microsoft Azure or Google Cloud AI.
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="list-unstyled">
                      <li className="mb-3">
                        <i className="bi bi-check-circle-fill text-success me-2"></i>
                        You need expert guidance on how AI impacts your specific workload.
                      </li>
                      <li className="mb-3">
                        <i className="bi bi-check-circle-fill text-success me-2"></i>
                        You want a structured learning roadmap instead of aimless training.
                      </li>
                    </ul>
                  </div>
                </div>
                <p className="lead text-center mt-4">🚀 <strong>AI is moving fast—don't get left behind. Let's create a strategy that works for YOU.</strong></p>
              </div>

              {/* Pricing Section */}
              <div className="text-center mb-5">
                <h4 className="text-primary mb-4">Secure Your Mentoring Block Today</h4>
                <div className="row justify-content-center">
                  <div className="col-md-6">
                    <div className="card border-0 bg-light mb-3">
                      <div className="card-body">
                        <h5 className="card-title">Standard Rate</h5>
                        <div className="display-6 fw-bold mb-0">$8,000</div>
                        <p className="text-muted">for 40 hours</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card border-0 bg-light mb-3">
                      <div className="card-body">
                        <h5 className="card-title">Tier 3 Members</h5>
                        <div className="display-6 fw-bold mb-0">$6,000</div>
                        <p className="text-muted">for 40 hours (25% off)</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Final CTA */}
              <div className="text-center">
                <p className="mb-3">
                  <i className="bi bi-lightbulb text-primary me-2"></i>
                  <strong>Have questions? Let's discuss your goals.</strong> Whether you're transitioning to AI, exploring Google/Microsoft training, or mapping out your next career step, we're here to help.
                </p>
                <p className="lead fw-bold mb-0">
                  <i className="bi bi-rocket-takeoff text-primary me-2"></i>
                  With AI evolving daily, now is the time to invest in expert mentoring. Let's build your future together.
                </p>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AIMentoringModal; 