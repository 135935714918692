import React, { useState, useEffect, lazy, Suspense } from 'react';
import PaymentConfirmation from '../components/PaymentConfirmation';
import stripeConfig from '../config/stripe';
import { Link, useNavigate } from 'react-router-dom';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { submitTierSubscription } from '../utils/api';
import InvoiceRequestModal from '../components/InvoiceRequestModal';

// Lazy load the StripePayment component
const StripePayment = lazy(() => import('../components/StripePayment'));

const Signup = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    discord: '',
    company: '',
    subscriptionTier: 'tier3',
    paymentMethod: '',
    termsCheck: false
  });

  const [validated, setValidated] = useState(false);
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState(null);
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);

  // Check if all required fields are filled
  const isFormValid = () => {
    return (
      formData.fullName.trim() !== '' &&
      formData.email.trim() !== '' &&
      formData.discord.trim() !== '' &&
      formData.subscriptionTier !== '' &&
      formData.paymentMethod !== ''
    );
  };

  const handleInputChange = (e) => {
    const { id, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [id]: type === 'checkbox' ? checked : value
    });
  };

  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    } else {
      setValidated(true);
      
      // Submit subscription data to Google Sheets with "Pending" status
      // Get subscription details
      const subscriptionName = getSubscriptionName();
      const subscriptionAmount = getSubscriptionAmount() / 100;
      
      submitTierSubscription({
        email: formData.email,
        name: formData.fullName,
        tier: subscriptionName,
        price: subscriptionAmount.toFixed(2),
        paymentStatus: 'Form Submitted',
        discord: formData.discord,
        company: formData.company || 'Not provided'
      })
      .then(response => {
        // console.log('Initial subscription data submitted to Google Sheets:', response);
      })
      .catch(error => {
        console.error('Error submitting initial subscription data:', error);
      });
      
      setShowPaymentForm(true);
    }
  };

  const getSubscriptionAmount = () => {
    // TEMPORARY: Return $2.00 (200 cents) for testing purposes
    return 200;
    
    /* Original code - uncomment after testing
    switch(formData.subscriptionTier) {
      case 'tier1':
        return stripeConfig.products.tier1.amount;
      case 'tier2':
        return stripeConfig.products.tier2.amount;
      case 'tier3':
        return stripeConfig.products.tier3.amount;
      default:
        return 0;
    }
    */
  };

  const getSubscriptionName = () => {
    switch(formData.subscriptionTier) {
      case 'tier1':
        return stripeConfig.products.tier1.name;
      case 'tier2':
        return stripeConfig.products.tier2.name;
      case 'tier3':
        return stripeConfig.products.tier3.name;
      default:
        return 'Technical Mentoring Subscription';
    }
  };

  const handlePaymentSuccess = (paymentMethod) => {
    // console.log('Payment successful:', paymentMethod);
    setPaymentInfo({
      brand: paymentMethod.card.brand,
      last4: paymentMethod.card.last4,
      expMonth: paymentMethod.card.exp_month,
      expYear: paymentMethod.card.exp_year
    });
    
    // Get subscription details
    const subscriptionName = getSubscriptionName();
    const subscriptionAmount = getSubscriptionAmount() / 100;
    
    // Update subscription data in Google Sheets with "Completed" status
    submitTierSubscription({
      email: formData.email,
      name: formData.fullName,
      tier: subscriptionName,
      price: subscriptionAmount.toFixed(2),
      paymentStatus: 'Payment Completed',
      discord: formData.discord,
      company: formData.company || 'Not provided'
    })
    .then(response => {
      // console.log('Payment completion data submitted to Google Sheets:', response);
    })
    .catch(error => {
      console.error('Error submitting payment completion data:', error);
      // Continue with the flow even if Google Sheets submission fails
    });
    
    setPaymentSuccess(true);
  };

  const handlePaymentError = (error) => {
    console.error('Payment error:', error);
    
    // Get subscription details
    const subscriptionName = getSubscriptionName();
    const subscriptionAmount = getSubscriptionAmount() / 100;
    
    // Update subscription data in Google Sheets with "Failed" status
    submitTierSubscription({
      email: formData.email,
      name: formData.fullName,
      tier: subscriptionName,
      price: subscriptionAmount.toFixed(2),
      paymentStatus: 'Payment Failed',
      discord: formData.discord,
      company: formData.company || 'Not provided',
      paymentError: error.message || 'Unknown payment error'
    })
    .then(response => {
      // console.log('Payment failure data submitted to Google Sheets:', response);
    })
    .catch(err => {
      console.error('Error submitting payment failure data:', err);
    });
    
    // You could set an error state here and display it to the user
  };

  const generateInvoice = () => {
    // console.log('Generating PDF invoice...');
    
    // Create a new PDF document
    const doc = new jsPDF();
    const today = new Date();
    const invoiceNumber = `INV-${today.getFullYear()}${(today.getMonth() + 1).toString().padStart(2, '0')}${today.getDate().toString().padStart(2, '0')}-${Math.floor(Math.random() * 10000).toString().padStart(4, '0')}`;
    const dueDate = new Date(today);
    dueDate.setDate(dueDate.getDate() + 30);
    
    // Add Charsoft logo and company info
    doc.setFontSize(20);
    doc.setFont('helvetica', 'bold');
    doc.text('Charsoft Consulting', 20, 20);
    
    doc.setFontSize(10);
    doc.setFont('helvetica', 'normal');
    doc.text('', 20, 30);
    doc.text('Bristow, VA 20136', 20, 35);
    doc.text('Phone: (703) 261-9371', 20, 40);
    doc.text('Email: billing@charsoft.com', 20, 45);
    doc.text('Web: www.charsoft.com', 20, 50);
    
    // Add invoice title and details
    doc.setFontSize(16);
    doc.setFont('helvetica', 'bold');
    doc.text('INVOICE', 150, 20);
    
    doc.setFontSize(10);
    doc.setFont('helvetica', 'normal');
    doc.text(`Invoice Number: ${invoiceNumber}`, 150, 30);
    doc.text(`Date: ${today.toLocaleDateString()}`, 150, 35);
    doc.text(`Due Date: ${dueDate.toLocaleDateString()}`, 150, 40);
    
    // Add client information
    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    doc.text('Bill To:', 20, 65);
    
    doc.setFontSize(10);
    doc.setFont('helvetica', 'normal');
    doc.text(formData.fullName, 20, 75);
    if (formData.company) {
      doc.text(formData.company, 20, 80);
      doc.text(formData.email, 20, 85);
    } else {
      doc.text(formData.email, 20, 80);
    }
    
    // Get subscription details
    const subscriptionName = getSubscriptionName();
    const subscriptionAmount = getSubscriptionAmount() / 100;
    
    // Create detailed description with form fields
    let tierDescription = '';
    switch(formData.subscriptionTier) {
      case 'tier1':
        tierDescription = 'Self-Paced Learning - Access to pre-recorded content and resources';
        break;
      case 'tier2':
        tierDescription = 'Interactive Learning - Live Q&A sessions and community support';
        break;
      case 'tier3':
        tierDescription = 'Premium Access - Customized Mentoring in small groups with access to 1:1 sessions with career coaching and personalized Tech roadmap';
        break;
      default:
        tierDescription = 'Technical Mentoring Subscription';
    }
    
    const detailedDescription = 
      `Monthly technical mentoring subscription: ${tierDescription}\n` +
      `Client: ${formData.fullName}\n` +
      `Email: ${formData.email}\n` +
      `Discord: ${formData.discord}\n` +
      `Company: ${formData.company || 'Not provided'}\n` +
      `Payment Method: Credit/Debit Card (via Stripe)`;
    
    // Add invoice items
    doc.autoTable({
      startY: formData.company ? 95 : 90,
      head: [['Item', 'Description', 'Quantity', 'Unit Price', 'Amount']],
      body: [
        [
          subscriptionName,
          detailedDescription,
          '1',
          `$${subscriptionAmount.toFixed(2)}/month`,
          `$${subscriptionAmount.toFixed(2)}`
        ]
      ],
      foot: [
        ['', '', '', 'Subtotal', `$${subscriptionAmount.toFixed(2)}`],
        ['', '', '', 'Tax (0%)', '$0.00'],
        ['', '', '', 'Total (Monthly)', `$${subscriptionAmount.toFixed(2)}`]
      ],
      theme: 'grid',
      headStyles: { fillColor: [41, 128, 185], textColor: 255 },
      footStyles: { fillColor: [240, 240, 240], textColor: 0, fontStyle: 'bold' },
      columnStyles: {
        1: { cellWidth: 'auto' }
      },
      styles: {
        overflow: 'linebreak',
        cellPadding: 3
      }
    });
    
    // Add payment instructions and terms
    const finalY = doc.lastAutoTable.finalY + 10;
    
    doc.setFontSize(11);
    doc.setFont('helvetica', 'bold');
    doc.text('Payment Instructions:', 20, finalY);
    
    doc.setFontSize(10);
    doc.setFont('helvetica', 'normal');
    doc.text('Please make payment via credit card, bank transfer, or check payable to Charsoft Consulting.', 20, finalY + 10);
    doc.text('For bank transfers, please contact billing@charsoft.com for account details.', 20, finalY + 15);
    
    doc.setFontSize(11);
    doc.setFont('helvetica', 'bold');
    doc.text('Terms & Conditions:', 20, finalY + 25);
    
    doc.setFontSize(10);
    doc.setFont('helvetica', 'normal');
    doc.text('1. This is a recurring monthly subscription.', 20, finalY + 35);
    doc.text('2. Subscription will be automatically billed each month until canceled.', 20, finalY + 40);
    doc.text('3. Cancellation requires 30 days notice before the next billing cycle.', 20, finalY + 45);
    
    doc.setFontSize(10);
    doc.setFont('helvetica', 'italic');
    doc.text('Thank you for choosing Charsoft Consulting for your technical mentoring needs!', 20, finalY + 55);
    
    // Track invoice download in Google Sheets
    submitTierSubscription({
      email: formData.email,
      name: formData.fullName,
      tier: subscriptionName,
      price: subscriptionAmount.toFixed(2),
      paymentStatus: 'Invoice Downloaded',
      discord: formData.discord,
      company: formData.company || 'Not provided',
      invoiceNumber: invoiceNumber
    })
    .then(response => {
      // console.log('Invoice download data submitted to Google Sheets:', response);
    })
    .catch(error => {
      console.error('Error submitting invoice download data:', error);
    });
    
    // Save the PDF
    doc.save(`Charsoft_TechnicalMentoring_Invoice_${invoiceNumber}.pdf`);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  // Handle invoice request button click
  const handleInvoiceRequest = (e) => {
    e.preventDefault(); // Prevent default button behavior
    // console.log('Invoice request button clicked');
    setShowInvoiceModal(true);
  };

  // Handle invoice request success
  const handleInvoiceSuccess = (result) => {
    // console.log('Invoice request successful:', result);
    
    // Get subscription details
    const subscriptionName = getSubscriptionName();
    const subscriptionAmount = getSubscriptionAmount() / 100;
    
    // Update subscription data in Google Sheets with "Invoice Requested" status
    submitTierSubscription({
      email: formData.email,
      name: formData.fullName,
      tier: subscriptionName,
      price: subscriptionAmount.toFixed(2),
      paymentStatus: 'Invoice Requested',
      discord: formData.discord,
      company: formData.company || 'Not provided'
    })
    .then(response => {
      // console.log('Invoice request data submitted to Google Sheets:', response);
    })
    .catch(error => {
      console.error('Error submitting invoice request data:', error);
    });
  };

  if (paymentSuccess) {
    return (
      <div className="container px-5 py-5">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <PaymentConfirmation 
              paymentInfo={paymentInfo}
              subscriptionDetails={{
                name: getSubscriptionName(),
                amount: (getSubscriptionAmount() / 100).toFixed(2)
              }}
              userDetails={{
                email: formData.email,
                name: formData.fullName
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {/* Header */}
      <header className="py-5">
        <div className="container px-5">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-xxl-6">
              <div className="text-center my-5">
                <h1 className="fw-bolder mb-3">Join Our Technical Mentoring Program</h1>
                <p className="lead fw-normal text-muted mb-4">
                  Take the next step in your technical career journey with expert guidance and support.
                </p>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* Signup Form Section */}
      <section className="py-5">
        <div className="container px-5">
          <div className="row gx-5 justify-content-center">
            <div className="col-lg-8">
              <div className="card border-0 rounded-3 shadow-lg">
                <div className="card-body p-4">
                  <button 
                    className="btn btn-sm btn-outline-secondary position-absolute top-0 end-0 mt-3 me-3" 
                    onClick={handleGoBack}
                    type="button"
                    aria-label="Close"
                  >
                    <i className="bi bi-x-lg"></i>
                  </button>
                  <div className="text-center mb-4">
                    <div className="alert alert-warning mb-3">
                      <strong>TEST MODE</strong> - Payments are set to $2.00 for testing purposes
                    </div>
                    <h4 className="fw-bolder">{showPaymentForm ? 'Complete Your Payment' : 'Complete Your Registration'}</h4>
                    <p className="lead fw-normal text-muted mb-0">
                      {showPaymentForm 
                        ? `You're signing up for ${getSubscriptionName()} at $${(getSubscriptionAmount() / 100).toFixed(2)}/month`
                        : 'All fields marked with * are required'
                      }
                    </p>
                  </div>

                  {showPaymentForm ? (
                    <Suspense fallback={<div>Loading Stripe Payment...</div>}>
                      <StripePayment 
                        amount={getSubscriptionAmount()} 
                        onSuccess={handlePaymentSuccess}
                        onError={handlePaymentError}
                      />
                    </Suspense>
                  ) : (
                    <form 
                      className={`needs-validation ${validated ? 'was-validated' : ''}`} 
                      noValidate
                      onSubmit={handleSubmit}
                    >
                      {/* Full Name */}
                      <div className="mb-3">
                        <label htmlFor="fullName" className="form-label">Full Name *</label>
                        <input 
                          type="text" 
                          className="form-control" 
                          id="fullName" 
                          value={formData.fullName}
                          onChange={handleInputChange}
                          required 
                        />
                        <div className="invalid-feedback">
                          Please provide your full name.
                        </div>
                      </div>

                      {/* Email */}
                      <div className="mb-3">
                        <label htmlFor="email" className="form-label">Email Address *</label>
                        <input 
                          type="email" 
                          className="form-control" 
                          id="email" 
                          value={formData.email}
                          onChange={handleInputChange}
                          required 
                        />
                        <div className="invalid-feedback">
                          Please provide a valid email address.
                        </div>
                      </div>

                      {/* Discord Username */}
                      <div className="mb-3">
                        <label htmlFor="discord" className="form-label">Discord Username *</label>
                        <input 
                          type="text" 
                          className="form-control" 
                          id="discord" 
                          value={formData.discord}
                          onChange={handleInputChange}
                          required 
                        />
                        <div className="form-text">
                          Include your full Discord username (e.g., username#1234). 
                          Don't have Discord? <a href="https://discord.com/register" target="_blank" rel="noopener noreferrer">Sign up here</a>.
                        </div>
                        <div className="invalid-feedback">
                          Please provide your Discord username.
                        </div>
                      </div>

                      {/* Company Name */}
                      <div className="mb-3">
                        <label htmlFor="company" className="form-label">Company Name (Optional)</label>
                        <input 
                          type="text" 
                          className="form-control" 
                          id="company" 
                          value={formData.company}
                          onChange={handleInputChange}
                        />
                      </div>

                      {/* Subscription Tier */}
                      <div className="mb-4">
                        <label className="form-label">Choose Your Subscription Tier *</label>
                        <div className="card border-0 bg-light">
                          <div className="card-body">
                            {/* Note: Currently only Premium Mentoring (Tier 3) is available */}
                            
                            {/* Tier 1 - Temporarily unavailable
                            <div className="form-check mb-3">
                              <input 
                                className="form-check-input" 
                                type="radio" 
                                name="subscriptionTier" 
                                id="tier1" 
                                value="tier1" 
                                checked={formData.subscriptionTier === 'tier1'}
                                onChange={handleRadioChange}
                                required 
                              />
                              <label className="form-check-label" htmlFor="tier1">
                                <strong>Tier 1 – Self-Paced Learning</strong>
                                <br />
                                <span className="text-muted">$49/month - Access to pre-recorded content and resources</span>
                              </label>
                            </div>
                            */}
                            
                            {/* Tier 2 - Temporarily unavailable
                            <div className="form-check mb-3">
                              <input 
                                className="form-check-input" 
                                type="radio" 
                                name="subscriptionTier" 
                                id="tier2" 
                                value="tier2" 
                                checked={formData.subscriptionTier === 'tier2'}
                                onChange={handleRadioChange}
                              />
                              <label className="form-check-label" htmlFor="tier2">
                                <strong>Tier 2 – Interactive Learning</strong>
                                <br />
                                <span className="text-muted">$149/month - Live Q&A sessions and community support</span>
                              </label>
                            </div>
                            */}
                            
                            <div className="form-check">
                              <input 
                                className="form-check-input" 
                                type="radio" 
                                name="subscriptionTier" 
                                id="tier3" 
                                value="tier3" 
                                checked={formData.subscriptionTier === 'tier3'}
                                onChange={handleRadioChange}
                                required
                              />
                              <label className="form-check-label" htmlFor="tier3">
                                <strong>Tier 3 – Premium Access</strong>
                                <br />
                                <span className="text-muted">$2.00/month (TEST MODE) - 1:1 sessions and mentoring discount</span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="invalid-feedback">
                          Please select a subscription tier.
                        </div>
                      </div>

                      {/* Payment Method */}
                      <div className="mb-4">
                        <label className="form-label">Preferred Payment Method *</label>
                        <div className="card border-0 bg-light">
                          <div className="card-body">
                            <div className="form-check">
                              <input 
                                className="form-check-input" 
                                type="radio" 
                                name="paymentMethod" 
                                id="stripe" 
                                value="stripe" 
                                checked={formData.paymentMethod === 'stripe'}
                                onChange={handleRadioChange}
                                required
                              />
                              <label className="form-check-label" htmlFor="stripe">
                                <i className="bi bi-credit-card me-2"></i>
                                Credit/Debit Card (via Stripe)
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="invalid-feedback">
                          Please select a payment method.
                        </div>
                      </div>

                      {/* Mentoring vs Consulting Explanation */}
                      <div className="alert alert-info mb-4">
                        <h5 className="alert-heading"><i className="bi bi-info-circle me-2"></i>What is Mentoring?</h5>
                        <p>Our mentoring service is designed to help you think through, plan, and execute technical solutions <strong>yourself</strong>. We provide guidance, expertise, and support throughout your learning journey.</p>
                        <hr />
                        <p className="mb-0">If you need us to implement solutions <strong>for you</strong>, please visit our <Link to="/consulting" className="alert-link">Consulting Services</Link> page instead.</p>
                      </div>

                      {/* Terms Checkbox */}
                      <div className="mb-4">
                        <div className="form-check">
                          <input 
                            className="form-check-input" 
                            type="checkbox" 
                            id="termsCheck" 
                            checked={formData.termsCheck}
                            onChange={handleInputChange}
                            required 
                          />
                          <label className="form-check-label" htmlFor="termsCheck">
                            I understand that this is a recurring monthly subscription *
                          </label>
                          <div className="invalid-feedback">
                            You must agree to the terms before proceeding.
                          </div>
                        </div>
                      </div>

                      {/* Submit Buttons */}
                      <div className="row g-2 mb-3">
                        <div className="col-md-6">
                          <button className="btn btn-primary btn-lg w-100" type="submit">
                            Continue to Payment
                            <i className="bi bi-arrow-right ms-2"></i>
                          </button>
                        </div>
                        <div className="col-md-6">
                          <button 
                            className="btn btn-outline-secondary btn-lg w-100" 
                            type="button"
                            onClick={(e) => {
                              // console.log('Invoice button clicked');
                              handleInvoiceRequest(e);
                            }}
                            disabled={!isFormValid()}
                          >
                            <i className="bi bi-file-earmark-text me-2"></i>
                            Download Invoice for My Manager
                          </button>
                        </div>
                      </div>
                      <div className="form-text text-center">
                        Need an invoice for approval? Click "Download Invoice" to generate a PDF for your manager.
                        {!isFormValid() && <span className="text-danger"> Please fill out all required fields first.</span>}
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Invoice Request Modal */}
      <InvoiceRequestModal
        show={showInvoiceModal}
        onHide={() => setShowInvoiceModal(false)}
        productDetails={{
          type: 'Subscription',
          name: getSubscriptionName(),
          price: getSubscriptionAmount()
        }}
        userData={{
          name: formData.fullName,
          email: formData.email,
          company: formData.company
        }}
        onSuccess={handleInvoiceSuccess}
        onGeneratePdf={generateInvoice}
      />
    </>
  );
};

export default Signup; 