import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card, Alert, Button, Spinner } from 'react-bootstrap';
import { getInvoiceData, approveInvoice } from '../utils/firebaseApi';

const ApproveInvoice = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [invoice, setInvoice] = useState(null);
  const [approvalSuccess, setApprovalSuccess] = useState(false);
  const [approvalError, setApprovalError] = useState(null);
  const [approvalLoading, setApprovalLoading] = useState(false);
  
  const location = useLocation();
  const navigate = useNavigate();
  
  // Get invoice number and email from URL query parameters
  const queryParams = new URLSearchParams(location.search);
  const invoiceNumber = queryParams.get('invoice');
  const email = queryParams.get('email');
  
  useEffect(() => {
    // If no invoice number is provided, show error
    if (!invoiceNumber) {
      setError('No invoice number provided. Please check your link and try again.');
      setLoading(false);
      return;
    }
    
    // Fetch invoice data from Firestore
    const fetchInvoiceData = async () => {
      console.log('Fetching invoice data for invoice number:', invoiceNumber);
      
      try {
        // Get invoice data from Firestore
        const invoiceData = await getInvoiceData(invoiceNumber);
        console.log('Successfully fetched invoice data:', invoiceData);
        setInvoice(invoiceData);
      } catch (err) {
        console.error('Error fetching invoice:', err);
        setError('Unable to load invoice details. Please try again later or contact support.');
      } finally {
        setLoading(false);
      }
    };
    
    fetchInvoiceData();
  }, [invoiceNumber]);
  
  const handleApprove = async () => {
    setApprovalLoading(true);
    setApprovalError(null);
    
    console.log('Approving invoice:', invoiceNumber);
    
    try {
      // Approve the invoice in Firestore
      const result = await approveInvoice(invoiceNumber, email);
      console.log('Approval result:', result);
      
      // Set success state
      setApprovalSuccess(true);
    } catch (err) {
      console.error('Error approving invoice:', err);
      setApprovalError('Failed to approve invoice. Please try again or contact support.');
    } finally {
      setApprovalLoading(false);
    }
  };
  
  if (loading) {
    return (
      <Container className="py-5 text-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
        <p className="mt-3">Loading invoice details...</p>
      </Container>
    );
  }
  
  if (error) {
    return (
      <Container className="py-5">
        <Alert variant="danger">
          <Alert.Heading>Error</Alert.Heading>
          <p>{error}</p>
          <hr />
          <p className="mb-0">
            If you continue to experience issues, please contact our support team.
          </p>
        </Alert>
      </Container>
    );
  }
  
  if (approvalSuccess) {
    return (
      <Container className="py-5">
        <Card className="border-0 shadow">
          <Card.Body className="p-5">
            <div className="text-center mb-4">
              <h2 className="fw-bold text-success">Invoice Approved!</h2>
              <p className="lead">Thank you for approving this invoice.</p>
            </div>
            
            <Alert variant="success">
              <p>The invoice has been approved and the requester has been notified.</p>
              <p className="mb-0">They can now proceed with the payment process.</p>
            </Alert>
            
            <div className="text-center mt-4">
              <Button 
                variant="primary" 
                onClick={() => navigate('/')}
              >
                Return to Home
              </Button>
            </div>
          </Card.Body>
        </Card>
      </Container>
    );
  }
  
  // Format price for display
  const formattedPrice = invoice && invoice.price 
    ? `$${(parseInt(invoice.price) / 100).toFixed(2)}` 
    : 'N/A';
  
  return (
    <Container className="py-5">
      <Card className="border-0 shadow">
        <Card.Body className="p-5">
          <div className="text-center mb-4">
            <h2 className="fw-bold">Invoice Approval Request</h2>
            <p className="lead">Invoice #{invoiceNumber}</p>
          </div>
          
          {approvalError && (
            <Alert variant="danger" className="mb-4">
              {approvalError}
            </Alert>
          )}
          
          <Row>
            <Col lg={8} className="mx-auto">
              <Card className="bg-light mb-4">
                <Card.Body>
                  <h4 className="mb-3">Invoice Details</h4>
                  <p><strong>Requester:</strong> {invoice.name}</p>
                  <p><strong>Email:</strong> {invoice.email}</p>
                  {invoice.company && <p><strong>Company:</strong> {invoice.company}</p>}
                  <p><strong>Product:</strong> {invoice.productType}: {invoice.productName}</p>
                  <p><strong>Amount:</strong> {formattedPrice}</p>
                  <p><strong>Date Requested:</strong> {invoice.timestamp ? new Date(invoice.timestamp.toDate()).toLocaleDateString() : 'N/A'}</p>
                  
                  {invoice.notes && (
                    <>
                      <h5 className="mt-4">Additional Notes</h5>
                      <p>{invoice.notes}</p>
                    </>
                  )}
                </Card.Body>
              </Card>
              
              <div className="d-grid gap-2">
                <Button 
                  variant="success" 
                  size="lg" 
                  onClick={handleApprove}
                  disabled={approvalLoading}
                >
                  {approvalLoading ? (
                    <>
                      <Spinner animation="border" size="sm" className="me-2" />
                      Processing...
                    </>
                  ) : (
                    'Approve Invoice'
                  )}
                </Button>
                
                <Button 
                  variant="outline-secondary" 
                  onClick={() => navigate('/')}
                >
                  Cancel
                </Button>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ApproveInvoice; 