import React from 'react';

const CourseDetails = ({ duration, maxParticipants, format, certificationPrep, enrollLink }) => {
  return (
    <div className="card h-100 shadow border-0">
      <div className="card-body p-4">
        <h3 className="fw-bolder mb-3">Course Details</h3>
        <ul className="list-unstyled mb-4">
          <li className="mb-2"><i className="bi bi-clock-fill text-primary me-2"></i>Duration: {duration}</li>
          <li className="mb-2"><i className="bi bi-people-fill text-primary me-2"></i>Max Participants: {maxParticipants}</li>
          <li className="mb-2"><i className="bi bi-laptop-fill text-primary me-2"></i>Format: {format}</li>
          {certificationPrep && (
            <li className="mb-2"><i className="bi bi-award-fill text-primary me-2"></i>{certificationPrep}</li>
          )}
        </ul>
        <a className="btn btn-primary btn-lg w-100" href={enrollLink}>Enroll Now</a>
      </div>
    </div>
  );
};

// Default props
CourseDetails.defaultProps = {
  duration: '40 hours',
  maxParticipants: 12,
  format: 'Hands-on Labs',
  certificationPrep: 'Certification Prep Included',
  enrollLink: '#!'
};

export default CourseDetails; 