// Stripe configuration
const stripeConfig = {
  // Test mode flag
  testMode: false,
  
  // Test publishable key - using Stripe's official test key format
  testPublishableKey: 'pk_test_TYooMQauvdEDq54NiTphI7jx',
  
  // Live publishable key (keep for future use)
  livePublishableKey: 'pk_live_51R2Fz6IImHS8HQehgEl9mbO6gyDYRzbTH1UoCS1t56jfGnnnzzOdNm8F7zpprlTF1d8D6egsew5L5AUJ5xkryKXT00EYt4S35J',
  
  // Use the appropriate key based on mode
  get publishableKey() {
    return this.testMode ? this.testPublishableKey : this.livePublishableKey;
  },
  
  // Product and price IDs from your Stripe dashboard
  products: {
    tier1: {
      priceId: 'prod_RwB60g4XtrOu8i',
      amount: 4900, // $49.00 in cents
      name: 'Tier 1 – Self-Paced Learning'
    },
    tier2: {
      priceId: 'prod_RwB8IjEQ2R2fbN',
      amount: 14900, // $149.00 in cents
      name: 'Tier 2 – Interactive Learning'
    },
    tier3: {
      priceId: 'prod_RwBAj1gJn1tk1g',
      amount: 100, // $349.00 in cents
      name: 'Tier 3 – Premium Access'
    },
    blockMentoring: {
      priceId: 'prod_RwBCTaVtNXGYYA',
      amount: 800000, // $8,000.00 in cents
      name: 'Dedicated Block Mentoring'
    }
  },
  
  // Static variable to store Stripe instance without using window
  _stripeInstance: null,
  _loadingPromise: null,
  
  // Helper function to initialize Stripe only when needed
  getStripe: async function() {
    try {
      // If we already have an instance, return it
      if (this._stripeInstance) {
        return this._stripeInstance;
      }
      
      // If we're already loading Stripe, return the existing promise
      // This prevents multiple simultaneous loading attempts
      if (this._loadingPromise) {
        return this._loadingPromise;
      }
      
      // Create a new loading promise
      this._loadingPromise = (async () => {
        try {
          const { loadStripe } = await import('@stripe/stripe-js');
          
          // Configure Stripe with options to handle ad blockers
          // Explicitly set empty options to avoid __shared_params__ warning
          const stripeOptions = {
            betas: [],
            advancedFraudSignals: false
          };
          
          this._stripeInstance = await loadStripe(this.publishableKey, stripeOptions);
          
          // Log whether we're in test mode
          console.log(`Stripe initialized in ${this.testMode ? 'TEST' : 'LIVE'} mode`);
          
          return this._stripeInstance;
        } catch (error) {
          // Clear the loading promise so future attempts can try again
          this._loadingPromise = null;
          console.error('Error initializing Stripe:', error);
          throw error;
        }
      })();
      
      return this._loadingPromise;
    } catch (error) {
      // Clear both references in case of error
      this._loadingPromise = null;
      this._stripeInstance = null;
      console.error('Error in getStripe:', error);
      throw error;
    }
  },
  
  // Test method to check if Stripe is available without loading it
  isStripeAvailable: async function() {
    try {
      // Try to access the Stripe object without initializing it
      await import('@stripe/stripe-js');
      return true;
    } catch (error) {
      console.error('Stripe is not available:', error);
      return false;
    }
  },
  
  // Get test card information for users
  getTestCardInfo: function() {
    if (!this.testMode) return null;
    
    return {
      success: {
        number: '4242 4242 4242 4242',
        exp: 'Any future date',
        cvc: 'Any 3 digits',
        zip: 'Any 5 digits'
      },
      decline: {
        number: '4000 0000 0000 0002',
        exp: 'Any future date',
        cvc: 'Any 3 digits',
        zip: 'Any 5 digits'
      }
    };
  }
};

export default stripeConfig; 