import React, { useState, useEffect, lazy, Suspense } from 'react';
import PaymentConfirmation from '../components/PaymentConfirmation';
import stripeConfig from '../config/stripe';
import { Link, useNavigate } from 'react-router-dom';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { submitMentorBlockPurchase } from '../utils/api';
import InvoiceRequestModal from '../components/InvoiceRequestModal';

// Lazy load the StripePayment component
const StripePayment = lazy(() => import('../components/StripePayment'));

const BlockMentoringSignup = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    discord: '',
    company: '',
    phoneNumber: '',
    preferredTechnology: '',
    goals: '',
    termsCheck: false
  });

  const [validated, setValidated] = useState(false);
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState(null);
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);

  // Check if all required fields are filled
  const isFormValid = () => {
    return (
      formData.fullName.trim() !== '' &&
      formData.email.trim() !== '' &&
      formData.discord.trim() !== '' &&
      formData.phoneNumber.trim() !== '' &&
      formData.preferredTechnology.trim() !== '' &&
      formData.goals.trim() !== ''
    );
  };

  const handleInputChange = (e) => {
    const { id, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [id]: type === 'checkbox' ? checked : value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    } else {
      setValidated(true);
      
      // Submit mentor block data to Google Sheets with "Form Submitted" status
      const blockName = getProgramName();
      const blockAmount = getAmount() / 100;
      
      submitMentorBlockPurchase({
        email: formData.email,
        name: formData.fullName,
        blockType: blockName,
        hours: '40', // This is a 40-hour block
        price: blockAmount.toFixed(2),
        paymentStatus: 'Form Submitted',
        discord: formData.discord,
        company: formData.company || 'Not provided',
        phoneNumber: formData.phoneNumber,
        preferredTechnology: formData.preferredTechnology,
        goals: formData.goals
      })
      .then(response => {
        console.log('Initial mentor block data submitted to Google Sheets:', response);
      })
      .catch(error => {
        console.error('Error submitting initial mentor block data:', error);
      });
      
      setShowPaymentForm(true);
    }
  };

  const getAmount = () => {
    return stripeConfig.products.blockMentoring.amount;
  };

  const getProgramName = () => {
    return stripeConfig.products.blockMentoring.name;
  };

  const handlePaymentSuccess = (paymentMethod) => {
    console.log('Payment successful:', paymentMethod);
    setPaymentInfo({
      brand: paymentMethod.card.brand,
      last4: paymentMethod.card.last4,
      expMonth: paymentMethod.card.exp_month,
      expYear: paymentMethod.card.exp_year
    });
    
    // Get block mentoring details
    const blockName = getProgramName();
    const blockAmount = getAmount() / 100;
    
    // Update mentor block data in Google Sheets with "Payment Completed" status
    submitMentorBlockPurchase({
      email: formData.email,
      name: formData.fullName,
      blockType: blockName,
      hours: '40', // This is a 40-hour block
      price: blockAmount.toFixed(2),
      paymentStatus: 'Payment Completed',
      discord: formData.discord,
      company: formData.company || 'Not provided',
      phoneNumber: formData.phoneNumber,
      preferredTechnology: formData.preferredTechnology,
      goals: formData.goals
    })
    .then(response => {
      console.log('Payment completion data submitted to Google Sheets:', response);
    })
    .catch(error => {
      console.error('Error submitting payment completion data:', error);
      // Continue with the flow even if Google Sheets submission fails
    });
    
    setPaymentSuccess(true);
  };

  const handlePaymentError = (error) => {
    console.error('Payment error:', error);
    
    // Get block mentoring details
    const blockName = getProgramName();
    const blockAmount = getAmount() / 100;
    
    // Update mentor block data in Google Sheets with "Payment Failed" status
    submitMentorBlockPurchase({
      email: formData.email,
      name: formData.fullName,
      blockType: blockName,
      hours: '40', // This is a 40-hour block
      price: blockAmount.toFixed(2),
      paymentStatus: 'Payment Failed',
      discord: formData.discord,
      company: formData.company || 'Not provided',
      phoneNumber: formData.phoneNumber,
      preferredTechnology: formData.preferredTechnology,
      goals: formData.goals,
      paymentError: error.message || 'Unknown payment error'
    })
    .then(response => {
      console.log('Payment failure data submitted to Google Sheets:', response);
    })
    .catch(err => {
      console.error('Error submitting payment failure data:', err);
    });
    
    // You could set an error state here and display it to the user
  };

  const generateInvoice = () => {
    console.log('Generating PDF invoice...');
    
    // Create a new PDF document
    const doc = new jsPDF();
    const today = new Date();
    const invoiceNumber = `INV-${today.getFullYear()}${(today.getMonth() + 1).toString().padStart(2, '0')}${today.getDate().toString().padStart(2, '0')}-${Math.floor(Math.random() * 10000).toString().padStart(4, '0')}`;
    const dueDate = new Date(today);
    dueDate.setDate(dueDate.getDate() + 30);
    
    // Add Charsoft logo and company info
    doc.setFontSize(20);
    doc.setFont('helvetica', 'bold');
    doc.text('Charsoft Consulting', 20, 20);
    
    doc.setFontSize(10);
    doc.setFont('helvetica', 'normal');
    doc.text('123 Tech Avenue', 20, 30);
    doc.text('Seattle, WA 98101', 20, 35);
    doc.text('Phone: (555) 123-4567', 20, 40);
    doc.text('Email: billing@charsoft.com', 20, 45);
    doc.text('Web: www.charsoft.com', 20, 50);
    
    // Add invoice title and details
    doc.setFontSize(16);
    doc.setFont('helvetica', 'bold');
    doc.text('INVOICE', 150, 20);
    
    doc.setFontSize(10);
    doc.setFont('helvetica', 'normal');
    doc.text(`Invoice Number: ${invoiceNumber}`, 150, 30);
    doc.text(`Date: ${today.toLocaleDateString()}`, 150, 35);
    doc.text(`Due Date: ${dueDate.toLocaleDateString()}`, 150, 40);
    
    // Add client information
    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    doc.text('Bill To:', 20, 65);
    
    doc.setFontSize(10);
    doc.setFont('helvetica', 'normal');
    doc.text(formData.fullName, 20, 75);
    if (formData.company) {
      doc.text(formData.company, 20, 80);
      doc.text(formData.email, 20, 85);
      doc.text(formData.phoneNumber || '', 20, 90);
    } else {
      doc.text(formData.email, 20, 80);
      doc.text(formData.phoneNumber || '', 20, 85);
    }
    
    // Create detailed description with form fields
    const technologyFocus = formData.preferredTechnology ? 
      document.querySelector(`#preferredTechnology option[value="${formData.preferredTechnology}"]`)?.textContent || formData.preferredTechnology : 
      'Not specified';
    
    const detailedDescription = 
      `40 hours of dedicated technical mentoring (valid for 12 months)\n` +
      `Client: ${formData.fullName}\n` +
      `Email: ${formData.email}\n` +
      `Phone: ${formData.phoneNumber || 'Not provided'}\n` +
      `Discord: ${formData.discord}\n` +
      `Company: ${formData.company || 'Not provided'}\n` +
      `Technology Focus: ${technologyFocus}\n` +
      `Learning Goals: ${formData.goals ? (formData.goals.length > 100 ? formData.goals.substring(0, 100) + '...' : formData.goals) : 'Not provided'}`;
    
    // Add invoice items
    doc.autoTable({
      startY: formData.company ? 100 : 95,
      head: [['Item', 'Description', 'Quantity', 'Unit Price', 'Amount']],
      body: [
        [
          'Block Mentoring',
          detailedDescription,
          '1',
          '$8,000.00',
          '$8,000.00'
        ]
      ],
      foot: [
        ['', '', '', 'Subtotal', '$8,000.00'],
        ['', '', '', 'Tax (0%)', '$0.00'],
        ['', '', '', 'Total', '$8,000.00']
      ],
      theme: 'grid',
      headStyles: { fillColor: [41, 128, 185], textColor: 255 },
      footStyles: { fillColor: [240, 240, 240], textColor: 0, fontStyle: 'bold' },
      columnStyles: {
        1: { cellWidth: 'auto' }
      },
      styles: {
        overflow: 'linebreak',
        cellPadding: 3
      }
    });
    
    // Add payment instructions and terms
    const finalY = doc.lastAutoTable.finalY + 10;
    
    doc.setFontSize(11);
    doc.setFont('helvetica', 'bold');
    doc.text('Payment Instructions:', 20, finalY);
    
    doc.setFontSize(10);
    doc.setFont('helvetica', 'normal');
    doc.text('Please make payment via credit card, bank transfer, or check payable to Charsoft Consulting.', 20, finalY + 10);
    doc.text('For bank transfers, please contact billing@charsoft.com for account details.', 20, finalY + 15);
    
    doc.setFontSize(11);
    doc.setFont('helvetica', 'bold');
    doc.text('Terms & Conditions:', 20, finalY + 25);
    
    doc.setFontSize(10);
    doc.setFont('helvetica', 'normal');
    doc.text('1. Payment is due within 30 days of invoice date.', 20, finalY + 35);
    doc.text('2. Block mentoring hours must be used within 12 months of purchase.', 20, finalY + 40);
    doc.text('3. Unused hours are non-refundable after the 12-month period.', 20, finalY + 45);
    
    doc.setFontSize(10);
    doc.setFont('helvetica', 'italic');
    doc.text('Thank you for choosing Charsoft Consulting for your technical mentoring needs!', 20, finalY + 55);
    
    // Track invoice download in Google Sheets
    const blockName = getProgramName();
    const blockAmount = getAmount() / 100;
    
    submitMentorBlockPurchase({
      email: formData.email,
      name: formData.fullName,
      blockType: blockName,
      hours: '40', // This is a 40-hour block
      price: blockAmount.toFixed(2),
      paymentStatus: 'Invoice Downloaded',
      discord: formData.discord,
      company: formData.company || 'Not provided',
      phoneNumber: formData.phoneNumber,
      preferredTechnology: formData.preferredTechnology,
      goals: formData.goals,
      invoiceNumber: invoiceNumber
    })
    .then(response => {
      console.log('Invoice download data submitted to Google Sheets:', response);
    })
    .catch(error => {
      console.error('Error submitting invoice download data:', error);
    });
    
    // Save the PDF
    doc.save(`Charsoft_BlockMentoring_Invoice_${invoiceNumber}.pdf`);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  // Handle invoice request button click
  const handleInvoiceRequest = (e) => {
    e.preventDefault(); // Prevent default button behavior
    console.log('Invoice request button clicked');
    setShowInvoiceModal(true);
  };

  // Handle invoice request success
  const handleInvoiceSuccess = (result) => {
    console.log('Invoice request successful:', result);
    
    // Get block mentoring details
    const blockName = getProgramName();
    const blockAmount = getAmount() / 100;
    
    // Update mentor block data in Google Sheets with "Invoice Requested" status
    submitMentorBlockPurchase({
      email: formData.email,
      name: formData.fullName,
      blockType: blockName,
      hours: '40', // This is a 40-hour block
      price: blockAmount.toFixed(2),
      paymentStatus: 'Invoice Requested',
      discord: formData.discord,
      company: formData.company || 'Not provided',
      phoneNumber: formData.phoneNumber,
      preferredTechnology: formData.preferredTechnology,
      goals: formData.goals
    })
    .then(response => {
      console.log('Invoice request data submitted to Google Sheets:', response);
    })
    .catch(error => {
      console.error('Error submitting invoice request data:', error);
    });
  };

  if (paymentSuccess) {
    return (
      <div className="container px-5 py-5">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <PaymentConfirmation 
              paymentInfo={paymentInfo}
              subscriptionDetails={{
                name: getProgramName(),
                amount: (getAmount() / 100).toFixed(2),
                isOneTime: true
              }}
              userDetails={{
                email: formData.email,
                name: formData.fullName
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {/* Header */}
      <header className="py-5">
        <div className="container px-5">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-xxl-6">
              <div className="text-center my-5">
                <h1 className="fw-bolder mb-3">Dedicated Block Mentoring</h1>
                <p className="lead fw-normal text-muted mb-4">
                  Get intensive, personalized technical mentoring with a dedicated block of 40 hours.
                </p>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* Signup Form Section */}
      <section className="py-5">
        <div className="container px-5">
          <div className="row gx-5 justify-content-center">
            <div className="col-lg-8">
              <div className="card border-0 rounded-3 shadow-lg">
                <div className="card-body p-4">
                  <button 
                    className="btn btn-sm btn-outline-secondary position-absolute top-0 end-0 mt-3 me-3" 
                    onClick={handleGoBack}
                    type="button"
                    aria-label="Close"
                  >
                    <i className="bi bi-x-lg"></i>
                  </button>
                  <div className="text-center mb-4">
                    <h4 className="fw-bolder">{showPaymentForm ? 'Complete Your Payment' : 'Complete Your Registration'}</h4>
                    <p className="lead fw-normal text-muted mb-0">
                      {showPaymentForm 
                        ? `You're signing up for ${getProgramName()} at $${(getAmount() / 100).toFixed(2)}`
                        : 'All fields marked with * are required'
                      }
                    </p>
                  </div>

                  {showPaymentForm ? (
                    <Suspense fallback={<div>Loading payment form...</div>}>
                      <StripePayment 
                        amount={getAmount()} 
                        onSuccess={handlePaymentSuccess}
                        onError={handlePaymentError}
                      />
                    </Suspense>
                  ) : (
                    <form 
                      className={`needs-validation ${validated ? 'was-validated' : ''}`} 
                      noValidate
                      onSubmit={handleSubmit}
                    >
                      {/* Full Name */}
                      <div className="mb-3">
                        <label htmlFor="fullName" className="form-label">Full Name *</label>
                        <input 
                          type="text" 
                          className="form-control" 
                          id="fullName" 
                          value={formData.fullName}
                          onChange={handleInputChange}
                          required 
                        />
                        <div className="invalid-feedback">
                          Please provide your full name.
                        </div>
                      </div>

                      {/* Email */}
                      <div className="mb-3">
                        <label htmlFor="email" className="form-label">Email Address *</label>
                        <input 
                          type="email" 
                          className="form-control" 
                          id="email" 
                          value={formData.email}
                          onChange={handleInputChange}
                          required 
                        />
                        <div className="invalid-feedback">
                          Please provide a valid email address.
                        </div>
                      </div>

                      {/* Phone Number */}
                      <div className="mb-3">
                        <label htmlFor="phoneNumber" className="form-label">Phone Number *</label>
                        <input 
                          type="tel" 
                          className="form-control" 
                          id="phoneNumber" 
                          value={formData.phoneNumber}
                          onChange={handleInputChange}
                          required 
                        />
                        <div className="invalid-feedback">
                          Please provide a valid phone number.
                        </div>
                      </div>

                      {/* Discord Username */}
                      <div className="mb-3">
                        <label htmlFor="discord" className="form-label">Discord Username *</label>
                        <input 
                          type="text" 
                          className="form-control" 
                          id="discord" 
                          value={formData.discord}
                          onChange={handleInputChange}
                          required 
                        />
                        <div className="form-text">
                          Include your full Discord username (e.g., username#1234). 
                          Don't have Discord? <a href="https://discord.com/register" target="_blank" rel="noopener noreferrer">Sign up here</a>.
                        </div>
                        <div className="invalid-feedback">
                          Please provide your Discord username.
                        </div>
                      </div>

                      {/* Company Name */}
                      <div className="mb-3">
                        <label htmlFor="company" className="form-label">Company Name (Optional)</label>
                        <input 
                          type="text" 
                          className="form-control" 
                          id="company" 
                          value={formData.company}
                          onChange={handleInputChange}
                        />
                      </div>

                      {/* Preferred Technology */}
                      <div className="mb-3">
                        <label htmlFor="preferredTechnology" className="form-label">Preferred Technology Focus *</label>
                        <select
                          className="form-select"
                          id="preferredTechnology"
                          value={formData.preferredTechnology}
                          onChange={handleInputChange}
                          required
                        >
                          <option value="">Select a technology focus</option>
                          <option value="cloud">Cloud Computing (AWS/Azure/GCP)</option>
                          <option value="ai">Artificial Intelligence & Machine Learning</option>
                          <option value="microsoft">Microsoft Technologies</option>
                          <option value="web">Web Development</option>
                          <option value="mobile">Mobile Development</option>
                          <option value="devops">DevOps & CI/CD</option>
                          <option value="security">Cybersecurity</option>
                          <option value="data">Data Science & Analytics</option>
                          <option value="other">Other (Please specify in goals)</option>
                        </select>
                        <div className="invalid-feedback">
                          Please select a technology focus.
                        </div>
                      </div>

                      {/* Goals */}
                      <div className="mb-4">
                        <label htmlFor="goals" className="form-label">Your Learning Goals *</label>
                        <textarea
                          className="form-control"
                          id="goals"
                          rows="4"
                          value={formData.goals}
                          onChange={handleInputChange}
                          required
                          placeholder="Please describe what you hope to achieve with this mentoring program..."
                        ></textarea>
                        <div className="invalid-feedback">
                          Please describe your learning goals.
                        </div>
                      </div>

                      {/* Block Mentoring Package */}
                      <div className="mb-4">
                        <label className="form-label">Block Mentoring Package</label>
                        <div className="card border-0 bg-light">
                          <div className="card-body">
                            <div className="d-flex justify-content-between align-items-center mb-3">
                              <div>
                                <h5 className="mb-1">Dedicated Block Mentoring</h5>
                                <p className="text-muted mb-0">40 hours of one-on-one dedicated coaching</p>
                              </div>
                              <span className="badge bg-primary rounded-pill fs-5">$8,000</span>
                            </div>
                            <ul className="list-unstyled mb-0">
                              <li className="mb-2"><i className="bi bi-check-circle-fill text-primary me-2"></i>Fully customized learning path</li>
                              <li className="mb-2"><i className="bi bi-check-circle-fill text-primary me-2"></i>Valid for one year</li>
                              <li className="mb-2"><i className="bi bi-check-circle-fill text-primary me-2"></i>Option to extend with additional hours</li>
                              <li className="mb-2"><i className="bi bi-check-circle-fill text-primary me-2"></i>Free access to Tier 2 while active hours remain</li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      {/* Mentoring vs Consulting Explanation */}
                      <div className="alert alert-info mb-4">
                        <h5 className="alert-heading"><i className="bi bi-info-circle me-2"></i>What is Mentoring?</h5>
                        <p>Our mentoring service is designed to help you think through, plan, and execute technical solutions <strong>yourself</strong>. We provide guidance, expertise, and support throughout your learning journey.</p>
                        <hr />
                        <p className="mb-0">If you need us to implement solutions <strong>for you</strong>, please visit our <Link to="/consulting" className="alert-link">Consulting Services</Link> page instead.</p>
                      </div>

                      {/* Terms Checkbox */}
                      <div className="mb-4">
                        <div className="form-check">
                          <input 
                            className="form-check-input" 
                            type="checkbox" 
                            id="termsCheck" 
                            checked={formData.termsCheck}
                            onChange={handleInputChange}
                            required 
                          />
                          <label className="form-check-label" htmlFor="termsCheck">
                            I understand that this is a one-time payment for 40 hours of mentoring *
                          </label>
                          <div className="invalid-feedback">
                            You must agree to the terms before proceeding.
                          </div>
                        </div>
                      </div>

                      {/* Submit Buttons */}
                      <div className="row g-2 mb-3">
                        <div className="col-md-6">
                          <button className="btn btn-primary btn-lg w-100" type="submit">
                            Continue to Payment
                            <i className="bi bi-arrow-right ms-2"></i>
                          </button>
                        </div>
                        <div className="col-md-6">
                          <button 
                            className="btn btn-outline-secondary btn-lg w-100" 
                            type="button"
                            onClick={(e) => {
                              console.log('Invoice button clicked');
                              handleInvoiceRequest(e);
                            }}
                            disabled={!isFormValid()}
                          >
                            <i className="bi bi-file-earmark-text me-2"></i>
                            Download Invoice for My Manager
                          </button>
                        </div>
                      </div>
                      <div className="form-text text-center">
                        Need an invoice for approval? Click "Download Invoice" to generate a PDF for your manager.
                        {!isFormValid() && <span className="text-danger"> Please fill out all required fields first.</span>}
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Invoice Request Modal */}
      <InvoiceRequestModal
        show={showInvoiceModal}
        onHide={() => setShowInvoiceModal(false)}
        productDetails={{
          type: 'Mentoring Block',
          name: getProgramName(),
          price: getAmount()
        }}
        userData={{
          name: formData.fullName,
          email: formData.email,
          company: formData.company
        }}
        onSuccess={handleInvoiceSuccess}
        onGeneratePdf={generateInvoice}
      />
    </>
  );
};

export default BlockMentoringSignup; 