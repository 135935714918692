import React, { useState, useEffect } from 'react';
import { Container, Card, Button, Alert, Spinner, ListGroup } from 'react-bootstrap';
import { createSampleData } from '../utils/sampleData';
import { db } from '../utils/firebase';
import { collection } from 'firebase/firestore';

const AdminFirebase = () => {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const [firebaseStatus, setFirebaseStatus] = useState('checking');
  
  // Check Firebase connection on component mount
  useEffect(() => {
    const checkFirebaseConnection = async () => {
      try {
        // Try to access Firestore to check connection
        if (!db) {
          console.error('Firestore not initialized');
          setFirebaseStatus('error');
          setError('Firestore not initialized. Check your Firebase configuration.');
          return;
        }
        
        // Check if we can access the Firestore instance
        console.log('Firestore instance:', db);
        console.log('Firebase app options:', db?.app?.options);
        
        // Just check if we can access the collection function
        if (typeof collection === 'function') {
          console.log('Firestore connection appears to be initialized');
          setFirebaseStatus('initialized');
        } else {
          console.error('Firestore collection function not available');
          setFirebaseStatus('error');
          setError('Firestore collection function not available. Check your Firebase configuration.');
        }
      } catch (error) {
        console.error('Error checking Firebase connection:', error);
        setFirebaseStatus('error');
        setError(`Firebase connection error: ${error.message}`);
      }
    };
    
    checkFirebaseConnection();
  }, []);
  
  const handleCreateSampleData = async () => {
    setLoading(true);
    setError(null);
    setResult(null);
    
    try {
      console.log('Starting sample data creation...');
      const result = await createSampleData();
      console.log('Sample data creation result:', result);
      setResult(result);
      
      if (result.status === 'partial') {
        setError('Some sample data could not be created. See details below.');
      }
    } catch (error) {
      console.error('Error creating sample data:', error);
      setError(`Failed to create sample data: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <Container className="py-5">
      <Card className="border-0 shadow">
        <Card.Body className="p-5">
          <div className="text-center mb-4">
            <h2 className="fw-bold">Firebase Admin</h2>
            <p className="lead">Manage your Firebase data</p>
          </div>
          
          {/* Firebase Connection Status */}
          <Alert 
            variant={
              firebaseStatus === 'connected' ? 'success' : 
              firebaseStatus === 'initialized' ? 'success' : 
              firebaseStatus === 'checking' ? 'info' : 'danger'
            } 
            className="mb-4"
          >
            <Alert.Heading>Firebase Status</Alert.Heading>
            <p>
              {firebaseStatus === 'connected' && 'Connected to Firebase successfully.'}
              {firebaseStatus === 'initialized' && 'Firebase initialized successfully.'}
              {firebaseStatus === 'checking' && 'Checking Firebase connection...'}
              {firebaseStatus === 'error' && 'Error connecting to Firebase. Check console for details.'}
            </p>
            <p className="mb-0">
              <strong>Project ID:</strong> {db?.app?.options?.projectId || 'Unknown'}
            </p>
          </Alert>
          
          {error && (
            <Alert variant="danger" className="mb-4">
              <Alert.Heading>Error</Alert.Heading>
              <p>{error}</p>
            </Alert>
          )}
          
          {result && (
            <Alert variant={result.status === 'success' ? 'success' : 'warning'} className="mb-4">
              <Alert.Heading>{result.status === 'success' ? 'Success!' : 'Partial Success'}</Alert.Heading>
              <p>{result.message}</p>
              
              {result.details && (
                <>
                  {result.details.success.length > 0 && (
                    <div className="mt-3">
                      <h6>Successfully Created:</h6>
                      <ListGroup variant="flush" className="mb-3">
                        {result.details.success.map((item, index) => (
                          <ListGroup.Item key={index} variant="success">
                            {item}
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    </div>
                  )}
                  
                  {result.details.errors.length > 0 && (
                    <div className="mt-3">
                      <h6>Errors:</h6>
                      <ListGroup variant="flush">
                        {result.details.errors.map((item, index) => (
                          <ListGroup.Item key={index} variant="danger">
                            {item}
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    </div>
                  )}
                </>
              )}
            </Alert>
          )}
          
          <Card className="mb-4">
            <Card.Header>Sample Data</Card.Header>
            <Card.Body>
              <p>
                Create sample data in your Firestore database for testing purposes.
                This will add sample records for:
              </p>
              <ul>
                <li>Leadership Quiz Results</li>
                <li>Tier Subscriptions</li>
                <li>Mentor Blocks</li>
                <li>Invoice Requests (Pending, Approved, and Paid)</li>
              </ul>
              <div className="d-grid">
                <Button 
                  variant="primary" 
                  onClick={handleCreateSampleData}
                  disabled={loading || firebaseStatus === 'error' || firebaseStatus === 'checking'}
                >
                  {loading ? (
                    <>
                      <Spinner animation="border" size="sm" className="me-2" />
                      Creating Sample Data...
                    </>
                  ) : (
                    'Create Sample Data'
                  )}
                </Button>
              </div>
            </Card.Body>
          </Card>
          
          <div className="text-center mt-4">
            <p className="text-muted small">
              Note: This page is for development and testing purposes only.
              Do not use in production.
            </p>
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default AdminFirebase; 