import React from 'react';

const About = () => {
  return (
    <>
      {/* Header */}
      <header className="py-5">
        <div className="container px-5">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-xxl-6">
              <div className="text-center my-5">
                <h1 className="fw-bolder mb-3">
                  Our mission is to empower clients with the knowledge and tools they need to achieve their business goals.
                </h1>
                <p className="lead fw-normal text-muted mb-4">
                  Charsoft Consulting, Inc. is a leading consulting firm dedicated to helping clients achieve their business objectives through strategic guidance and practical solutions. We offer a comprehensive range of services, including business consulting, technology consulting, training and workshops, project management, and change management.
                </p>
                <a className="btn btn-primary btn-lg" href="#scroll-target">
                  Read our story
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* About section one */}
      <section className="py-5 bg-light" id="scroll-target">
        <div className="container px-5 my-5">
          <div className="row gx-5 align-items-center">
            <div className="col-lg-6">
              <img 
                className="img-fluid rounded mb-5 mb-lg-0" 
                src="/assets/agreement-black-business-943630.jpg" 
                alt="Business meeting" 
              />
            </div>
            <div className="col-lg-6">
              <h2 className="fw-bolder">Our founding</h2>
              <p className="lead fw-normal text-muted mb-0">
                Charsoft Consulting, Inc. was founded in 1999 with the mission of helping businesses of all sizes achieve their full potential. We believe that every business, regardless of industry or technical expertise, should have access to the resources and support necessary to succeed.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* About section two */}
      <section className="py-5">
        <div className="container px-5 my-5">
          <div className="row gx-5 align-items-center">
            <div className="col-lg-6 order-first order-lg-last">
              <img 
                className="img-fluid rounded mb-5 mb-lg-0" 
                src="/assets/adult-american-board-1157859.jpg" 
                alt="Team planning" 
              />
            </div>
            <div className="col-lg-6">
              <h2 className="fw-bolder">Growth &amp; beyond</h2>
              <p className="lead fw-normal text-muted mb-0">
                Since our founding, Charsoft Consulting, Inc. has grown to become a leading consulting firm in the industry. We have a proven track record of success in helping clients achieve their business goals. We are committed to providing our clients with the highest level of service and support.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Team members section */}
      <section className="py-5 bg-light">
        <div className="container px-5 my-5">
          <div className="text-center">
            <h2 className="fw-bolder">Our team</h2>
            <p className="lead fw-normal text-muted mb-5">
              Our team of experienced professionals is passionate about helping clients achieve their goals. We have a proven track record of success in delivering high-quality results.
            </p>
          </div>
          <div className="row gx-5 row-cols-1 row-cols-sm-2 row-cols-xl-4 justify-content-center">
            <div className="col mb-5 mb-5 mb-xl-0">
              <div className="text-center">
                <img 
                  className="img-fluid rounded-circle mb-4 px-4" 
                  style={{ height: "200px" }} 
                  src="/assets/char face 2024 crp.JPG" 
                  alt="Charlene Banks" 
                />
                <h5 className="fw-bolder">Charlene Banks</h5>
                <div className="fst-italic text-muted">Founder &amp; CEO</div>
              </div>
            </div>
            <div className="col mb-5 mb-5 mb-xl-0">
              <div className="text-center">
                <img 
                  className="img-fluid rounded-circle mb-4 px-4" 
                  style={{ height: "200px" }} 
                  src="/assets/Picture1.jpg" 
                  alt="Blake Cook" 
                />
                <h5 className="fw-bolder">Blake Cook</h5>
                <div className="fst-italic text-muted">CFO/CTO</div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* From the CEO section */}
      <section className="py-5">
        <div className="container px-5 my-5">
          <div className="text-center">
            <h2 className="fw-bolder">From the CEO</h2>
            <p className="lead fw-normal text-muted mb-5">
              Consulting is making a paradigm shift... no longer are clients willing to risk loss of productivity and resources to consulting teams. The current climate dictates that staff members be involved with consultants... working side-by-side during development and deployment of database systems. Charsoft Consulting is dedicated to "growing the expertise of your staff" by providing initial consulting and/or training, and ongoing mentoring to support your company's needs.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default About; 