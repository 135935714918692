import React from 'react';
import { Link } from 'react-router-dom';

const PaymentConfirmation = ({ 
  paymentInfo, 
  subscriptionDetails,
  userDetails 
}) => {
  const isOneTimePayment = subscriptionDetails?.isOneTime || false;

  return (
    <div className="payment-confirmation">
      <div className="text-center mb-4">
        <div className="display-1 text-success mb-3">
          <i className="bi bi-check-circle-fill"></i>
        </div>
        <h2 className="fw-bold mb-3">Payment Successful!</h2>
        <p className="lead text-muted">
          Thank you for joining our {isOneTimePayment ? 'Block Mentoring Program' : 'Technical Mentoring Program'}.
        </p>
      </div>

      <div className="card border-0 shadow-sm mb-4">
        <div className="card-body">
          <h5 className="card-title mb-3">Payment Details</h5>
          <div className="row mb-2">
            <div className="col-sm-4 text-muted">Plan:</div>
            <div className="col-sm-8 fw-bold">{subscriptionDetails?.name || 'Technical Mentoring Subscription'}</div>
          </div>
          <div className="row mb-2">
            <div className="col-sm-4 text-muted">Amount:</div>
            <div className="col-sm-8">
              ${subscriptionDetails?.amount || '0.00'} {isOneTimePayment ? '(one-time payment)' : '/ month'}
            </div>
          </div>
          {isOneTimePayment && (
            <div className="row mb-2">
              <div className="col-sm-4 text-muted">Hours:</div>
              <div className="col-sm-8">40 hours (valid for 12 months)</div>
            </div>
          )}
          <div className="row mb-2">
            <div className="col-sm-4 text-muted">{isOneTimePayment ? 'Purchase Date:' : 'Start Date:'}</div>
            <div className="col-sm-8">{new Date().toLocaleDateString()}</div>
          </div>
          <div className="row">
            <div className="col-sm-4 text-muted">Payment Method:</div>
            <div className="col-sm-8">
              {paymentInfo?.brand ? (
                <>
                  <i className={`bi bi-${paymentInfo.brand.toLowerCase()} me-2`}></i>
                  {paymentInfo.brand} •••• {paymentInfo.last4}
                </>
              ) : (
                'Credit/Debit Card'
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="card border-0 shadow-sm mb-4">
        <div className="card-body">
          <h5 className="card-title mb-3">What's Next?</h5>
          {isOneTimePayment ? (
            <ol className="mb-0">
              <li className="mb-2">
                <strong>Check your email</strong> - We've sent a confirmation to {userDetails?.email || 'your email address'}.
              </li>
              <li className="mb-2">
                <strong>Schedule a kickoff call</strong> - Our team will contact you within 24 hours to schedule your initial planning session.
              </li>
              <li className="mb-2">
                <strong>Prepare your goals</strong> - Review your learning objectives to make the most of your first session.
              </li>
              <li className="mb-2">
                <strong>Join our Discord community</strong> - You'll receive an invitation link via email.
              </li>
              <li>
                <strong>Questions?</strong> - Call us at (555) 123-4567 or email <a href="mailto:mentoring@charsoft.com">mentoring@charsoft.com</a>
              </li>
            </ol>
          ) : (
            <ol className="mb-0">
              <li className="mb-2">
                <strong>Check your email</strong> - We've sent a confirmation to {userDetails?.email || 'your email address'}.
              </li>
              <li className="mb-2">
                <strong>Join our Discord community</strong> - You'll receive an invitation link via email.
              </li>
              <li className="mb-2">
                <strong>Set up your profile</strong> - Complete your profile to get personalized recommendations.
              </li>
              <li>
                <strong>Schedule your first session</strong> - If your plan includes 1:1 sessions, book your first appointment.
              </li>
            </ol>
          )}
        </div>
      </div>

      <div className="d-grid gap-2">
        <Link to="/" className="btn btn-primary btn-lg">
          Go to Dashboard
        </Link>
        <Link to="/mentoring" className="btn btn-outline-secondary">
          Explore Other Programs
        </Link>
      </div>
    </div>
  );
};

export default PaymentConfirmation; 