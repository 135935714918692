import React, { useState, useEffect, lazy, Suspense } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card, Alert, Spinner } from 'react-bootstrap';
import { getInvoiceData, updateInvoicePaymentStatus } from '../utils/firebaseApi';

// Lazy load the StripePayment component
const StripePayment = lazy(() => import('../components/StripePayment'));

const InvoicePayment = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [invoice, setInvoice] = useState(null);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState(null);
  
  const location = useLocation();
  const navigate = useNavigate();
  
  // Get invoice number from URL query parameters
  const queryParams = new URLSearchParams(location.search);
  const invoiceNumber = queryParams.get('invoice');
  
  useEffect(() => {
    // If no invoice number is provided, show error
    if (!invoiceNumber) {
      setError('No invoice number provided. Please check your link and try again.');
      setLoading(false);
      return;
    }
    
    // Fetch invoice data from Firestore
    const fetchInvoiceData = async () => {
      try {
        console.log('Fetching invoice data for invoice number:', invoiceNumber);
        
        // Get invoice data from Firestore
        const invoiceData = await getInvoiceData(invoiceNumber);
        console.log('Successfully fetched invoice data:', invoiceData);
        setInvoice(invoiceData);
      } catch (err) {
        console.error('Error fetching invoice:', err);
        setError('Unable to load invoice details. Please try again later or contact support.');
      } finally {
        setLoading(false);
      }
    };
    
    fetchInvoiceData();
  }, [invoiceNumber]);
  
  const handlePaymentSuccess = (paymentMethod) => {
    console.log('Payment successful:', paymentMethod);
    setPaymentInfo({
      brand: paymentMethod.card.brand,
      last4: paymentMethod.card.last4,
      expMonth: paymentMethod.card.exp_month,
      expYear: paymentMethod.card.exp_year
    });
    
    // Update payment status in Firestore
    updatePaymentStatus(paymentMethod.id);
    
    setPaymentSuccess(true);
  };
  
  const updatePaymentStatus = async (paymentId) => {
    try {
      console.log('Updating payment status for invoice:', invoiceNumber);
      
      // Update payment status in Firestore
      const result = await updateInvoicePaymentStatus(
        invoiceNumber,
        'Paid',
        'Credit Card',
        paymentId
      );
      
      console.log('Payment status update result:', result);
    } catch (err) {
      console.error('Error updating payment status:', err);
      // We don't show this error to the user since the payment was successful
      // and this is just a backend update
    }
  };
  
  const handlePaymentError = (error) => {
    console.error('Payment error:', error);
    setError('Payment failed: ' + error.message);
  };
  
  if (loading) {
    return (
      <Container className="py-5 text-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
        <p className="mt-3">Loading invoice details...</p>
      </Container>
    );
  }
  
  if (error) {
    return (
      <Container className="py-5">
        <Alert variant="danger">
          <Alert.Heading>Error</Alert.Heading>
          <p>{error}</p>
          <hr />
          <p className="mb-0">
            If you continue to experience issues, please contact our support team.
          </p>
        </Alert>
      </Container>
    );
  }
  
  if (paymentSuccess) {
    return (
      <Container className="py-5">
        <Card className="border-0 shadow">
          <Card.Body className="p-5">
            <div className="text-center mb-4">
              <h2 className="fw-bold text-success">Payment Successful!</h2>
              <p className="lead">Thank you for your payment.</p>
            </div>
            
            <Row className="justify-content-center">
              <Col md={8}>
                <Card className="bg-light mb-4">
                  <Card.Body>
                    <h5>Payment Details</h5>
                    <p><strong>Amount:</strong> ${(parseInt(invoice.price) / 100).toFixed(2)}</p>
                    <p><strong>Card:</strong> {paymentInfo.brand.toUpperCase()} ending in {paymentInfo.last4}</p>
                    <p><strong>Invoice Number:</strong> {invoiceNumber}</p>
                    <p><strong>Date:</strong> {new Date().toLocaleDateString()}</p>
                  </Card.Body>
                </Card>
                
                <div className="text-center">
                  <p>A receipt has been sent to your email address.</p>
                  <button 
                    className="btn btn-primary mt-3" 
                    onClick={() => navigate('/')}
                  >
                    Return to Home
                  </button>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
    );
  }
  
  // Format price for display
  const formattedPrice = invoice && invoice.price 
    ? `$${(parseInt(invoice.price) / 100).toFixed(2)}` 
    : 'N/A';
  
  return (
    <Container className="py-5">
      <Card className="border-0 shadow">
        <Card.Body className="p-5">
          <div className="text-center mb-4">
            <h2 className="fw-bold">Complete Your Payment</h2>
            <p className="lead">Invoice #{invoiceNumber}</p>
          </div>
          
          <Row>
            <Col lg={6} className="mb-4 mb-lg-0">
              <h4>Invoice Details</h4>
              <Card className="bg-light mb-4">
                <Card.Body>
                  <p><strong>Product:</strong> {invoice.productType}: {invoice.productName}</p>
                  <p><strong>Amount:</strong> {formattedPrice}</p>
                  <p><strong>Invoice Date:</strong> {invoice.timestamp ? new Date(invoice.timestamp.toDate()).toLocaleDateString() : 'N/A'}</p>
                  {invoice.notes && (
                    <>
                      <h5 className="mt-3">Additional Notes</h5>
                      <p>{invoice.notes}</p>
                    </>
                  )}
                </Card.Body>
              </Card>
              
              <h5>Customer Information</h5>
              <p><strong>Name:</strong> {invoice.name}</p>
              <p><strong>Email:</strong> {invoice.email}</p>
              {invoice.company && <p><strong>Company:</strong> {invoice.company}</p>}
            </Col>
            
            <Col lg={6}>
              <h4>Payment Method</h4>
              <Suspense fallback={<div>Loading payment form...</div>}>
                <StripePayment 
                  amount={parseInt(invoice.price)} 
                  onSuccess={handlePaymentSuccess}
                  onError={handlePaymentError}
                />
              </Suspense>
              
              <div className="mt-4">
                <small className="text-muted">
                  Your payment is processed securely through Stripe. We do not store your credit card information.
                </small>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default InvoicePayment; 