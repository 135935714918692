import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  const [trainingDropdownOpen, setTrainingDropdownOpen] = useState(false);
  const [mentoringDropdownOpen, setMentoringDropdownOpen] = useState(false);

  // Initialize Bootstrap dropdowns when component mounts
  useEffect(() => {
    // Check if Bootstrap is available
    if (typeof window !== 'undefined' && typeof window.bootstrap !== 'undefined') {
      // Initialize all dropdowns
      const dropdownElementList = document.querySelectorAll('.dropdown-toggle');
      dropdownElementList.forEach(dropdownToggle => {
        new window.bootstrap.Dropdown(dropdownToggle);
      });
    } else {
      console.warn('Bootstrap is not loaded. Dropdowns may not function correctly.');
    }

    // Add event listener for clicks outside dropdowns
    const handleClickOutside = (event) => {
      if (!event.target.closest('.dropdown')) {
        setTrainingDropdownOpen(false);
        setMentoringDropdownOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    
    // Clean up event listener
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  // Toggle dropdown state
  const toggleTrainingDropdown = (e) => {
    e.preventDefault();
    setTrainingDropdownOpen(!trainingDropdownOpen);
    setMentoringDropdownOpen(false);
  };

  const toggleMentoringDropdown = (e) => {
    e.preventDefault();
    setMentoringDropdownOpen(!mentoringDropdownOpen);
    setTrainingDropdownOpen(false);
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container px-5">
        <Link className="navbar-brand" to="/">
          <img 
            src={`${process.env.PUBLIC_URL}/assets/charsoft logo dark letters1 (1).png`} 
            width="400px" 
            alt="Charsoft Consulting Logo" 
            title="Charsoft Consulting Logo"
            style={{ maxHeight: '80px', width: 'auto' }}
            onError={(e) => {
              e.target.onerror = null;
              console.error('Error loading logo');
              // Use a text fallback if image fails to load
              e.target.style.display = 'none';
              e.target.parentNode.innerHTML += '<span style="font-size: 24px; font-weight: bold;">Charsoft Consulting</span>';
            }}
          />
        </Link>
        <button 
          className="navbar-toggler" 
          type="button" 
          data-bs-toggle="collapse" 
          data-bs-target="#navbarSupportedContent" 
          aria-controls="navbarSupportedContent" 
          aria-expanded="false" 
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link className="nav-link" to="/">Home</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/about">About</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/clients">Clients</Link>
            </li>
            <li className={`nav-item dropdown ${trainingDropdownOpen ? 'show' : ''}`}>
              <a 
                className="nav-link dropdown-toggle" 
                href="#" 
                id="trainingDropdown"
                role="button" 
                onClick={toggleTrainingDropdown}
                aria-expanded={trainingDropdownOpen}
              >
                Training
              </a>
              <ul className={`dropdown-menu dropdown-menu-end ${trainingDropdownOpen ? 'show' : ''}`} aria-labelledby="trainingDropdown">
                <li><Link className="dropdown-item" to="/training" onClick={() => setTrainingDropdownOpen(false)}>Overview</Link></li>
                <li><Link className="dropdown-item" to="/google-cloud-training" onClick={() => setTrainingDropdownOpen(false)}>Google Cloud</Link></li>
                <li><Link className="dropdown-item" to="/microsoft-training" onClick={() => setTrainingDropdownOpen(false)}>Microsoft</Link></li>
                <li><Link className="dropdown-item" to="/ai-training" onClick={() => setTrainingDropdownOpen(false)}>AI & ML</Link></li>
              </ul>
            </li>
            <li className={`nav-item dropdown ${mentoringDropdownOpen ? 'show' : ''}`}>
              <a 
                className="nav-link dropdown-toggle" 
                href="#" 
                id="mentoringDropdown"
                role="button" 
                onClick={toggleMentoringDropdown}
                aria-expanded={mentoringDropdownOpen}
              >
                Mentoring
              </a>
              <ul className={`dropdown-menu dropdown-menu-end ${mentoringDropdownOpen ? 'show' : ''}`} aria-labelledby="mentoringDropdown">
                <li><Link className="dropdown-item" to="/mentoring" onClick={() => setMentoringDropdownOpen(false)}>Overview</Link></li>
                <li><Link className="dropdown-item" to="/technical-mentoring" onClick={() => setMentoringDropdownOpen(false)}>
                  <i className="bi bi-rocket-takeoff text-primary me-2"></i>Technical Mentoring
                </Link></li>
                <li><Link className="dropdown-item" to="/leadership-program" onClick={() => setMentoringDropdownOpen(false)}>Leadership Development</Link></li>
                <li><Link className="dropdown-item" to="/leadership-quiz" onClick={() => setMentoringDropdownOpen(false)}>
                  <i className="bi bi-clipboard-check text-success me-2"></i>Leadership Style Quiz
                </Link></li>
                {/* 
                <li><Link className="dropdown-item" to="/career-development" onClick={() => setMentoringDropdownOpen(false)}>Career Development</Link></li>
                <li><Link className="dropdown-item" to="/team-coaching" onClick={() => setMentoringDropdownOpen(false)}>Team Coaching</Link></li>
                */}
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header; 