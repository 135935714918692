import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import FeatureCard from '../components/FeatureCard';
import ServiceCard from '../components/ServiceCard';

const Home = () => {
  // Video ref for controlling playback speed
  const videoRef = useRef(null);
  
  // Set video playback rate when component mounts
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 0.75; // Slow down by 25%
    }
  }, []);

  // Feature data
  const features = [
    {
      icon: 'bi-collection',
      title: 'Analysis',
      description: 'Many of our customers do not need to buy new software to get the innovation they need. We will analyze your business challenges and then offer modern, leading-edge solutions to automate your work!'
    },
    {
      icon: 'bi-star',
      title: '5-Star Support',
      description: 'We stand behind our solutions, providing ongoing training and support to enhance the knowledge base of our users.'
    },
    {
      icon: 'bi-lightbulb',
      title: 'Innovative Ideas',
      description: 'We review your investments and help you leverage the best path forward for a modern, innovative approach to cloud-based and on-prem solutions.'
    },
    {
      icon: 'bi-toggles2',
      title: 'Dynamic and Engaged',
      description: 'Our consultants are EDUCATORS, dynamically presenting content that ENGAGES our clients and encourages leadership and excellence in the Tech field.'
    }
  ];

  // Service data
  const services = [
    {
      image: '/assets/online-training.png',
      badge: 'Training',
      title: 'Professional Training Programs',
      description: 'Comprehensive training in cloud computing, artificial intelligence, and Microsoft technologies. Our hands-on programs empower teams with cutting-edge skills and practical knowledge.',
      link: '/training'
    },
    {
      image: '/assets/agreement-black-business-943630.jpg',
      badge: 'Consulting',
      title: 'Expert Consulting Services',
      description: 'Strategic guidance and technical expertise to help your organization navigate digital transformation. We analyze challenges and provide innovative solutions for modern business needs.',
      link: '#'
    },
    {
      image: '/assets/mentoring.png',
      badge: 'Coaching',
      title: 'Coaching & Mentoring',
      description: 'Personalized coaching and mentoring programs designed to develop leadership skills, technical expertise, and professional growth. One-on-one guidance to help you achieve your career goals.',
      link: '/mentoring'
    }
  ];

  return (
    <>
      {/* Header */}
      <header className="bg-dark py-5">
        <div className="container px-5">
          <div className="row gx-5 align-items-center justify-content-center">
            <div className="col-lg-8 col-xl-7 col-xxl-6">
              <div className="my-5 text-center text-xl-start">
                <h1 className="display-5 fw-bolder text-white mb-2">Developing Solutions, Strategies, Skill-sets</h1>
                <p className="lead fw-normal text-white-50 mb-4">
                  Charsoft Consulting helps design your solution, and we will train your team so that you can continue to be effective long after our deliverables have been met.
                </p>
              </div>
            </div>
            <div className="col-xl-5 col-xxl-6 d-none d-xl-block text-center">
              <img className="img-fluid rounded-3 my-5" src="/assets/Home03-img02.jpg" alt="Team collaboration" />
            </div>
          </div>
        </div>
      </header>

      {/* Announcement Section */}
      <section className="py-4 bg-gradient" style={{ background: 'linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%)' }}>
        <div className="container px-5">
          <div className="row gx-5 justify-content-center">
            <div className="col-lg-10 text-center">
              <div className="d-inline-block position-relative mb-3">
                {/* Fireworks animation */}
                <div className="position-absolute" style={{ top: '-10px', left: '-20px', width: 'calc(100% + 40px)', height: 'calc(100% + 20px)', overflow: 'hidden', pointerEvents: 'none' }}>
                  <div className="firework" style={{ position: 'absolute', left: '10%', top: '50%' }}></div>
                  <div className="firework" style={{ position: 'absolute', left: '90%', top: '50%', animationDelay: '0.5s' }}></div>
                </div>
                <span className="text-primary fw-bold" style={{ fontSize: '1rem', letterSpacing: '1px', position: 'relative', zIndex: 1 }}>
                  <i className="bi bi-stars me-2"></i>NEW OFFERING<i className="bi bi-stars ms-2"></i>
                </span>
                <div className="position-absolute" style={{ height: '2px', width: '100%', background: 'linear-gradient(90deg, transparent, #0d6efd 15%, #0d6efd 85%, transparent)', bottom: '-5px', left: '0' }}></div>
              </div>
              <h2 className="fw-bold text-primary mb-3">Keep the Magic Going!</h2>
              <p className="lead text-dark mb-4">
                Students love the high-energy teaching style in LaunchPad and other training sessions—and many have asked for more! We're here to fill the gap between formal courses, offering coaching and mentoring to help you apply what you've learned and prepare for your next training at ROI Training or Learning Tree. Charlene and Blake's dynamic approach has sparked something exciting—an opportunity to keep growing between classes!
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5 position-relative overflow-hidden">
        {/* Video Background */}
        <div className="position-absolute top-0 start-0 w-100 h-100" style={{ zIndex: -1 }}>
          <video 
            ref={videoRef}
            className="w-100 h-100 object-fit-cover" 
            autoPlay 
            muted 
            loop 
            playsInline
          >
            <source src="/assets/movementvid-smaller.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        
        <div className="container px-5 position-relative">
          <div className="row gx-5 justify-content-center">
            <div className="col-md-6 mb-4 mb-md-0">
              <div className="card h-100 border-0 shadow-sm" style={{ background: 'rgba(255, 255, 255, 0.9)' }}>
                <div className="card-body p-4">
                  <div className="d-flex align-items-center mb-3">
                    <i className="bi bi-person-check-fill text-primary fs-2 me-3"></i>
                    <h3 className="fw-bold mb-0">Custom Individual Mentoring</h3>
                  </div>
                  <p className="mb-4">
                    One-on-one guidance tailored to your specific needs and career goals. 
                    Get personalized attention from our expert mentors who will help you 
                    navigate cloud technologies, AI, and career growth.
                  </p>
                  <Link to="/technical-mentoring" className="btn btn-outline-primary">
                    <i className="bi bi-rocket-takeoff me-2"></i>Accelerate Your Growth
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card h-100 border-0 shadow-sm" style={{ background: 'rgba(255, 255, 255, 0.9)' }}>
                <div className="card-body p-4">
                  <div className="d-flex align-items-center mb-3">
                    <i className="bi bi-people-fill text-primary fs-2 me-3"></i>
                    <h3 className="fw-bold mb-0">Group Mentoring on Discord</h3>
                  </div>
                  <p className="mb-4">
                    Join our private Discord community where you can ask questions, 
                    participate in live Q&A sessions, and connect with peers who are 
                    also growing in their careers. Learn together in a supportive environment!
                  </p>
                  <Link to="/technical-mentoring" className="btn btn-outline-primary">
                    <i className="bi bi-chat-square-text me-2"></i>Join the Community
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center mt-5">
            <div className="p-4 rounded-3 shadow-sm" style={{ background: 'rgba(255, 255, 255, 0.9)' }}>
              <p className="lead fw-bold text-dark mb-3">
                <i className="bi bi-lightbulb text-primary me-2"></i>
                Your career is too valuable to leave to trial and error. Investing in mentorship now will put you years ahead of the competition!
                <i className="bi bi-rocket-takeoff ms-2 text-primary"></i>
              </p>
              <Link to="/technical-mentoring" className="btn btn-lg btn-primary">
                Explore Mentoring Options
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* Features section */}
      <section className="py-5" id="features">
        <div className="container px-5 my-5">
          <div className="row gx-5">
            <div className="col-lg-4 mb-5 mb-lg-0">
              <h2 className="fw-bolder mb-0">The Charsoft Difference</h2>
              <div className="mt-4">
                <div className="rounded shadow overflow-hidden">
                  <video 
                    className="w-100" 
                    autoPlay 
                    muted 
                    loop 
                    playsInline
                    style={{ maxHeight: '300px', objectFit: 'cover' }}
                  >
                    <source src="/assets/highfive2-smaller.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <p className="text-muted mt-3 small">Our approach combines technical expertise with engaging education</p>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="row gx-5 row-cols-1 row-cols-md-2">
                {features.map((feature, index) => (
                  <FeatureCard 
                    key={index}
                    icon={feature.icon}
                    title={feature.title}
                    description={feature.description}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Testimonial section */}
      <div className="py-5 bg-light">
        <div className="container px-5 my-5">
          <div className="row gx-5 justify-content-center">
            <div className="col-lg-10 col-xl-7">
              <div className="text-center">
                <div className="fs-4 mb-4 fst-italic">
                  "Working with Charsoft to train our employees in cloud innovation allowed our team to triple their effectiveness. Charsoft's consultants helped us to enable the technology we learned in class. Best training ever!"
                </div>
                <div className="d-flex align-items-center justify-content-center">
                  <img className="rounded-circle me-3" src="https://dummyimage.com/40x40/ced4da/6c757d" alt="Testimonial avatar" />
                  <div className="fw-bold">
                    Tom Atom
                    <span className="fw-bold text-primary mx-1">/</span>
                    CEO, Biz Teams Inc.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Services preview section */}
      <section className="py-5">
        <div className="container px-5 my-5">
          <div className="row gx-5 justify-content-center">
            <div className="col-lg-8 col-xl-6">
              <div className="text-center">
                <h2 className="fw-bolder">About Our Services</h2>
                <p className="lead fw-normal text-muted mb-5">
                  We offer comprehensive training programs, expert consulting services, and personalized coaching to help your team excel in today's rapidly evolving technology landscape.
                </p>
              </div>
            </div>
          </div>
          <div className="row gx-5">
            {services.map((service, index) => (
              <ServiceCard 
                key={index}
                image={service.image}
                badge={service.badge}
                title={service.title}
                description={service.description}
                link={service.link}
              />
            ))}
          </div>
        </div>
      </section>

      {/* Fireworks CSS */}
      <style jsx="true">{`
        @keyframes firework {
          0% {
            transform: translate(0, 0);
            width: 0px;
            height: 0px;
            opacity: 0;
          }
          50% {
            opacity: 1;
          }
          100% {
            width: 10px;
            height: 10px;
            opacity: 0;
            transform: translate(var(--x), var(--y));
          }
        }
        
        .firework::before,
        .firework::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 3px;
          height: 3px;
          border-radius: 50%;
          background-color: rgba(13, 110, 253, 0.5);
          transform: translate(-50%, -50%);
        }
        
        .firework::before {
          --x: -20px;
          --y: -20px;
          animation: firework 1.5s infinite;
        }
        
        .firework::after {
          --x: 20px;
          --y: -20px;
          animation: firework 1.5s 0.1s infinite;
        }
        
        .firework:nth-child(2)::before {
          --x: 20px;
          --y: 20px;
          animation: firework 1.5s 0.2s infinite;
        }
        
        .firework:nth-child(2)::after {
          --x: -20px;
          --y: 20px;
          animation: firework 1.5s 0.3s infinite;
        }
      `}</style>
    </>
  );
};

export default Home; 