import React from 'react';
import { Link } from 'react-router-dom';

const MayaSuccessStoryModal = () => {
  return (
    <div className="modal fade" id="mayaSuccessStoryModal" tabIndex="-1" aria-labelledby="mayaSuccessStoryModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div className="modal-content">
          <div className="modal-header bg-primary text-white">
            <h5 className="modal-title" id="mayaSuccessStoryModalLabel">From Stuck to Confident: How Maya Used Tier 2 to Level Up in Cloud</h5>
            <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className="container-fluid">
              {/* Meet Maya Section */}
              <div className="mb-4 position-relative">
                <div className="row">
                  <div className="col-md-9">
                    <h4 className="text-primary">Meet Maya: The Overwhelmed Cloud Engineer</h4>
                    <p>Maya had always been <strong>passionate about technology</strong>. She worked as a <strong>systems administrator</strong> for a mid-sized healthcare company, managing on-prem servers and handling day-to-day IT issues.</p>
                    <p>Her company had started <strong>moving to Google Cloud</strong>, and Maya was expected to <strong>learn cloud computing fast</strong>—without formal training. She felt <strong>stuck, overwhelmed, and intimidated</strong> by the shift.</p>
                    <blockquote className="blockquote">
                      <p className="mb-0 fst-italic">"How am I supposed to figure this out on my own?"</p>
                    </blockquote>
                    <p>That's when she came across <strong>Charlene Banks' Cloud Digital Leader course</strong>.</p>
                  </div>
                  <div className="col-md-3">
                    <div className="position-relative">
                      <img src="/assets/maya.png" alt="Maya - Cloud Engineer" className="rounded-circle img-fluid border border-4 border-primary shadow-sm" style={{ width: '180px', height: '180px', objectFit: 'cover' }} />
                      <div className="position-absolute bottom-0 end-0">
                        <span className="badge bg-success rounded-pill">Success Story</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Cloud Digital Leader Training Section */}
              <div className="mb-4">
                <h4 className="text-primary">A Spark of Understanding: Cloud Digital Leader Training</h4>
                <p>Maya took the class to understand <strong>Google Cloud fundamentals</strong>. Charlene's <strong>engaging teaching style</strong> made everything <strong>click</strong>—she finally saw <strong>how cloud strategy worked</strong> and why her company was making the switch.</p>
                <div className="card mb-3">
                  <div className="card-body">
                    <ul className="list-unstyled mb-0">
                      <li className="mb-2"><i className="bi bi-check-circle-fill text-success me-2"></i>She learned about <strong>cost optimization</strong> and why <strong>her company wanted to move workloads to the cloud</strong>.</li>
                      <li className="mb-2"><i className="bi bi-check-circle-fill text-success me-2"></i>She saw how <strong>machine learning and automation</strong> could reduce the manual IT work she hated.</li>
                      <li className="mb-2"><i className="bi bi-check-circle-fill text-success me-2"></i>She realized that her <strong>on-prem skills weren't obsolete—they just needed a cloud upgrade!</strong></li>
                    </ul>
                  </div>
                </div>
                <p>She passed the <strong>Cloud Digital Leader exam</strong>, but something was still missing…</p>
                <blockquote className="blockquote">
                  <p className="mb-0 fst-italic">"I understand the concepts, but I have no idea how to actually do this at my job."</p>
                </blockquote>
                <p>That's when she found <strong>Tier 2: Interactive Learning</strong>.</p>
              </div>

              {/* Tier 2 Benefits Section */}
              <div className="mb-4">
                <h4 className="text-primary">Tier 2: Getting Real-World Cloud Support</h4>
                <p>Maya needed <strong>ongoing mentorship</strong> to apply what she learned.</p>
                <ul className="list-unstyled mb-4">
                  <li className="mb-2"><i className="bi bi-dash text-primary me-2"></i><strong>She didn't need 1:1 coaching just yet</strong>—she needed a place to <strong>ask questions, learn from others, and get unstuck</strong>.</li>
                  <li className="mb-2"><i className="bi bi-dash text-primary me-2"></i>She joined <strong>Tier 2 - Interactive Learning</strong> to get <strong>live guidance, real-world Q&As, and access to a professional cloud community</strong>.</li>
                </ul>

                <div className="card mb-3">
                  <div className="card-body">
                    <h5 className="card-title"><i className="bi bi-camera-video-fill text-primary me-2"></i>Live Q&A Sessions</h5>
                    <p>Maya attended her <strong>first live Q&A session</strong>, where she asked:</p>
                    <ul className="list-unstyled">
                      <li className="mb-2"><i className="bi bi-question-circle-fill text-primary me-2"></i><strong>"How do I migrate on-prem virtual machines to Google Cloud?"</strong></li>
                      <li className="mb-2"><i className="bi bi-question-circle-fill text-primary me-2"></i><strong>"What's the easiest way to learn Terraform?"</strong></li>
                    </ul>
                    <p className="mb-0">Charlene and the <strong>community of mentees</strong> shared insights, step-by-step solutions, and real-world tips. She left the session with <strong>actionable steps</strong>—no more guessing!</p>
                  </div>
                </div>

                <div className="card mb-3">
                  <div className="card-body">
                    <h5 className="card-title"><i className="bi bi-clock-fill text-primary me-2"></i>Office Hours for Troubleshooting</h5>
                    <p>During her first real cloud project, Maya struggled with <strong>IAM permissions</strong>.</p>
                    <ul className="list-unstyled mb-0">
                      <li className="mb-2"><i className="bi bi-arrow-right-circle-fill text-primary me-2"></i>She joined a <strong>weekly office hour</strong> session to troubleshoot the issue.</li>
                      <li className="mb-2"><i className="bi bi-arrow-right-circle-fill text-primary me-2"></i>Within <strong>15 minutes</strong>, she had <strong>a clear understanding of IAM roles & policies</strong>.</li>
                      <li className="mb-2"><i className="bi bi-arrow-right-circle-fill text-primary me-2"></i>A mentor in the group even shared a <strong>Terraform script</strong> to help her automate the process!</li>
                    </ul>
                  </div>
                </div>

                <div className="card mb-3">
                  <div className="card-body">
                    <h5 className="card-title"><i className="bi bi-discord text-primary me-2"></i>Private Discord Group for Quick Help</h5>
                    <p>Instead of wasting hours Googling issues, Maya started <strong>dropping questions in the Tier 2 Discord community</strong>.</p>
                    <ul className="list-unstyled mb-0">
                      <li className="mb-2"><i className="bi bi-chat-dots-fill text-primary me-2"></i><strong>"How do I optimize a Cloud SQL database?"</strong> → Answered within minutes.</li>
                      <li className="mb-2"><i className="bi bi-chat-dots-fill text-primary me-2"></i><strong>"Has anyone set up Cloud Logging alerts before?"</strong> → A fellow mentee shared their dashboard setup.</li>
                      <li className="mb-2"><i className="bi bi-chat-dots-fill text-primary me-2"></i><strong>"What cert should I go for next?"</strong> → Charlene dropped a voice message with tailored career advice.</li>
                    </ul>
                  </div>
                </div>

                <p>For the first time, Maya <strong>wasn't alone</strong>—she had <strong>a support system</strong> of experts and peers who helped her grow.</p>
              </div>

              {/* Transformation Section */}
              <div className="mb-4">
                <h4 className="text-primary">The Transformation: From Hesitant to Confident</h4>
                <div className="alert alert-success">
                  <h5><i className="bi bi-trophy-fill me-2"></i>After <strong>3 months in Tier 2</strong>, Maya had:</h5>
                  <ul className="list-unstyled mb-0">
                    <li className="mb-2"><i className="bi bi-check-lg me-2"></i><strong>Migrated her first workload</strong> to Google Cloud</li>
                    <li className="mb-2"><i className="bi bi-check-lg me-2"></i><strong>Presented a cloud strategy proposal</strong> to her manager</li>
                    <li className="mb-2"><i className="bi bi-check-lg me-2"></i><strong>Gained enough confidence</strong> to start studying for the Associate Cloud Engineer certification</li>
                  </ul>
                </div>
                <p>She no longer felt <strong>lost or stuck</strong>. She was <strong>part of a thriving learning community</strong>, where she could <strong>ask questions, share insights, and build confidence daily</strong>.</p>
                <p>Her company <strong>noticed her growth</strong>—and she was offered a <strong>new role as a Cloud Engineer</strong>!</p>
              </div>

              {/* Call to Action Section */}
              <div className="bg-light p-4 rounded">
                <h4 className="text-primary">Who is Tier 2 For?</h4>
                <p>If you've learned <strong>Google Cloud fundamentals</strong> but feel <strong>stuck on real-world application</strong>, Tier 2 will help you:</p>
                <div className="row">
                  <div className="col-md-6">
                    <ul className="list-unstyled">
                      <li className="mb-2"><i className="bi bi-check-circle-fill text-success me-2"></i><strong>Get live Q&A support from experts</strong></li>
                      <li className="mb-2"><i className="bi bi-check-circle-fill text-success me-2"></i><strong>Troubleshoot challenges in office hours</strong></li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="list-unstyled">
                      <li className="mb-2"><i className="bi bi-check-circle-fill text-success me-2"></i><strong>Ask questions & share insights in a private Discord</strong></li>
                      <li className="mb-2"><i className="bi bi-check-circle-fill text-success me-2"></i><strong>Stay accountable & grow in a supportive community</strong></li>
                    </ul>
                  </div>
                </div>
                <p className="mb-0 mt-3"><strong>🚀 Need help applying cloud concepts at work? Join Tier 2 today!</strong></p>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MayaSuccessStoryModal; 