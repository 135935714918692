import React, { useEffect } from 'react';
import CourseDetails from '../components/CourseDetails';

const GoogleCloudTraining = () => {
  // Ensure Bootstrap components are initialized when this page loads
  useEffect(() => {
    // Scroll to top when component mounts
    window.scrollTo(0, 0);
    
    // Re-initialize Bootstrap components if needed
    if (typeof window !== 'undefined' && typeof window.bootstrap !== 'undefined') {
      // This ensures any Bootstrap components on this page are properly initialized
      const dropdownElementList = document.querySelectorAll('.dropdown-toggle');
      dropdownElementList.forEach(dropdownToggle => {
        new window.bootstrap.Dropdown(dropdownToggle);
      });
    }
  }, []);

  return (
    <>
      {/* Header */}
      <header className="py-5">
        <div className="container px-5">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-xxl-6">
              <div className="text-center my-5">
                <h1 className="fw-bolder mb-3">Google Cloud Platform Training</h1>
                <p className="lead fw-normal text-muted mb-4">
                  Comprehensive training in Google Cloud Platform (GCP) services, designed to help your team master cloud computing and build scalable solutions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* Training Details section */}
      <section className="py-5">
        <div className="container px-5 my-5">
          <div className="row gx-5">
            <div className="col-lg-8">
              <div className="card h-100 shadow border-0">
                <img className="card-img-top" src="/assets/google-cloud-platform.png" alt="Google Cloud Training" />
                <div className="card-body p-4">
                  <h2 className="fw-bolder mb-4">Course Overview</h2>
                  <p className="mb-4">
                    Our Google Cloud Platform training program provides comprehensive coverage of GCP services and best practices. 
                    Through hands-on exercises and real-world projects, participants will learn to build, deploy, and manage applications on GCP.
                  </p>
                  
                  <h3 className="fw-bolder mb-3">Key Topics Covered</h3>
                  <ul className="list-unstyled mb-4">
                    <li className="mb-2"><i className="bi bi-check-circle-fill text-primary me-2"></i>Compute Engine and App Engine</li>
                    <li className="mb-2"><i className="bi bi-check-circle-fill text-primary me-2"></i>Cloud Storage and Cloud SQL</li>
                    <li className="mb-2"><i className="bi bi-check-circle-fill text-primary me-2"></i>Cloud Networking and Security</li>
                    <li className="mb-2"><i className="bi bi-check-circle-fill text-primary me-2"></i>BigQuery and Data Analytics</li>
                    <li className="mb-2"><i className="bi bi-check-circle-fill text-primary me-2"></i>Cloud Functions and Serverless Computing</li>
                  </ul>

                  <h3 className="fw-bolder mb-3">Course Benefits</h3>
                  <ul className="list-unstyled mb-4">
                    <li className="mb-2"><i className="bi bi-check-circle-fill text-primary me-2"></i>Hands-on experience with GCP services</li>
                    <li className="mb-2"><i className="bi bi-check-circle-fill text-primary me-2"></i>Best practices for cloud architecture</li>
                    <li className="mb-2"><i className="bi bi-check-circle-fill text-primary me-2"></i>Cost optimization strategies</li>
                    <li className="mb-2"><i className="bi bi-check-circle-fill text-primary me-2"></i>Security and compliance guidelines</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <CourseDetails 
                duration="40 hours"
                maxParticipants={12}
                format="Hands-on Labs"
                certificationPrep="Certification Prep Included"
                enrollLink="#!"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default GoogleCloudTraining; 