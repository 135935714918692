import React, { useState } from 'react';
import { Modal, Button, Form, Alert, Spinner } from 'react-bootstrap';
import { submitInvoiceRequest } from '../utils/api';

/**
 * Modal component for requesting an invoice for manager approval
 * 
 * @param {Object} props
 * @param {boolean} props.show - Whether the modal is visible
 * @param {Function} props.onHide - Function to call when the modal is closed
 * @param {Object} props.productDetails - Details of the product being purchased
 * @param {string} props.productDetails.type - Type of product (e.g., "Subscription", "Mentoring Block")
 * @param {string} props.productDetails.name - Name of the product (e.g., "Premium Tier", "40-Hour Block")
 * @param {number} props.productDetails.price - Price of the product in cents
 * @param {Object} props.userData - User data from the parent form
 * @param {string} props.userData.name - User's name
 * @param {string} props.userData.email - User's email
 * @param {string} props.userData.company - User's company (optional)
 * @param {Function} props.onSuccess - Function to call when the invoice request is successful
 * @param {Function} props.onGeneratePdf - Function to call when the user wants to generate a PDF instead
 */
const InvoiceRequestModal = ({ 
  show, 
  onHide, 
  productDetails, 
  userData, 
  onSuccess,
  onGeneratePdf
}) => {
  const [formData, setFormData] = useState({
    managerEmail: '',
    notes: ''
  });
  
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [emailSent, setEmailSent] = useState(true); // Default to true, will be set to false if email fails
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError('');
    
    try {
      // Submit the invoice request
      const result = await submitInvoiceRequest({
        email: userData.email,
        name: userData.name,
        company: userData.company || '',
        managerEmail: formData.managerEmail,
        notes: formData.notes,
        productType: productDetails.type,
        productName: productDetails.name,
        price: productDetails.price.toString()
      });
      
      console.log('Invoice request result:', result);
      
      // Check if email was sent successfully
      if (result && result.emailSent === false) {
        setEmailSent(false);
      } else {
        setEmailSent(true);
      }
      
      setSuccess(true);
      
      // Call the success callback
      if (onSuccess) {
        onSuccess(result);
      }
    } catch (err) {
      console.error('Error submitting invoice request:', err);
      setError('There was an error submitting your request. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };
  
  const handleDownloadPdf = () => {
    onHide();
    if (onGeneratePdf) {
      onGeneratePdf();
    }
  };
  
  return (
    <Modal show={show} onHide={onHide} centered backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Request Invoice for Manager Approval</Modal.Title>
      </Modal.Header>
      
      <Modal.Body>
        {success ? (
          <div className="text-center py-3">
            <div className="mb-4">
              <i className="bi bi-envelope-check text-success" style={{ fontSize: '3rem' }}></i>
              <h4 className="mt-3">Invoice Request Submitted!</h4>
            </div>
            
            <Alert variant={emailSent ? "success" : "warning"}>
              {emailSent ? (
                <p className="mb-0">
                  We've sent an invoice to your email address. If you provided your manager's email, 
                  they've also received a copy for approval.
                </p>
              ) : (
                <p className="mb-0">
                  Your invoice request has been saved, but there was an issue sending the email. 
                  Please use the "Download PDF" option below to get your invoice.
                </p>
              )}
            </Alert>
            
            <div className="mt-4 text-start">
              <h5>Next Steps:</h5>
              <ol>
                {emailSent ? (
                  <>
                    <li>Check your email for the invoice</li>
                    <li>Forward it to your manager for approval (if needed)</li>
                    <li>Complete the payment once approved using the link in the email</li>
                  </>
                ) : (
                  <>
                    <li>Download the PDF invoice using the button below</li>
                    <li>Forward it to your manager for approval</li>
                    <li>Complete the payment once approved</li>
                  </>
                )}
              </ol>
            </div>
            
            <div className="d-flex justify-content-between mt-4">
              {!emailSent && (
                <Button variant="primary" onClick={handleDownloadPdf}>
                  <i className="bi bi-file-earmark-pdf me-2"></i>
                  Download PDF Invoice
                </Button>
              )}
              <Button variant={emailSent ? "primary" : "outline-secondary"} onClick={onHide} className={emailSent ? "w-100" : ""}>
                Close
              </Button>
            </div>
          </div>
        ) : (
          <>
            <p className="text-muted mb-3">
              Fill out this form to receive an invoice that you can forward to your manager for approval.
            </p>
            
            {error && (
              <Alert variant="danger">{error}</Alert>
            )}
            
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Manager's Email</Form.Label>
                <Form.Control
                  type="email"
                  name="managerEmail"
                  value={formData.managerEmail}
                  onChange={handleChange}
                  placeholder="Enter your manager's email address"
                />
                <Form.Text className="text-muted">
                  If provided, we'll also send the invoice directly to your manager for approval.
                </Form.Text>
              </Form.Group>
              
              <Form.Group className="mb-3">
                <Form.Label>Additional Notes</Form.Label>
                <Form.Control
                  as="textarea"
                  name="notes"
                  value={formData.notes}
                  onChange={handleChange}
                  placeholder="Any additional information for the invoice"
                  rows={3}
                />
              </Form.Group>
              
              <div className="d-flex justify-content-between mt-4">
                <Button variant="link" onClick={handleDownloadPdf}>
                  <i className="bi bi-file-earmark-pdf me-1"></i>
                  Download PDF Instead
                </Button>
                <Button type="submit" variant="primary" disabled={isSubmitting}>
                  {isSubmitting ? (
                    <>
                      <Spinner animation="border" size="sm" className="me-2" />
                      Sending...
                    </>
                  ) : (
                    'Send Invoice'
                  )}
                </Button>
              </div>
            </Form>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default InvoiceRequestModal; 